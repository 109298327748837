import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useUser } from "../../hooks";
import Breadcrumbs from "../../components/breadcrumbs";
import styled from "@emotion/styled";

const Container = styled.div(
  {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    gap: 20,
    padding: "30px 15px",
  },
  ({ theme: { colors, media } }) => ({
    color: colors.black,
    [media.md]: { padding: "30px 40px" },
  })
);

const Email = styled.a({ textDecoration: "none" }, ({ theme: { colors } }) => ({
  color: colors.purple,
}));
const email = "ae@flipner.com";
const href = `mailto:${email}`;

const EmailLink = () => <Email href={href}>{email}</Email>;

const SupportPage = () => {
  const { t } = useTranslation();
  useUser();

  const breadcrumbsItems = useMemo(
    () => [{ title: t("support.breadcrubms"), url: "/" }],
    [t]
  );

  return (
    <Container>
      <Breadcrumbs arrow items={breadcrumbsItems} />
      <div>
        <p>
          <Trans
            i18nKey="support.text1"
            components={{
              emailLink: <EmailLink />,
            }}
          />
        </p>
        <p>
          <Trans
            i18nKey="support.text2"
            components={{
              emailLink: <EmailLink />,
            }}
          />
        </p>
      </div>
    </Container>
  );
};

export default SupportPage;
