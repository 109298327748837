import Alert from "../../../components/alert";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import RestrictionModal from "../../billing/components/RestrictionModal";
import { errorModalChange, selectErrorModal } from "../dashboardSlice";
import ArticleItemModal from "./article-item-modal";
import ArticleModal from "./article-modal";
import ArticlesList from "./articles-list";
import CustomStyleModal from "./custom-style/custom-style-modal";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const errorModal = useAppSelector(selectErrorModal);

  const onErrorModalOpenChange = useCallback(
    (open: boolean) => {
      dispatch(errorModalChange({ open }));
    },
    [dispatch],
  );

  return (
    <>
      <ArticlesList />
      <Alert
        title={errorModal.isInfo ? t("information") : t("error")}
        titleIcon={errorModal.isInfo ? "icon-info" : undefined}
        message={errorModal.message || t("commonApiError")}
        open={errorModal.open}
        onOpenChange={onErrorModalOpenChange}
        buttons={[{ text: t("ok") }]}
      />
      <RestrictionModal />
      <ArticleItemModal />
      <ArticleModal />
      <CustomStyleModal />
    </>
  );
};

export default Dashboard;
