import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import * as Accordion from "@radix-ui/react-accordion";
import DndContext from "../../../../context/dnd";
import ArticleItemsList from "../article-items-list/article-items-list";
import AddNote from "../add-note";
import { Article } from "../../dashboardSlice";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

const Trigger = styled(Accordion.Trigger)(
  {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 50,
    padding: "12px 15px",
    fontWeight: 500,
    borderRadius: 10,
    outline: "none",
    border: "none",
    "&[data-state=open]": {
      i: {
        transform: "rotate(180deg)",
      },
    },
  },
  ({ theme: { colors, fontSize } }) => ({
    background: colors.purple,
    color: colors.white,
    ...fontSize.lg,
    "&[data-state=open]": {
      background: colors.lightgrey2,
      color: colors.black,
      i: { color: colors.purple },
    },
  })
);

const Content = styled(Accordion.Content)({
  maxWidth: 396,
});

const ItemsList = styled(ArticleItemsList)(
  { padding: 0 },
  ({ theme: { media } }) => ({ [media.md]: { paddingBottom: 20 } })
);

interface ArticleModalItemsListProps {
  article: Article;
}

const ArticleModalItemsList: FC<ArticleModalItemsListProps> = ({ article }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMd = useMediaQuery({
    query: `(min-width: ${theme.mediaValues.sm}px)`,
  });
  const isLg = useMediaQuery({
    query: `(min-width: ${theme.mediaValues.md}px)`,
  });

  const List = (
    <DndContext>
      <ItemsList article={article} />
    </DndContext>
  );

  if (isLg)
    return (
      <>
        {List}
        <AddNote article={article} />
      </>
    );

  if (isMd)
    return (
      <Accordion.Root type="single" collapsible>
        <Accordion.Item value="list">
          <Trigger>
            {t("dashboard.notes")}
            <i className="icon-down-micro" />
          </Trigger>
          <Content>{List}</Content>
        </Accordion.Item>
      </Accordion.Root>
    );

  return null;
};

export default ArticleModalItemsList;
