import { formatNumber } from "../../../utils";
import { Plan, TariffType } from "../billingSlice";
import { filter, find, map } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const LANGUAGES_COUNT = 35;

interface Feature {
  icon?: string;
  text?: string;
  tooltip?: string;
}

export interface TariffSchema {
  id?: number;
  title: string;
  features: Feature[];
  disabledFeatures?: string[];
  highlightedFeatures?: string[];
  isPopular?: boolean;
  isEnterprice?: boolean;

  idMonthly?: number;
  idAnnually?: number;
  priceMonthly?: string;
  priceAnnually?: string;
  type: TariffType;
}

export const useTariffsSchema = (planList: Plan[], isAppSumo?: boolean) => {
  const { t } = useTranslation();

  const schema = useMemo<{
    regular: TariffSchema[];
    ltd?: TariffSchema[];
  }>(() => {
    if (isAppSumo) {
      const appSumoPlans = filter(planList, { type: "appsumo" });
      if (appSumoPlans.length > 0) {
        return {
          regular: map(
            appSumoPlans,
            ({
              id,
              name,
              limit_characters,
              limit_note_audio_min,
              limit_total_audios_min,
              monthly_price,
              type,
            }) => ({
              id,
              title: name,
              features: [
                { text: t("billing.commonFeatures.text_unlimited") },
                { text: t("billing.commonFeatures.note_unlimited") },
                {
                  text: t("billing.commonFeatures.character", {
                    count: limit_characters,
                    formattedCount: formatNumber(limit_characters),
                  }),
                },
                {
                  text: t("billing.commonFeatures.audio_length", {
                    count: limit_note_audio_min,
                    formattedCount: formatNumber(limit_note_audio_min),
                  }),
                },
                {
                  text: t("billing.commonFeatures.total_audio", {
                    count: limit_total_audios_min,
                    formattedCount: formatNumber(limit_total_audios_min),
                  }),
                },
                {
                  text: t("billing.commonFeatures.languages", {
                    count: LANGUAGES_COUNT,
                    formattedCount: formatNumber(LANGUAGES_COUNT),
                  }),
                },
                { text: t("billing.commonFeatures.tones") },
                { text: t("billing.commonFeatures.rewrite") },
                { text: t("billing.commonFeatures.choice") },
                { text: t("billing.commonFeatures.uploadWithSize") },
                { text: t("billing.commonFeatures.download") },
              ],
              idMonthly: id,
              idAnnually: id,
              priceMonthly: monthly_price,
              priceAnnually: monthly_price,
              type,
            }),
          ),
        };
      }
    }

    const free = find(planList, { type: "free" });
    const standardMonthly = find(planList, {
      type: "standard",
      payment_period: "month",
    });
    const standardAnnually = find(planList, {
      type: "standard",
      payment_period: "year",
    });
    const proMonthly = find(planList, {
      type: "pro",
      payment_period: "month",
    });
    const proAnnually = find(planList, {
      type: "pro",
      payment_period: "year",
    });
    const ltdTariffs = filter(planList, { type: "lifetime" });

    const regular = [
      {
        title: t("billing.free.title"),
        features: [
          {
            text: t("billing.free.text", {
              count: free?.texts_count_limit,
              formattedCount: formatNumber(free?.texts_count_limit),
            }),
          },
          {
            text: t("billing.free.note", {
              count: free?.notes_count_limit,
              formattedCount: formatNumber(free?.notes_count_limit),
            }),
          },
          {
            text: t("billing.commonFeatures.audio_length", {
              count: free?.limit_note_audio_min,
              formattedCount: formatNumber(free?.limit_note_audio_min),
            }),
          },
          {
            text: t("billing.commonFeatures.total_audio", {
              count: free?.limit_total_audios_min,
              formattedCount: formatNumber(free?.limit_total_audios_min),
            }),
          },
          { text: t("billing.commonFeatures.recording") },
          {
            text: t("billing.commonFeatures.character", {
              count: free?.limit_characters,
              formattedCount: formatNumber(free?.limit_characters),
            }),
            icon: "icon-help-circled",
            tooltip: t("billing.commonFeatures.charactersTooltip"),
          },
          { text: t("billing.commonFeatures.rewriteUnlimited") },
          {
            text: t("billing.commonFeatures.languages", {
              count: LANGUAGES_COUNT,
              formattedCount: formatNumber(LANGUAGES_COUNT),
            }),
          },
          { text: t("billing.commonFeatures.tones") },
        ],
        disabledFeatures: [
          t("billing.commonFeatures.voicePrompt"),
          t("billing.commonFeatures.choice"),
          t("billing.commonFeatures.upload"),
          t("billing.commonFeatures.download"),
        ],
        idMonthly: free?.id,
        idAnnually: free?.id,
        priceMonthly: free?.monthly_price,
        priceAnnually: free?.monthly_price,
        type: "free" as TariffType,
      },
      {
        title: t("billing.standard.title"),
        features: [
          { text: t("billing.commonFeatures.text_unlimited") },
          { text: t("billing.commonFeatures.note_unlimited") },
          {
            text: t("billing.commonFeatures.audio_length", {
              count: standardMonthly?.limit_note_audio_min,
              formattedCount: formatNumber(
                standardMonthly?.limit_note_audio_min,
              ),
            }),
          },
          {
            text: t("billing.commonFeatures.total_audio", {
              count: standardMonthly?.limit_total_audios_min,
              formattedCount: formatNumber(
                standardMonthly?.limit_total_audios_min,
              ),
            }),
          },
          { text: t("billing.commonFeatures.recording") },
          {
            text: t("billing.commonFeatures.character", {
              count: standardMonthly?.limit_characters,
              formattedCount: formatNumber(standardMonthly?.limit_characters),
            }),
            icon: "icon-help-circled",
            tooltip: t("billing.commonFeatures.charactersTooltip"),
          },
          { text: t("billing.commonFeatures.rewriteUnlimited") },
          {
            text: t("billing.commonFeatures.languages", {
              count: LANGUAGES_COUNT,
              formattedCount: formatNumber(LANGUAGES_COUNT),
            }),
          },
          { text: t("billing.commonFeatures.tones") },
          { text: t("billing.commonFeatures.voicePrompt") },
          { text: t("billing.commonFeatures.choice") },
          {
            text: t("billing.commonFeatures.upload_with_size", {
              count: standardMonthly?.limit_uploading_audio_min,
              formattedCount: formatNumber(
                standardMonthly?.limit_uploading_audio_min,
              ),
            }),
          },
        ],
        disabledFeatures: [t("billing.commonFeatures.download")],
        isPopular: true,
        idMonthly: standardMonthly?.id,
        idAnnually: standardAnnually?.id,
        priceMonthly: standardMonthly?.monthly_price,
        priceAnnually: standardAnnually?.monthly_price,
        type: "standard" as TariffType,
      },
      {
        title: t("billing.pro.title"),
        features: [
          { text: t("billing.commonFeatures.text_unlimited") },
          { text: t("billing.commonFeatures.note_unlimited") },
          {
            text: t("billing.commonFeatures.audio_length", {
              count: proMonthly?.limit_note_audio_min,
              formattedCount: formatNumber(proMonthly?.limit_note_audio_min),
            }),
          },
          {
            text: t("billing.commonFeatures.total_audio", {
              count: proMonthly?.limit_total_audios_min,
              formattedCount: formatNumber(proMonthly?.limit_total_audios_min),
            }),
          },
          { text: t("billing.commonFeatures.recording") },
          {
            text: t("billing.commonFeatures.character", {
              count: proMonthly?.limit_characters,
              formattedCount: formatNumber(proMonthly?.limit_characters),
            }),
            icon: "icon-help-circled",
            tooltip: t("billing.commonFeatures.charactersTooltip"),
          },
          { text: t("billing.commonFeatures.rewriteUnlimited") },
          {
            text: t("billing.commonFeatures.languages", {
              count: LANGUAGES_COUNT,
              formattedCount: formatNumber(LANGUAGES_COUNT),
            }),
          },
          { text: t("billing.commonFeatures.tones") },
          { text: t("billing.commonFeatures.voicePrompt") },
          { text: t("billing.commonFeatures.choice") },
          {
            text: t("billing.commonFeatures.upload_with_size", {
              count: proMonthly?.limit_uploading_audio_min,
              formattedCount: formatNumber(
                proMonthly?.limit_uploading_audio_min,
              ),
            }),
          },
          { text: t("billing.commonFeatures.download") },
        ],
        idMonthly: proMonthly?.id,
        idAnnually: proAnnually?.id,
        priceMonthly: proMonthly?.monthly_price,
        priceAnnually: proAnnually?.monthly_price,
        type: "pro" as TariffType,
      },
      {
        title: t("billing.enterprise.title"),
        features: [
          { text: t("billing.enterprise.sla") },
          { text: t("billing.enterprise.priority") },
          { text: t("billing.enterprise.custom") },
        ],
        highlightedFeatures: [t("billing.enterprise.highlighted")],
        isEnterprice: true,
        type: "enterprise" as TariffType,
      },
    ];

    const ltd = map(
      ltdTariffs,
      ({
        id,
        name,
        limit_uploading_audio_min,
        limit_total_audios_min,
        limit_note_audio_min,
        limit_characters,
        full_price,
      }) => {
        const isPro = name.toLowerCase().includes("pro");
        return {
          title: name,
          features: [
            { text: t("billing.commonFeatures.text_unlimited") },
            { text: t("billing.commonFeatures.note_unlimited") },
            {
              text: t("billing.commonFeatures.audio_length", {
                count: limit_note_audio_min,
                formattedCount: formatNumber(limit_note_audio_min),
              }),
            },
            {
              text: t("billing.commonFeatures.total_audio", {
                count: limit_total_audios_min,
                formattedCount: formatNumber(limit_total_audios_min),
              }),
            },
            { text: t("billing.commonFeatures.recording") },
            {
              text: t("billing.commonFeatures.character", {
                count: limit_characters,
                formattedCount: formatNumber(limit_characters),
              }),
              icon: "icon-help-circled",
              tooltip: t("billing.commonFeatures.charactersTooltip"),
            },
            { text: t("billing.commonFeatures.rewriteUnlimited") },
            {
              text: t("billing.commonFeatures.languages", {
                count: LANGUAGES_COUNT,
                formattedCount: formatNumber(LANGUAGES_COUNT),
              }),
            },
            { text: t("billing.commonFeatures.tones") },
            { text: t("billing.commonFeatures.voicePrompt") },
            { text: t("billing.commonFeatures.choice") },
            {
              text: t("billing.commonFeatures.upload_with_size", {
                count: limit_uploading_audio_min,
                formattedCount: formatNumber(limit_uploading_audio_min),
              }),
            },
            isPro && { text: t("billing.commonFeatures.download") },
          ].filter(Boolean) as Feature[],
          ...(!isPro && {
            disabledFeatures: [t("billing.commonFeatures.download")],
          }),
          id,
          idMonthly: id,
          idAnnually: id,
          priceMonthly: full_price,
          priceAnnually: full_price,
          type: "lifetime" as TariffType,
        };
      },
    ).sort((a, b) => (a.title < b.title ? 1 : -1));

    return { regular, ltd };
  }, [isAppSumo, planList, t]);

  return schema;
};
