import { FC } from "react";
import { first, size } from "lodash";
import { useTranslation } from "react-i18next";
import { ValidationErrors } from "../authSlice";
import Alert from "../../../components/alert";

interface AuthErrorAlertProps {
  error?: string;
  errors?: ValidationErrors;
  onOpenChange: (open: boolean) => void;
}

const AuthErrorAlert: FC<AuthErrorAlertProps> = ({
  error,
  errors,
  onOpenChange,
}) => {
  const { t } = useTranslation();
  return (
    <Alert
      open={!!error || size(errors?.non_field_errors) > 0}
      onOpenChange={onOpenChange}
      title={t("error")}
      message={error || first(errors?.non_field_errors)}
      buttons={[{ text: t("ok") }]}
    />
  );
};

export default AuthErrorAlert;
