import { useFullsizeModalContext } from "../pages/root/components/fullsize-modal";
import { getStyledScrollbar } from "../styles/scrollbar";
import Loader from "./loader";
import styled from "@emotion/styled";
import autosize from "autosize";
import {
  ChangeEvent,
  KeyboardEvent,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
  CSSProperties,
} from "react";
import { useTranslation } from "react-i18next";
import { Resizable as ReactResizable } from "react-resizable";

const Container = styled.div(
  {
    width: "100%",
    position: "relative",
    paddingRight: 4,
    borderRadius: 5,
  },
  ({ theme: { colors } }) => ({
    background: colors.lightgrey2,
  }),
);

const LoaderContainer = styled.div(
  {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 15,
    zIndex: 3,
  },
  ({ theme: { colors } }) => ({
    background: colors.lightgrey2,
    color: colors.purple,
  }),
);

const ScrollContainer = styled.div<{
  resize?: boolean;
  maxH?: string | number;
}>(
  {
    width: "100%",
    height: "100%",
    overflow: "hidden auto",
    borderRadius: 5,
  },
  ({ theme: { colors }, resize, maxH = "auto" }) => ({
    maxHeight: maxH,
    ...getStyledScrollbar(colors),
    "::-webkit-scrollbar-track": {
      marginTop: 6,
      marginBottom: resize ? 27 : 6,
    },
  }),
);

const StyledTextarea = styled.textarea(
  {
    width: "100%",
    padding: "15px 11px 15px 15px",
    border: "none",
    ":focus": { outline: "none" },
  },
  ({ theme: { colors, fontSize } }) => ({
    color: colors.black,
    "::placeholder": {
      color: colors.grey,
    },
    ...fontSize.md,
  }),
);

const ResizeHandle = styled.i(
  {
    width: 16,
    height: 16,
    position: "absolute",
    bottom: 5,
    right: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 16,
    cursor: "ns-resize",
    zIndex: 2,
  },
  ({ theme: { colors } }) => ({
    color: colors.purple,
  }),
);

const Resizable = styled(ReactResizable)({
  position: "relative",
});

interface TextareaProps {
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  onKeyDown?: (event: KeyboardEvent<HTMLTextAreaElement>) => void;
  initMaxHeight?: number;
  maxHeight?: string | number;
  resize?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  className?: string;
  isLoading?: boolean;
}

const Textarea: FC<TextareaProps> = ({
  value,
  onChange,
  onBlur,
  onKeyDown,
  initMaxHeight,
  maxHeight,
  resize,
  autoFocus,
  placeholder,
  className,
  isLoading,
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLTextAreaElement | null>(null);
  const [height, setHeight] = useState(0);

  const { fullSize } = useFullsizeModalContext();

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  const getInitHeight = useCallback(
    (textarea: HTMLTextAreaElement) => {
      let height = textarea.offsetHeight;
      if (initMaxHeight && height > initMaxHeight) {
        height = initMaxHeight;
      }
      return height;
    },
    [initMaxHeight],
  );

  useEffect(() => {
    if (!ref) return;
    const { current } = ref;
    if (!current) return;
    autosize(current);
    setHeight(getInitHeight(current));
    return () => {
      autosize.destroy(current);
    };
  }, [getInitHeight]);

  useEffect(() => {
    if (!ref.current) return;
    autosize.update(ref.current);
  }, [isLoading, fullSize]);

  const style: CSSProperties = {};
  if (resize) style.height = height;

  const Content = (
    <Container style={style}>
      {isLoading && (
        <LoaderContainer>
          <Loader />
          {t("dashboard.isCreating")}
        </LoaderContainer>
      )}
      <ScrollContainer resize={resize} maxH={maxHeight}>
        <StyledTextarea
          ref={ref}
          value={value}
          onChange={handleChange}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          autoFocus={autoFocus}
          className={className}
        />
      </ScrollContainer>
    </Container>
  );

  if (resize) {
    return (
      <Resizable
        width={0}
        height={height}
        handle={<ResizeHandle className="icon-resize" />}
        axis="y"
        onResize={(_, { size }) => setHeight(size.height)}
      >
        {Content}
      </Resizable>
    );
  }

  return Content;
};

export default Textarea;
