import { useCallback } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { first } from "lodash";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import AuthFormCard from "./components/auth-form-card";
import AuthForm from "./components/auth-form";
import AuthFormHeader from "./components/auth-form-header";
import FormInput from "./components/form-input";
import AuthErrorAlert from "./components/auth-error-alert";
import Button from "../../components/button";
import Link from "./components/link";
import AuthFlexContainer from "./components/auth-flex-container";
import { closeSignUpErrorModal, selectSignUpState, signUp } from "./authSlice";
import api from "../../lib/api";
import styled from "@emotion/styled";

const LoginLink = styled(Link)({
  marginLeft: 20,
});

interface FormData {
  email: string;
  name: string;
  website: string;
  password1: string;
  password2: string;
}

const SignUpPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const dispatch = useAppDispatch();
  const { loading, errors } = useAppSelector(selectSignUpState);
  const { register, handleSubmit } = useForm<FormData>();
  const code = search.get("code");

  const onSubmit = handleSubmit(async (data) => {
    try {
      const { email, name, website, password1, password2 } = data;
      const body = {
        username: email,
        email,
        password1,
        password2,
        name: name || undefined,
        website: website || undefined,
      };
      await dispatch(signUp(body)).unwrap();
      if (code && email) {
        await api.get(`api/appsumo/add_token/${email}/${code}/`);
      }
      navigate("/auth-message?type=confirm-email");
    } catch (error) {}
  });

  const onErrorDialogOpenChange = useCallback(
    (open: boolean) => {
      if (!open) dispatch(closeSignUpErrorModal());
    },
    [dispatch]
  );

  return (
    <>
      <AuthFormCard>
        <AuthForm onSubmit={onSubmit}>
          <AuthFormHeader>{t("auth.signUpTitle")}</AuthFormHeader>
          <FormInput
            register={register("email")}
            placeholder={t("auth.emailPlaceholder")}
            error={first(errors?.email) || first(errors?.username)}
            required
          />
          <FormInput
            register={register("name")}
            placeholder={t("auth.namePlaceholder")}
            error={first(errors?.name)}
          />
          <FormInput
            register={register("website")}
            placeholder={t("auth.websitePlaceholder")}
            error={first(errors?.website)}
          />
          <FormInput
            register={register("password1")}
            type="password"
            placeholder={t("auth.passwordPlaceholder")}
            error={first(errors?.password1)}
            required
          />
          <FormInput
            register={register("password2")}
            type="password"
            placeholder={t("auth.passwordAgainPlaceholder")}
            error={first(errors?.password2)}
            required
          />
          <div>
            <Trans
              i18nKey="auth.agreement"
              components={{
                termsLink: (
                  <Link
                    to="https://www.flipner.com/terms-of-service"
                    target="_blank"
                  />
                ),
                privacyPolicyLink: (
                  <Link
                    to="https://www.flipner.com/privacy-policy"
                    target="_blank"
                  />
                ),
              }}
            />
          </div>
          <AuthFlexContainer>
            <Button type="submit" disabled={loading}>
              {t("auth.signUp")}
            </Button>
          </AuthFlexContainer>
          <AuthFlexContainer>
            {t("auth.haveAnAccount")}
            <LoginLink to="/login">{t("auth.login")}</LoginLink>
          </AuthFlexContainer>
        </AuthForm>
      </AuthFormCard>
      <AuthErrorAlert errors={errors} onOpenChange={onErrorDialogOpenChange} />
    </>
  );
};

export default SignUpPage;
