import { FC, Fragment } from "react";
import { map } from "lodash";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";

const Container = styled.div(
  {
    display: "flex",
    gap: 5,
    fontSize: 13,
    lineHeight: "18px",
    a: { textDecoration: "none" },
  },
  ({ theme: { colors } }) => ({
    color: colors.purple,
    a: { color: colors.purple },
  })
);

const Arrow = styled.i({ ":before": { transform: "rotate(-90deg)" } });

interface BreadcrumbsProps {
  arrow?: boolean;
  items: Array<{ title: string; url: string }>;
}

const Breadcrumbs: FC<BreadcrumbsProps> = ({ arrow, items }) => {
  return (
    <Container>
      {arrow && <Arrow className="icon-arrow-up" />}
      {map(items, ({ title, url }, index) => (
        <Fragment key={index}>
          {index > 0 && "/"}
          <Link to={url}>{title}</Link>
        </Fragment>
      ))}
    </Container>
  );
};

export default Breadcrumbs;
