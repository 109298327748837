import { FC, useCallback, useEffect, useRef, useState } from "react";
import ResponsiveButton from "../pages/root/components/responsive-button";
import { ButtonProps } from "./button";
import styled from "@emotion/styled";

const Button = styled(ResponsiveButton)({ transition: "all 0.5s" });

interface AnimatedButtonProps extends ButtonProps {
  iconClassName: string;
  text: string;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
}

const AnimatedButton: FC<AnimatedButtonProps> = ({
  iconClassName,
  text,
  onClick,
  disabled,
  className,
}) => {
  const [clicked, setClicked] = useState(false);
  const timeoutId = useRef<number | undefined>(undefined);

  const handleClick = useCallback(() => {
    setClicked(true);

    timeoutId.current = window.setTimeout(() => {
      setClicked(false);
    }, 2000);

    if (onClick) onClick();
  }, [onClick]);

  useEffect(() => () => window.clearTimeout(timeoutId.current), []);

  return (
    <Button
      variant={clicked ? "normal" : "outline"}
      onClick={handleClick}
      disabled={disabled}
      className={className}
    >
      {clicked ? <i className="icon-check" /> : <i className={iconClassName} />}
      {text}
    </Button>
  );
};

export default AnimatedButton;
