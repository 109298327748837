const USETIFUL_BASE_CLASSNAME = "flipner-usetiful-";

export const USETIFUL_STEP_CLASSNAMES = {
  article: `${USETIFUL_BASE_CLASSNAME}article`,
  audioNote: `${USETIFUL_BASE_CLASSNAME}audio-note`,
  action: `${USETIFUL_BASE_CLASSNAME}action`,
  addTitle: `${USETIFUL_BASE_CLASSNAME}add-title`,
  addNote: `${USETIFUL_BASE_CLASSNAME}add-note`,
  profile: `${USETIFUL_BASE_CLASSNAME}profile`,
};
