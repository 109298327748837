import { theme } from "../context/theme";

export const getStyledScrollbar = (colors: (typeof theme)["colors"]) => {
  return {
    "::-webkit-scrollbar": {
      width: 4,
      height: 4,
    },
    "::-webkit-scrollbar-track": {
      background: colors.grey3,
      borderRadius: 100,
    },
    "::-webkit-scrollbar-thumb": {
      background: colors.purple,
      borderRadius: 100,
    },
    scrollbarWidth: "thin",
    scrollbarColor: `${colors.purple} ${colors.grey3}`,
  } as const;
};
