import styled from "@emotion/styled";
import { pulse } from "../../styles/animations";
import TextLogo from "../../components/text-logo";

const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoContainer = styled.div`
  animation: ${pulse} 1s infinite ease-in-out;
`;

const LogoPage = () => {
  return (
    <Container>
      <LogoContainer>
        <TextLogo />
      </LogoContainer>
    </Container>
  );
};

export default LogoPage;
