import api from "../../lib/api";
import { RootState } from "../../store";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { find } from "lodash";

export type TariffType =
  | "free"
  | "standard"
  | "pro"
  | "enterprise"
  | "appsumo"
  | "lifetime";
export type PaymentPeriod = "month" | "year" | "ltd";

export interface Plan {
  id: number;
  name: string;
  type: TariffType;
  payment_period: PaymentPeriod;
  monthly_price: string;
  full_price: string;
  limit_characters: number;
  limit_note_audio_min: number;
  limit_total_audios_min: number;
  texts_count_limit: number;
  notes_count_limit: number;
  custom_style_allowed?: boolean;
  limit_live_audio_min: number;
  limit_uploading_audio_min: number;
}

export interface UserPlan {
  id: number;
  plan_id: number;
  total_audio_min: number;
  total_characters: number;
  planDetails: Plan;
  total_live_audio_min: number;
  total_uploading_audio_min: number;
}
export interface BillingHistoryItem {
  id: number;
  plan_id: number;
  user_id: number;
  date_from: string;
  date_to: string;
  total_characters: number;
  total_audio_min: number;
  total_live_audio_min?: number;
  total_uploading_audio_min?: number;
}

export enum RestrictionTypes {
  addText = "addText",
  addArticle = "addArticle",
  characters = "characters",
  audioLength = "audioLength",
}

export interface ProposalRequest {
  plan: number;
  email: string;
  text: string;
  title: string;
}

export interface ProposalValidationError
  extends Partial<{
    [Key in keyof ProposalRequest]: string[];
  }> {}

interface BillingState {
  currentPlan: UserPlan | null;
  plansList: Plan[];
  history: BillingHistoryItem[];
  isLoadingHistory: boolean;
  isLoadingBilling: boolean;
  isLoadingUpdatePlan: boolean;
  billindErrorModalOpen: boolean;
  billingRestrictionModal: {
    open: boolean;
    type?: keyof typeof RestrictionTypes;
  };
}

const initialState: BillingState = {
  currentPlan: null,
  plansList: [],
  history: [],
  isLoadingBilling: false,
  isLoadingHistory: false,
  isLoadingUpdatePlan: false,
  billindErrorModalOpen: false,
  billingRestrictionModal: { open: false },
};

export const updateCurrentPlan = createAsyncThunk<
  {
    total_live_audio_min: number;
    total_uploading_audio_min: number;
    total_characters: number;
    isCharactersExceeded: boolean;
    isLiveAudioMinutesExceeded: boolean;
    isUploadingAudioMinutesExceeded: boolean;
  },
  undefined,
  { state: RootState }
>("billing/updateCurrentPlan", async (_, { getState }) => {
  const {
    data: { total_live_audio_min, total_uploading_audio_min, total_characters },
  } = await api.get<UserPlan>("api/user_plan/");

  const {
    billing: { currentPlan },
  } = getState();

  const isCharactersExceeded =
    currentPlan?.planDetails !== undefined
      ? total_characters > currentPlan?.planDetails?.limit_characters
      : false;
  const isLiveAudioMinutesExceeded =
    currentPlan?.planDetails !== undefined
      ? total_live_audio_min > currentPlan?.planDetails?.limit_live_audio_min
      : false;
  const isUploadingAudioMinutesExceeded =
    currentPlan?.planDetails !== undefined
      ? total_uploading_audio_min >
        currentPlan?.planDetails?.limit_uploading_audio_min
      : false;

  return {
    total_live_audio_min,
    total_uploading_audio_min,
    total_characters,
    isCharactersExceeded,
    isLiveAudioMinutesExceeded,
    isUploadingAudioMinutesExceeded,
  };
});

export const getBilling = createAsyncThunk<{
  planList: Plan[];
  currentPlan: UserPlan;
}>("billing/getBilling", async () => {
  const { data: planList } = await api.get<Plan[]>("api/plan/");
  const { data: currentPlan } = await api.get<UserPlan>("api/user_plan/");
  const planDetails = find(planList, { id: currentPlan.plan_id });
  if (!planDetails) throw new Error();
  return { planList, currentPlan: { ...currentPlan, planDetails } };
});

export const changePlan = createAsyncThunk("billing/changePlan", async () => {
  const response = await api.get("api/change_plan/");
  const url = response.data.url;
  if (url) {
    window.location.href = url;
  }
});

export const getHistory = createAsyncThunk("billing/getHistory", async () => {
  const response = await api.get("api/user_plan/history/");
  return response.data;
});

export const postProposal = createAsyncThunk<
  any,
  ProposalRequest,
  { rejectValue: ProposalValidationError }
>("billing/proposal", async (body: ProposalRequest, { rejectWithValue }) => {
  try {
    const response = await api.post("api/proposal/", body);
    return response.data;
  } catch (error) {
    const { response } = error as AxiosError<ProposalValidationError>;
    if (!response) throw error;
    return rejectWithValue(response.data);
  }
});

const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    billingErrorModalChange: (state, action: PayloadAction<boolean>) => {
      state.billindErrorModalOpen = action.payload;
    },
    billingRestrictionModalChange: (
      state,
      action: PayloadAction<{
        open: boolean;
        type?: keyof typeof RestrictionTypes;
      }>,
    ) => {
      state.billingRestrictionModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBilling.pending, (state) => {
      state.isLoadingBilling = true;
    });
    builder.addCase(getBilling.fulfilled, (state, action) => {
      state.isLoadingBilling = false;
      const { planList, currentPlan } = action.payload;
      state.plansList = planList;
      state.currentPlan = currentPlan;
    });
    builder.addCase(getBilling.rejected, (state) => {
      state.isLoadingBilling = false;
    });

    builder.addCase(changePlan.rejected, (state) => {
      state.billindErrorModalOpen = true;
    });

    builder.addCase(getHistory.pending, (state) => {
      state.isLoadingHistory = true;
    });
    builder.addCase(getHistory.fulfilled, (state, action) => {
      state.isLoadingHistory = false;
      state.history = action.payload;
    });
    builder.addCase(getHistory.rejected, (state) => {
      state.isLoadingHistory = false;
    });

    builder.addCase(updateCurrentPlan.pending, (state) => {
      state.isLoadingUpdatePlan = true;
    });
    builder.addCase(updateCurrentPlan.fulfilled, (state, action) => {
      state.isLoadingUpdatePlan = false;
      const {
        total_live_audio_min,
        total_uploading_audio_min,
        total_characters,
      } = action.payload;
      if (
        total_live_audio_min &&
        total_uploading_audio_min &&
        total_characters &&
        state.currentPlan
      ) {
        state.currentPlan.total_live_audio_min = total_live_audio_min;
        state.currentPlan.total_uploading_audio_min = total_uploading_audio_min;
        state.currentPlan.total_characters = total_characters;
      }
    });
    builder.addCase(updateCurrentPlan.rejected, (state) => {
      state.isLoadingUpdatePlan = false;
    });
  },
});

export const { billingErrorModalChange, billingRestrictionModalChange } =
  billingSlice.actions;

export const selectPlanList = (state: RootState) => state.billing.plansList;
export const selectCurrentPlan = (state: RootState) =>
  state.billing.currentPlan;
export const selectIsLoadingBilling = (state: RootState) =>
  state.billing.isLoadingBilling;
export const selectBillingHistory = (state: RootState) => state.billing.history;
export const selectIsLoadingHistory = (state: RootState) =>
  state.billing.isLoadingHistory;
export const selectIsLoadingUpdatePlan = (state: RootState) =>
  state.billing.isLoadingUpdatePlan;
export const selectBillingErrorModalOpen = (state: RootState) =>
  state.billing.billindErrorModalOpen;
export const selectBillingRestrictionModal = (state: RootState) =>
  state.billing.billingRestrictionModal;

export default billingSlice.reducer;
