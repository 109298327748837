import Button from "../../../components/button";
import Card from "../../../components/card";
import Loader from "../../../components/loader";
import { useBilling } from "../../../hooks";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { USETIFUL_STEP_CLASSNAMES } from "../../../utils/usetiful";
import {
  RestrictionTypes,
  billingRestrictionModalChange,
} from "../../billing/billingSlice";
import {
  addArticle,
  selectAddArticleForm,
  selectAddArticleState,
  selectArticles,
  setAddArticleForm,
} from "../dashboardSlice";
import LanguageSelect from "./language-select";
import WritingStyleSelect from "./writing-style-select";
import styled from "@emotion/styled";
import { isNil, size } from "lodash";
import { FormEventHandler, useCallback } from "react";
import { useTranslation } from "react-i18next";

const AddArticleContainer = styled.div`
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 365px;
  ${({ theme: { media } }) => `
    ${media.md} {
      flex-basis: 446px;
    }
  `}
  display: inline-flex;
  align-items: start;
`;

const AddArticleButton = styled(Button)`
  flex: 1;
  padding: 15px;
  font-size: 18px;
  line-height: 18px;
  margin-right: 20px;
  i {
    margin-right: 20px;
    font-size: 20px;
  }
`;

const AddArticleCard = styled(Card)`
  flex: 1;
  margin-right: 20px;
`;

const CreateTextForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  color: ${({ theme: { colors } }) => colors.black};
  font-size: 16px;
  white-space: normal;
`;

const CreateTextFormFooter = styled.div`
  display: flex;
  align-items: center;
`;

const SubmitButton = styled(Button)`
  margin-right: 30px;
`;

const IconClose = styled.i`
  color: #7c5cff;
  cursor: pointer;
`;

const AddArticle = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector(selectAddArticleState);

  const { plan } = useBilling();
  const articles = useAppSelector(selectArticles);
  const { texts_count_limit } = plan?.planDetails || {};
  const addTextRestricted =
    !isNil(texts_count_limit) && size(articles) >= texts_count_limit;

  const { show, input_language, output_language, style } =
    useAppSelector(selectAddArticleForm);

  const showForm = useCallback(() => {
    if (!addTextRestricted) {
      dispatch(setAddArticleForm({ show: true }));
    } else {
      dispatch(
        billingRestrictionModalChange({
          open: true,
          type: RestrictionTypes.addText,
        }),
      );
    }
  }, [addTextRestricted, dispatch]);

  const hideForm = useCallback(() => {
    dispatch(setAddArticleForm({ show: false }));
  }, [dispatch]);

  const onAddArticle: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    await dispatch(
      addArticle({
        name: t("dashboard.defaultColumnTitle"),
        input_language,
        output_language,
        style,
      }),
    );
    hideForm();
  };

  return (
    <AddArticleContainer>
      {show ? (
        <AddArticleCard>
          <CreateTextForm onSubmit={onAddArticle}>
            <LanguageSelect
              type="input"
              value={input_language}
              onChange={(input_language) =>
                dispatch(setAddArticleForm({ input_language }))
              }
            />
            <LanguageSelect
              type="output"
              value={output_language}
              inputValue={input_language}
              onChange={(output_language) =>
                dispatch(setAddArticleForm({ output_language }))
              }
            />
            <WritingStyleSelect
              value={style}
              onChange={(style) => dispatch(setAddArticleForm({ style }))}
              filterLanguageValue={output_language}
            />
            <CreateTextFormFooter>
              <SubmitButton type="submit" disabled={loading}>
                {t("dashboard.createArticleButton")}
              </SubmitButton>
              {loading ? (
                <Loader />
              ) : (
                <IconClose className="icon-close" onClick={hideForm} />
              )}
            </CreateTextFormFooter>
          </CreateTextForm>
        </AddArticleCard>
      ) : (
        <AddArticleButton
          onClick={showForm}
          className={USETIFUL_STEP_CLASSNAMES.addTitle}
        >
          <i className="icon-plus" />
          <span>{t("dashboard.addArticleButton")}</span>
        </AddArticleButton>
      )}
    </AddArticleContainer>
  );
};

export default AddArticle;
