import Alert from "./alert";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface DeleteConfirmProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onAccept: () => void;
  message?: string;
}

const DeleteConfirm: FC<DeleteConfirmProps> = ({
  open,
  onOpenChange,
  onAccept,
  message,
}) => {
  const { t } = useTranslation();

  const buttons = [
    {
      text: t("accept"),
      onClick: () => {
        onAccept();
        onOpenChange(false);
      },
      icon: "icon-check",
    },
    {
      variant: "outline" as const,
      text: t("cancel"),
      icon: "icon-close",
      onClick: () => onOpenChange(false),
    },
  ];

  return (
    <Alert
      open={open}
      onOpenChange={onOpenChange}
      title={t("dashboard.deleteConfirmTitle")}
      titleIcon="icon-check"
      message={message || t("dashboard.deleteConfirmText")}
      buttons={buttons}
    />
  );
};

export default DeleteConfirm;
