import Alert from "../../../components/alert";
import Breadcrumbs from "../../../components/breadcrumbs";
import Loader from "../../../components/loader";
import Switch from "../../../components/switch";
import { useBilling, useUser } from "../../../hooks";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  PaymentPeriod,
  billingErrorModalChange,
  changePlan,
  getBilling,
  selectBillingErrorModalOpen,
} from "../billingSlice";
import { changeFreePlan } from "../utils";
import LtdConfirm from "./components/ltd-confirm";
import Tariff from "./components/tariff";
import { useTariffsSchema } from "./hooks";
import styled from "@emotion/styled";
import { map } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const Container = styled.div(
  { position: "relative", padding: "78px 30px 60px 30px" },
  ({ theme: { media } }) => ({
    [media.sm]: { padding: "78px 30px 60px 30px" },
    [media.md]: { padding: "25px 40px" },
  }),
);

const LoaderContainer = styled.div({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const BreadCrumbsContainer = styled.div(
  {
    position: "absolute",
    top: 30,
    left: 15,
  },
  ({ theme: { media } }) => ({
    [media.sm]: {
      left: 50,
    },
    [media.md]: {
      left: 40,
    },
  }),
);

const Tariffs = styled.div(
  {
    display: "flex",
    flexWrap: "wrap",
    gap: 30,
    paddingTop: 30,
  },
  ({ theme: { media } }) => ({
    [media.md]: { flexWrap: "nowrap", paddingTop: 46, gap: 40 },
  }),
);

const LtdTariffsContainer = styled.div(
  {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 10,
    padding: 10,
    marginTop: 30,
    borderRadius: 10,
  },
  ({ theme: { colors, media } }) => ({
    background: colors.lightblue5,
    [media.md]: {
      padding: 30,
      borderRadius: 30,
    },
  }),
);

const LtdTariffTitle = styled.span(
  { fontSize: 20, fontWeight: 600, textAlign: "center" },
  ({ theme: { colors, media } }) => ({
    color: colors.black,
    [media.md]: { fontSize: 40 },
  }),
);

const LtdTariffSubTitle = styled.span(
  {
    padding: "10px 20px",
    fontSize: 16,
    fontWeight: 600,
    borderRadius: 15,
    background: "linear-gradient(91.5deg, #685DE7 -0.32%, #8D72FF 100%)",
  },
  ({ theme: { colors, media } }) => ({
    color: colors.white,
    [media.md]: { fontSize: 32 },
  }),
);

const LtdTariffList = styled.div({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  gap: 40,
  marginTop: 20,
});

const LtdTariff = styled(Tariff)({ width: 290 }, ({ theme: { media } }) => ({
  [media.sm]: { width: 290, height: 690 },
  [media.md]: { width: 310, height: 655 },
}));

const PaymentPeriodSwitch = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 20,
});

const PaymentPeriodText = styled.span<{ isActive: boolean }>(
  { fontWeight: 500 },
  ({ isActive, theme: { colors, fontSize } }) => ({
    color: isActive ? colors.black : colors.grey,
    ...fontSize.md,
  }),
);

const BillingPaywallPage = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { user } = useUser();
  const { plan: userPlan, planList, loading } = useBilling();
  const errorModalOpen = useAppSelector(selectBillingErrorModalOpen);

  const [ltdConfirmId, setLtdConfirmId] = useState<number>();
  const [ltdSavedOpen, setLtdSavedOpen] = useState(false);

  const plan = userPlan?.planDetails;
  const isCurrentEnterprice = plan?.type === "enterprise";
  const isCurrentFree = plan?.type === "free";
  const isCurrentLtd = plan?.type === "lifetime";

  const [paymentPeriod, setPaymentPeriod] = useState<PaymentPeriod>(
    plan?.payment_period === "year" ? "year" : "month",
  );

  useEffect(() => {
    dispatch(getBilling());
  }, [dispatch]);

  useEffect(() => {
    if (plan?.payment_period && plan.payment_period !== "ltd")
      setPaymentPeriod(plan.payment_period);
  }, [plan?.payment_period]);

  const breadcrumbsItems = useMemo(
    () => [
      { title: t("billing.main"), url: "/" },
      { title: t("billing.pricing"), url: "/billing/paywall" },
    ],
    [t],
  );

  const schema = useTariffsSchema(planList, plan?.type === "appsumo");

  const onChangePeriod = useCallback((checked: boolean) => {
    if (checked) setPaymentPeriod("year");
    else setPaymentPeriod("month");
  }, []);

  const onChangePlan = useCallback(async () => {
    if (isCurrentFree && user) {
      changeFreePlan(user);
    } else if (plan?.type === "appsumo") {
      window.open("https://appsumo.com/account/products/", "_blank")?.focus();
    } else {
      await dispatch(changePlan());
    }
  }, [isCurrentFree, user, plan?.type, dispatch]);

  const onErrorModalOpenChange = useCallback(
    (open: boolean) => dispatch(billingErrorModalChange(open)),
    [dispatch],
  );

  const onLtdSave = useCallback(() => {
    setLtdSavedOpen(true);
  }, []);

  if (loading)
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );

  return (
    <Container>
      <BreadCrumbsContainer>
        <Breadcrumbs items={breadcrumbsItems} />
      </BreadCrumbsContainer>
      {plan?.type !== "appsumo" && (
        <PaymentPeriodSwitch>
          <PaymentPeriodText isActive={paymentPeriod === "month"}>
            {t("billing.billedMonthly")}
          </PaymentPeriodText>
          <Switch
            checked={paymentPeriod === "year"}
            onChange={onChangePeriod}
          />
          <PaymentPeriodText isActive={paymentPeriod === "year"}>
            {t("billing.billedAnnually")}
          </PaymentPeriodText>
        </PaymentPeriodSwitch>
      )}
      <Tariffs>
        {map(schema.regular, (props) => {
          const isCurrent =
            props.type === plan?.type &&
            (paymentPeriod === "month"
              ? props.idMonthly === plan.id
              : props.idAnnually === plan.id);
          return (
            <Tariff
              key={props.id || props.type}
              isCurrent={isCurrent}
              isCurrentEnterprice={isCurrentEnterprice}
              isCurrentLtd={isCurrentLtd}
              paymentPeriod={paymentPeriod}
              onChange={onChangePlan}
              {...props}
            />
          );
        })}
      </Tariffs>
      {schema.ltd && (
        <LtdTariffsContainer>
          <LtdTariffTitle>{t("billing.ltd.title")}</LtdTariffTitle>
          <LtdTariffSubTitle>{t("billing.ltd.subtitle")}</LtdTariffSubTitle>
          <LtdTariffList>
            {map(schema.ltd, (props) => {
              const isCurrent = props.id === plan?.id;
              return (
                <LtdTariff
                  key={props.id || props.type}
                  isCurrent={isCurrent}
                  paymentPeriod={paymentPeriod}
                  onChange={() => props.id && setLtdConfirmId(props.id)}
                  {...props}
                />
              );
            })}
          </LtdTariffList>
        </LtdTariffsContainer>
      )}
      <Alert
        title={t("error")}
        message={t("commonApiError")}
        open={errorModalOpen}
        onOpenChange={onErrorModalOpenChange}
        buttons={[{ text: t("ok") }]}
      />
      <Alert
        message={t("billing.ltd.confirmation.confirmed")}
        open={ltdSavedOpen}
        onOpenChange={setLtdSavedOpen}
        buttons={[{ text: t("ok") }]}
        hideClose
      />
      <LtdConfirm
        id={ltdConfirmId}
        onClose={() => setLtdConfirmId(undefined)}
        onSave={onLtdSave}
      />
    </Container>
  );
};

export default BillingPaywallPage;
