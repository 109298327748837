import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "../../../components/input";
import { useAppDispatch } from "../../../store/hooks";
import { Article, errorModalChange, patchArticle } from "../dashboardSlice";
import styled from "@emotion/styled";

interface ArticleNameProps {
  article: Article;
}

const ArticleNameContainer = styled.div`
  flex: 1;
  display: flex;
  margin-right: 8px;
`;

const ArticleNamePreview = styled.div`
  flex: 1;
  color: ${({ theme: { colors } }) => colors.black};
  font-weight: 500;
  white-space: normal;
  word-break: break-all;
`;

const ArticleNameInput = styled(Input)`
  flex: 1;
  padding: 5px 0;
  font-weight: 500;
`;

const ArticleName: FC<ArticleNameProps> = ({ article }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isEditing, setIsEditing] = useState(false);
  const [changedName, setChangedName] = useState(article.name);

  const startEditing = useCallback(() => {
    setIsEditing(true);
  }, [setIsEditing]);

  const setName = useCallback(async () => {
    setIsEditing(false);
    if (!changedName) {
      setChangedName(article.name);
      return;
    }
    if (changedName.length > 200) {
      setChangedName(article.name);
      dispatch(
        errorModalChange({
          open: true,
          message: t("dashboard.articleNameMaxLength"),
        })
      );
      return;
    }
    if (article.name === changedName) return;
    try {
      await dispatch(
        patchArticle({ article, change: { name: changedName } })
      ).unwrap();
    } catch (error) {
      setChangedName(article.name);
    }
  }, [article, changedName, dispatch, t]);

  return (
    <ArticleNameContainer>
      {isEditing ? (
        <ArticleNameInput
          autoFocus
          value={changedName}
          onChange={setChangedName}
          onEnter={setName}
          onBlur={setName}
          placeholder=""
        />
      ) : (
        <ArticleNamePreview onClick={startEditing}>
          {article.name}
        </ArticleNamePreview>
      )}
    </ArticleNameContainer>
  );
};

export default ArticleName;
