import { defaultMimeType, formatTime, getAuthHeader } from "../utils";
import Popover from "./popover";
import styled from "@emotion/styled";
import * as Slider from "@radix-ui/react-slider";
import { Howl } from "howler";
import { first, isNaN, last, round, split } from "lodash";
import { FC, useCallback, useEffect, useRef, useState } from "react";

const Container = styled.div(
  {
    width: "100%",
    height: 50,
    display: "flex",
    alignItems: "center",
    gap: 10,
    padding: "0 15px",
    borderRadius: 100,
  },
  ({ theme: { colors, media } }) => ({
    background: colors.white,
    color: colors.black,
    [media.md]: {
      padding: "0 20px",
    },
  }),
);

const PlayPause = styled.i<{ disabled?: boolean }>(
  {
    fontSize: 16,
    cursor: "pointer",
  },
  ({ theme: { colors }, disabled }) => ({
    color: disabled ? colors.grey : "inherit",
    pointerEvents: disabled ? "none" : "auto",
  }),
);

const Time = styled.div(
  {
    fontWeight: 500,
  },
  ({ theme: { fontSize } }) => fontSize.sm,
);

const ProgressSliderRoot = styled(Slider.Root)({
  height: 4,
  position: "relative",
  display: "flex",
  flexGrow: 1,
  alignItems: "center",
  userSelect: "none",
  touchAction: "none",
});

const ProgressSliderTrack = styled(Slider.Track)(
  {
    position: "relative",
    flexGrow: 1,
    height: 3,
    borderRadius: 100,
    cursor: "pointer",
  },
  ({ theme: { colors } }) => ({
    background: colors.grey2,
  }),
);

const ProgressSliderRange = styled(Slider.Range)(
  {
    height: "100%",
    position: "absolute",
    borderRadius: 100,
  },
  ({ theme: { colors } }) => ({
    background: colors.black,
  }),
);

const VolumeSliderRoot = styled(Slider.Root)({
  position: "relative",
  display: "flex",
  justifyContent: "center",
  userSelect: "none",
  touchAction: "none",
});

const VolumeSliderTrack = styled(Slider.Track)(
  {
    height: 70,
    width: 3,
    position: "relative",
    borderRadius: 100,
    cursor: "pointer",
  },
  ({ theme: { colors } }) => ({
    background: colors.grey2,
  }),
);

const VolumeSliderRange = styled(Slider.Range)(
  {
    width: "100%",
    position: "absolute",
    borderRadius: 100,
  },
  ({ theme: { colors } }) => ({
    background: colors.black,
  }),
);

const VolumeSliderThumb = styled(Slider.Thumb)(
  {
    display: "block",
    width: 15,
    height: 15,
    borderRadius: "50%",
  },
  ({ theme: { colors } }) => ({
    background: colors.white,
    boxShadow: `0 1px 5px ${colors.black}`,
    outlineColor: colors.black,
  }),
);

const VolumeIcon = styled.i({ marginLeft: 5, fontSize: 20, cursor: "pointer" });

interface ProgressProps {
  player: Howl | null;
  isPlaying: boolean;
}

const Progress: FC<ProgressProps> = ({ player, isPlaying }) => {
  const [seek, setSeek] = useState(0);
  const raf = useRef<number | null>(null);

  const animate = useCallback(() => {
    if (player) setSeek(player.seek());
    raf.current = requestAnimationFrame(animate);
  }, [player]);

  useEffect(() => {
    if (isPlaying) animate();
    return () => {
      if (raf.current) cancelAnimationFrame(raf.current);
    };
  }, [isPlaying, animate]);

  const onChangeProgress = useCallback(
    (value: number[]) => {
      const percent = first(value);
      if (percent && player) {
        const newProgress = (player.duration() / 100) * percent;
        player.seek(newProgress);
        setSeek(newProgress);
      }
    },
    [player],
  );

  let progress = 0;
  if (player) {
    progress = round((seek / player.duration()) * 100);
  }
  if (isNaN(progress) || progress === Infinity) progress = 0;

  return (
    <>
      <Time>{formatTime(seek)}</Time>
      <ProgressSliderRoot value={[progress]} onValueChange={onChangeProgress}>
        <ProgressSliderTrack>
          <ProgressSliderRange />
        </ProgressSliderTrack>
      </ProgressSliderRoot>
    </>
  );
};

interface VolumeProps {
  value: number;
  onChange: (value: number) => void;
}

const Volume: FC<VolumeProps> = ({ value, onChange }) => {
  const handleChange = useCallback(
    (values: number[]) => {
      const value = first(values);
      if (!value) return;
      onChange(value);
    },
    [onChange],
  );

  return (
    <Popover
      content={
        <VolumeSliderRoot
          value={[value]}
          onValueChange={handleChange}
          orientation="vertical"
          min={0}
          max={1}
          step={0.01}
        >
          <VolumeSliderTrack>
            <VolumeSliderRange />
          </VolumeSliderTrack>
          <VolumeSliderThumb aria-label="Volume" />
        </VolumeSliderRoot>
      }
      showClose={false}
      contentProps={{ side: "top", sideOffset: 10 }}
    >
      <VolumeIcon className="icon-sound" />
    </Popover>
  );
};

interface AudioPlayerProps {
  src: string;
}

const DEFAULT_VOLUME = 0.5;

const AudioPlayer: FC<AudioPlayerProps> = ({ src }) => {
  const [volume, setVolume] = useState(DEFAULT_VOLUME);
  const [isPlaying, setIsPlaying] = useState(false);

  const player = useRef<Howl | null>(null);

  const onEnd = useCallback(() => setIsPlaying(false), []);

  useEffect(() => {
    const format = last(split(defaultMimeType, "/"));
    player.current = new Howl({
      src,
      ...(format && { format }),
      onend: onEnd,
      volume: DEFAULT_VOLUME,
      preload: false,
      xhr: {
        headers: {
          Authorization: getAuthHeader(),
          "ngrok-skip-browser-warning": "69420",
        },
      },
    });
    return () => {
      player.current?.unload();
      player.current = null;
    };
  }, [src, onEnd]);

  const togglePlayPause = () => {
    if (isPlaying) {
      player.current?.pause();
      setIsPlaying(false);
    } else {
      player.current?.load();
      player.current?.play();
      setIsPlaying(true);
    }
  };

  const onChangeVolume = (value: number) => {
    if (player.current) player.current.volume(value);
    setVolume(value);
  };

  return (
    <Container className="audio-player">
      <PlayPause
        className={isPlaying ? "icon-pause" : "icon-play"}
        aria-label={isPlaying ? "Pause" : "Play"}
        onClick={togglePlayPause}
      />
      <Progress player={player.current} isPlaying={isPlaying} />
      <Volume value={volume} onChange={onChangeVolume} />
    </Container>
  );
};

export default AudioPlayer;
