import styled from "@emotion/styled";

export interface ButtonProps {
  variant?: "normal" | "outline" | "rounded" | "text";
}

const Button = styled.button<ButtonProps>(
  {
    display: "inline-flex",
    justifyContent: "start",
    alignItems: "center",
    fontWeight: 500,
    border: "none",
    transition: "background-color 200ms",
  },
  ({ variant, theme: { colors, fontSize }, disabled }) => {
    let color = colors.white;
    if (variant === "text" || variant === "outline") {
      color = disabled ? colors.grey : colors.purple;
    }

    let background = colors.purple;
    if (disabled) background = colors.grey2;
    if (variant === "text" || variant === "outline") background = "none";

    let hoverBackground = colors.purpleHover;
    if (variant === "text" || variant === "outline") hoverBackground = "none";
    if (disabled && variant !== "outline") hoverBackground = colors.grey2;

    let border = "none";
    if (variant === "outline") {
      border = disabled
        ? `1px solid ${colors.grey}`
        : `1px solid ${colors.purple}`;
    }

    return {
      padding: variant === "text" ? 0 : "12px",
      color,
      background,
      borderRadius: variant === "rounded" ? "100px" : "5px",
      border,
      ":hover": {
        background: hoverBackground,
      },
      cursor: disabled ? "default" : "pointer",
      ...fontSize.md,
    };
  }
);

export default Button;
