import {
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
} from "./scrollbars";
import styled from "@emotion/styled";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import * as Select from "@radix-ui/react-select";
import { first, map } from "lodash";
import { FC, forwardRef, ReactNode, useEffect, useState } from "react";

const SelectTrigger = styled(Select.SelectTrigger)(
  {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 2px 0 8px",
    fontSize: "14px",
    lineHeight: "14px",
    borderRadius: 5,
    border: "none",
  },
  ({ theme: { colors } }) => ({
    color: colors.black,
    backgroundColor: colors.lightgrey2,
    "&:focus-visible": { outline: `1px solid ${colors.purple}` },
    "&[data-disabled]": {
      color: colors.grey,
      i: { color: colors.grey },
    },
  }),
);

const SelectContent = styled(Select.Content)(
  {
    minWidth: "var(--radix-select-trigger-width)",
    maxHeight: 150,
    borderRadius: 5,
    boxShadow:
      "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
    zIndex: 2,
  },
  ({ theme: { colors } }) => ({ background: colors.white }),
);

export const ScrollAreaRoot = styled(ScrollArea.Root)({
  width: "100%",
  height: "100%",
});

const ScrollViewport = styled(ScrollAreaViewport)({
  width: "100%",
  height: "100%",
  maxHeight: 150,
});

const SelectIcon = styled(Select.SelectIcon)(null, ({ theme: { colors } }) => ({
  color: colors.purple,
}));
const Icon = styled.i<{ open?: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 32px;
  font-size: 18px;
  &:before {
    transform: rotate(${({ open }) => (open ? -180 : 0)}deg);
  }
`;

const StyledItem = styled(Select.Item)(
  {
    position: "relative",
    display: "flex",
    alignItems: "center",
    padding: "8px",
    fontSize: 14,
    borderRadius: 3,
    userSelect: "none",
    cursor: "pointer",
    "> *": {
      width: "100%",
    },
  },
  ({ theme: { colors } }) => ({
    color: colors.black,
    "&[data-disabled]": {
      color: colors.grey,
      pointerEvents: "none",
    },
    "&[data-highlighted]": {
      backgroundColor: colors.lightgrey2,
    },
    "&:focus-visible": { outline: "none" },
  }),
);

const SelectItem = forwardRef<HTMLDivElement, Select.SelectItemProps>(
  ({ children, ...props }, forwardedRef) => {
    return (
      <StyledItem {...props} ref={forwardedRef}>
        <Select.ItemText>{children}</Select.ItemText>
      </StyledItem>
    );
  },
);

export interface Option {
  value: string;
  label: ReactNode;
  disabled?: boolean;
}

interface SelectProps {
  open?: boolean;
  setOpen?: (value: boolean) => void;
  value: string;
  onChange: (value: string) => void;
  options: Array<Option>;
  placeholder?: string;
  ariaLabel?: string;
  customValue?: ReactNode;
  disabled?: boolean;
}

const CustomSelect: FC<SelectProps> = ({
  open,
  setOpen,
  value,
  onChange,
  options,
  placeholder,
  ariaLabel,
  customValue,
  disabled,
}) => {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(false);

  const firstOption = first(options);

  useEffect(() => {
    if (!value) {
      const firstValue = firstOption?.value;
      if (firstValue) onChange(firstValue);
    }
  }, [value, firstOption, onChange]);

  return (
    <Select.Root
      value={value}
      onValueChange={onChange}
      open={typeof open === "boolean" ? open : uncontrolledOpen}
      onOpenChange={
        typeof setOpen === "function" ? setOpen : setUncontrolledOpen
      }
      disabled={disabled}
    >
      <SelectTrigger aria-label={ariaLabel}>
        <Select.Value placeholder={placeholder} aria-label={value}>
          {customValue}
        </Select.Value>
        <SelectIcon>
          <Icon open={uncontrolledOpen} className="icon-down-micro" />
        </SelectIcon>
      </SelectTrigger>
      <Select.Portal>
        <SelectContent position="popper">
          <ScrollAreaRoot type="auto">
            <Select.Viewport asChild>
              <ScrollViewport style={{ overflowY: undefined }}>
                {map(options, ({ value, label, disabled }) => {
                  return (
                    <SelectItem key={value} value={value} disabled={disabled}>
                      {label}
                    </SelectItem>
                  );
                })}
              </ScrollViewport>
            </Select.Viewport>
            <ScrollAreaScrollbar orientation="vertical">
              <ScrollAreaThumb />
            </ScrollAreaScrollbar>
          </ScrollAreaRoot>
        </SelectContent>
      </Select.Portal>
    </Select.Root>
  );
};

export default CustomSelect;
