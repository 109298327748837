import { FC } from "react";
import { useTranslation } from "react-i18next";
import Tooltip from "../../../components/tooltip";
import styled from "@emotion/styled";

const Container = styled.div({ display: "flex", alignItems: "center" });

const FavoriteButton = styled.button<{ active?: boolean }>(
  {
    height: 26,
    display: "flex",
    alignItems: "center",
    padding: "8px 12px 8px 12px",
    gap: 6,
    borderRadius: 100,
    i: {
      fontSize: 20,
    },
    cursor: "pointer",
  },
  ({
    theme: {
      colors,
      fontSize,
      media: { md },
    },
    active,
  }) => ({
    color: colors.purple,
    ...fontSize.sm,
    border: `1px solid ${colors.purple}`,
    background: active ? colors.lightblue4 : colors.white,
    [md]: {
      height: 40,
      ...fontSize.md,
      i: {
        fontSize: 24,
      },
    },
  })
);

const Icon = styled.i({ marginLeft: 5 }, ({ theme: { colors } }) => ({
  color: colors.purple,
}));

interface ArticleItemFavoriteProps {
  onClick: () => void;
  favorite?: boolean;
  className?: string;
}

const ArticleItemFavorite: FC<ArticleItemFavoriteProps> = ({
  onClick,
  favorite,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <Container className={className}>
      <FavoriteButton active={favorite} onClick={onClick}>
        {favorite ? (
          <i className="icon-star-sharp-fill" />
        ) : (
          <i className="icon-star-sharp-stroke" />
        )}
        {t("dashboard.favorite")}
      </FavoriteButton>
      <Tooltip content={t("dashboard.favoriteHint")}>
        <Icon className="icon-help-circled" />
      </Tooltip>
    </Container>
  );
};

export default ArticleItemFavorite;
