import { useEffect, useMemo, useState } from "react";
import { useMatches, useNavigate } from "react-router-dom";
import { isEmpty, some } from "lodash";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  getUser,
  selectGetUserState,
  selectToken,
  selectUser,
} from "../pages/auth/authSlice";
import {
  selectCurrentPlan,
  selectIsLoadingBilling,
  selectPlanList,
} from "../pages/billing/billingSlice";

export const useAuth = () => {
  const navigate = useNavigate();
  const matches = useMatches();
  const token = useAppSelector(selectToken);
  const [checked, setChecked] = useState(false);

  const isAuthRoute = useMemo(
    () =>
      some(
        matches,
        (match: { handle?: { isAuthRoute?: boolean } }) =>
          match.handle?.isAuthRoute
      ),
    [matches]
  );

  const isPromoRoute = useMemo(
    () =>
      some(
        matches,
        (match: { handle?: { isPromoRoute?: boolean } }) =>
          match.handle?.isPromoRoute
      ),
    [matches]
  );

  useEffect(() => {
    if (isPromoRoute) {
      setChecked(true);
      return;
    }
    if (!token && !isAuthRoute) {
      navigate("/login");
    } else if (token && isAuthRoute) {
      navigate("/");
    }
    setChecked(true);
  }, [navigate, isAuthRoute, isPromoRoute, token]);

  return checked;
};

export const useUser = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const { loading } = useAppSelector(selectGetUserState);

  useEffect(() => {
    if (isEmpty(user)) dispatch(getUser());
  }, [user, dispatch]);

  return { user, loading };
};

export const useBilling = () => {
  const plan = useAppSelector(selectCurrentPlan);
  const planList = useAppSelector(selectPlanList);
  const loading = useAppSelector(selectIsLoadingBilling);
  return { plan, planList, loading };
};
