import NoSleep from "@zakj/no-sleep";
import mobile from "is-mobile";
import { floor, round } from "lodash";

const TOKEN_LOCALSTORAGE_KEY = "flipner_token";

export const saveToken = (value: string, rememberMe: boolean) => {
  if (!rememberMe) sessionStorage.setItem(TOKEN_LOCALSTORAGE_KEY, value);
  else localStorage.setItem(TOKEN_LOCALSTORAGE_KEY, value);
};
export const getToken = () => {
  return (
    sessionStorage.getItem(TOKEN_LOCALSTORAGE_KEY) ||
    localStorage.getItem(TOKEN_LOCALSTORAGE_KEY)
  );
};
export const removeToken = () => {
  localStorage.removeItem(TOKEN_LOCALSTORAGE_KEY);
  sessionStorage.removeItem(TOKEN_LOCALSTORAGE_KEY);
};
export const getAuthHeader = (token?: string) => `Token ${token || getToken()}`;

export const isApiUrl = (url?: string) =>
  /^(api\/.+)|dj-rest-auth\/user\/|dj-rest-auth\/password\/change\/$/.test(
    url || "",
  );

export const reorder = (
  sourceIndex: number,
  destinationIndex: number,
  origOrder: number[],
) => {
  const order = [...origOrder];
  const [item] = order.splice(sourceIndex, 1);
  order.splice(destinationIndex, 0, item);
  return order;
};

const getDefaultMimeType = () => {
  if (!MediaRecorder) return "";
  if (MediaRecorder.isTypeSupported("video/webm")) {
    return "video/webm";
  } else if (MediaRecorder.isTypeSupported("video/mp4")) {
    return "video/mp4";
  }
  return "";
};

export const defaultMimeType = getDefaultMimeType();

export const isMediaSupported = () =>
  !!(
    window?.MediaRecorder &&
    defaultMimeType &&
    navigator?.mediaDevices?.getUserMedia
  );

export const formatTime = (value: number) => {
  const seconds = round(value);
  let result =
    ("0" + floor((seconds % (60 * 60)) / 60)).slice(-2) +
    ":" +
    ("0" + (seconds % 60)).slice(-2);
  const hours = floor(seconds / (60 * 60));
  if (hours > 0) result = ("0" + hours).slice(-2) + ":" + result;
  return result;
};

export const isMobile = mobile();

export const noSleep = new NoSleep();

export const formatNumber = (value: number | null | undefined) => {
  return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") || "0";
};

export const isIOS = (function () {
  var iosQuirkPresent = function () {
    var audio = new Audio();
    audio.volume = 0.5;
    return audio.volume === 1; // volume cannot be changed from "1" on iOS 12 and below
  };
  var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  var isAppleDevice = navigator.userAgent.includes("Macintosh");
  var isTouchScreen = navigator.maxTouchPoints >= 1; // true for iOS 13 (and hopefully beyond)
  return isIOS || (isAppleDevice && (isTouchScreen || iosQuirkPresent()));
})();

export const isSafari =
  navigator?.vendor.indexOf("Apple") > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf("CriOS") === -1 &&
  navigator.userAgent.indexOf("FxiOS") === -1;

export const isChrome =
  navigator?.userAgent?.indexOf("Chrome") > -1 &&
  // @ts-ignore
  !!window.chrome &&
  // @ts-ignore
  !window.opr &&
  navigator?.userAgent?.indexOf("Edg") === -1 &&
  navigator?.vendor?.indexOf("Google Inc") > -1;

export const drawRoundRect = (
  ctx: CanvasRenderingContext2D,
  x0: number,
  y0: number,
  width: number,
  height: number,
  radius: number,
) => {
  const x1 = x0 + width;
  const y1 = y0 + height;
  ctx.beginPath();
  ctx.moveTo(x1 - radius, y0);
  ctx.quadraticCurveTo(x1, y0, x1, y0 + radius);
  ctx.lineTo(x1, y1 - radius);
  ctx.quadraticCurveTo(x1, y1, x1 - radius, y1);
  ctx.lineTo(x0 + radius, y1);
  ctx.quadraticCurveTo(x0, y1, x0, y1 - radius);
  ctx.lineTo(x0, y0 + radius);
  ctx.quadraticCurveTo(x0, y0, x0 + radius, y0);
  ctx.closePath();
};

export const copyText = (text = "") => navigator?.clipboard?.writeText(text);

export const dataURLtoFile = (dataurl: string, filename: string) => {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)![1];
  const bstr = atob(arr[arr.length - 1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};
