import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/flipner-text-logo.svg";

const TextLogo = () => {
  return (
    <Link to="/">
      <Logo title="Flipner" />
    </Link>
  );
};

export default TextLogo;
