import { FC, PropsWithChildren, ReactNode } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import CloseButton from "./close-button";
import { fadeIn } from "../styles/animations";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";

interface ModalProps extends PropsWithChildren {
  content: ReactNode;
  contentProps?: Dialog.DialogContentProps;
  className?: string;
}

const contentShow = keyframes({
  "0%": { opacity: 0, transform: "translate(-50%, -48%) scale(.96)" },
  "100%": { opacity: 1, transform: "translate(-50%, -50%) scale(1)" },
});

const DialogOverlay = styled(Dialog.Overlay)({
  backgroundColor: "rgba(0, 0, 0, 0.70)",
  position: "fixed",
  inset: 0,
  animation: `${fadeIn} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  zIndex: 1,
  overflow: "auto",
});

const DialogContent = styled(Dialog.Content)({
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  "&:focus": { outline: "none" },
  zIndex: 1,
});

interface ModalProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  closeOffset?: { top?: number; right?: number };
}

const Modal: FC<ModalProps> = ({
  open,
  onOpenChange,
  children,
  content,
  contentProps,
  closeOffset,
}) => {
  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Portal>
        <DialogOverlay>
          <DialogContent {...contentProps}>
            {content}
            <Dialog.Close aria-label="Close" asChild>
              <CloseButton {...closeOffset} />
            </Dialog.Close>
          </DialogContent>
        </DialogOverlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default Modal;
