import Button from "../../../../components/button";
import Card from "../../../../components/card";
import Modal from "../../../../components/modal";
import Popover from "../../../../components/popover";
import { USETIFUL_STEP_CLASSNAMES } from "../../../../utils/usetiful";
import { Article } from "../../dashboardSlice";
import ArticleSettingsForm from "./article-settings-form";
import { ClassNames, keyframes, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

interface ActionButtonProps {
  article: Article;
  isPreview?: boolean;
}

const ActionButtonTrigger = styled(Button)`
  padding: 7px 8px;
  ${({ theme: { colors } }) => `
    color: ${colors.purple};
    background: ${colors.lightblue};
    &:hover {
      color: ${colors.white};
      background: ${colors.purple};
    }
  `}
`;

const ActionButtonText = styled.span`
  display: none;
  ${({ theme: { media } }) => `${media.md} { display: inline; }`};
  margin-right: 10px;
`;

const Content = styled(Card)`
  width: 310px;
  flex-direction: column;
  align-items: start;
  padding: 20px 15px;
  ${({ theme: { media } }) => `${media.md} { padding: 20px }`};
  gap: 10px;
`;

const contentShow = keyframes({
  "0%": { transform: "translateX(-50%)" },
  "100%": { transform: "translateX(0)" },
});

const ActionButton: FC<ActionButtonProps> = ({ article, isPreview }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.mediaValues.sm}px)`,
  });

  const [isOpen, setIsOpen] = useState(false);

  const Trigger = (
    <ActionButtonTrigger
      variant="rounded"
      className={USETIFUL_STEP_CLASSNAMES.action}
    >
      <ActionButtonText>{t("dashboard.actionButton")}</ActionButtonText>
      <i className="icon-dot-3" />
    </ActionButtonTrigger>
  );

  const onClose = useCallback(() => setIsOpen(false), []);

  if (isPreview) return Trigger;

  if (!isMobile) {
    return (
      <Popover
        open={isOpen}
        onOpenChange={setIsOpen}
        content={
          <Content>
            <ArticleSettingsForm article={article} onClose={onClose} />
          </Content>
        }
        contentProps={{
          side: "right",
          sideOffset: 20,
          align: "start",
          alignOffset: -15,
        }}
      >
        {Trigger}
      </Popover>
    );
  }

  return (
    <ClassNames>
      {({ css }) => (
        <Modal
          open={isOpen}
          onOpenChange={setIsOpen}
          content={<ArticleSettingsForm article={article} onClose={onClose} />}
          contentProps={{
            className: css({
              width: 310,
              height: "100%",
              left: 0,
              top: 0,
              display: "flex",
              flexFlow: "column",
              alignItems: "start",
              gap: "10px",
              padding: "30px 20px",
              background: theme.colors.white,
              transform: "none",
              animation: `${contentShow} 200ms cubic-bezier(0.16, 1, 0.3, 1)`,
            }),
          }}
          closeOffset={{ top: 30, right: 25 }}
        >
          {Trigger}
        </Modal>
      )}
    </ClassNames>
  );
};

export default ActionButton;
