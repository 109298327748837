import { User } from "../auth/authSlice";

export const changeFreePlan = (user: User) => {
  window
    .open(
      `https://flipner.lemonsqueezy.com/checkout/buy/84349de4-50b9-4590-8727-d2e4a38b4371?checkout[email]=${user.email}&checkout[custom][user_id]=${user.pk}`,
      "_blank"
    )
    ?.focus();
};
