import Card from "../../../components/card";
import { USETIFUL_STEP_CLASSNAMES } from "../../../utils/usetiful";
import { Article as IArticle } from "../dashboardSlice";
import ActionButton from "./action-button";
import AddNote from "./add-note";
import ArticleItemsList from "./article-items-list/article-items-list";
import ArticleName from "./article-name";
import MergeButton from "./merge-button";
import styled from "@emotion/styled";
import { toString } from "lodash";
import { FC } from "react";
import { Draggable } from "react-beautiful-dnd";

export interface ArticleProps {
  article: IArticle;
  index: number;
}

const ArticleCard = styled(Card)`
  width: 345px;
  margin-right: 20px;
  ${({ theme: { media } }) => `
    ${media.md} { width: 426px; }
  `}
  position: relative;
  flex-direction: column;
`;

const ArticleTitle = styled.div`
  display: flex;
  align-items: start;
`;

const Article: FC<ArticleProps> = ({ article, index }) => {
  return (
    <div>
      <Draggable draggableId={toString(article.id)} index={index}>
        {(provided) => (
          <ArticleCard
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={
              index === 0 ? USETIFUL_STEP_CLASSNAMES.article : undefined
            }
          >
            <ArticleTitle>
              <ArticleName article={article} />
              <ActionButton article={article} />
            </ArticleTitle>
            <ArticleItemsList article={article} />
            <AddNote article={article} />
            <MergeButton article={article} />
          </ArticleCard>
        )}
      </Draggable>
    </div>
  );
};

export default Article;
