import AnimatedButton from "../../../components/animated-button";
import DeleteConfirm from "../../../components/delete-confirm";
import Textarea from "../../../components/textarea";
import { useAppDispatch } from "../../../store/hooks";
import { copyText } from "../../../utils";
import {
  ArticleItemResult as IArticleItemResult,
  deleteArticleItemResult,
  patchArticleItemResult,
} from "../dashboardSlice";
import ArticleItemFavorite from "./article-item-favorite";
import ResponsiveButton from "./responsive-button";
import ResultInfo from "./result-info";
import styled from "@emotion/styled";
import { debounce } from "lodash";
import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

const NoteContainer = styled.div(
  {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    gap: 15,
    fontSize: 18,
    fontWeight: 500,
    lineHeight: "23px",
  },
  ({ theme: { colors } }) => ({
    color: colors.black,
  }),
);

const ResultFavoriteButton = styled(ArticleItemFavorite)(
  { width: "100%" },
  ({
    theme: {
      media: { md },
    },
  }) => ({ [md]: { width: "auto" } }),
);

const ButtonsContainer = styled.div<{ isLastItem?: boolean }>(
  {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    gap: 20,
  },
  ({
    theme: {
      media: { md },
    },
    isLastItem,
  }) => ({ [md]: { display: isLastItem ? "none" : "flex" } }),
);

export const ModalTextArea = styled(Textarea)(
  {},
  ({ theme: { media, fontSize } }) => ({
    ...fontSize.sm,
    [media.sm]: fontSize.md,
  }),
);

interface ArticleItemResultProps extends IArticleItemResult {
  articleId: number;
  articleItemId: number;
  loading?: boolean;
  style?: number;
  isLastItem?: boolean;
}

const ArticleItemResult: FC<ArticleItemResultProps> = ({
  articleId,
  articleItemId,
  id,
  text = "",
  favorite,
  created,
  style = 1,
  loading,
  isLastItem,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const [editedText, setEditedText] = useState(text);

  const onFavoriteChange = useCallback(() => {
    if (favorite) return;
    dispatch(
      patchArticleItemResult({
        articleId,
        articleItemId,
        id,
        change: { favorite: true },
      }),
    );
  }, [articleId, articleItemId, dispatch, favorite, id]);

  const onTextSave = useCallback(
    (text: string) => {
      dispatch(
        patchArticleItemResult({
          articleId,
          articleItemId,
          id,
          change: { text },
        }),
      );
    },
    [articleId, articleItemId, dispatch, id],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedTextSave = useCallback(debounce(onTextSave, 1000), []);

  const onTextChange = useCallback(
    (text: string) => {
      setEditedText(text);
      debouncedTextSave(text);
    },
    [debouncedTextSave],
  );

  const onDeleteClick = useCallback(() => {
    setDeleteConfirmOpen(true);
  }, []);

  const onDelete = useCallback(() => {
    dispatch(
      deleteArticleItemResult({
        articleId,
        articleItemId,
        id,
      }),
    );
  }, [articleId, articleItemId, dispatch, id]);

  const onCopy = useCallback(() => {
    copyText(text);
  }, [text]);

  return (
    <>
      <NoteContainer>
        <ResultInfo text={text} style={style} date={created}>
          <ResultFavoriteButton
            onClick={onFavoriteChange}
            favorite={favorite}
          />
        </ResultInfo>
        <ModalTextArea
          value={editedText}
          onChange={onTextChange}
          initMaxHeight={492}
          resize
        />
        <ButtonsContainer isLastItem={isLastItem}>
          <AnimatedButton
            variant="outline"
            onClick={onCopy}
            disabled={loading}
            iconClassName="icon-copy"
            text={t("copy")}
          />
          <ResponsiveButton
            variant="outline"
            onClick={onDeleteClick}
            disabled={loading}
          >
            <i className="icon-trash" />
            {t("delete")}
          </ResponsiveButton>
        </ButtonsContainer>
      </NoteContainer>
      <DeleteConfirm
        open={deleteConfirmOpen}
        onOpenChange={setDeleteConfirmOpen}
        onAccept={onDelete}
      />
    </>
  );
};

export default ArticleItemResult;
