import { FormEventHandler } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AuthForm from "./components/auth-form";
import AuthFormCard from "./components/auth-form-card";
import AuthFormHeader from "./components/auth-form-header";
import AuthFlexContainer from "./components/auth-flex-container";
import Button from "../../components/button";
import styled from "@emotion/styled";

export const AuthMessagePageCard = styled(AuthFormCard)`
  ${({ theme: { media } }) => `${media.sm} { width: 384px; margin-top: 70px; }`}
`;

const SuccessDescription = styled(AuthFlexContainer)({
  marginBottom: 5,
});

const AuthMessagePage = () => {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const type = params.get("type");

  const onSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    navigate("/login");
  };

  let content;
  if (type === "password-changed") {
    content = (
      <>
        <AuthFormHeader>{t("auth.successHeader")}</AuthFormHeader>
        <SuccessDescription>{t("auth.passwordChanged")}</SuccessDescription>
        <AuthFlexContainer>
          <Button type="submit">{t("auth.login")}</Button>
        </AuthFlexContainer>
      </>
    );
  }
  if (type === "password-reset") {
    content = (
      <>
        <AuthFormHeader>{t("auth.successHeader")}</AuthFormHeader>
        <AuthFlexContainer>{t("auth.passwordResetEmail")}</AuthFlexContainer>
      </>
    );
  }
  if (type === "confirm-email") {
    content = (
      <>
        <AuthFormHeader>{t("auth.successHeader")}</AuthFormHeader>
        <AuthFlexContainer>{t("auth.confirmEmail")}</AuthFlexContainer>
      </>
    );
  }
  if (type === "account-created") {
    content = (
      <>
        <AuthFormHeader>{t("auth.successHeader")}</AuthFormHeader>
        <AuthFlexContainer>{t("auth.accountCreated")}</AuthFlexContainer>
        <AuthFlexContainer>
          <Button type="submit">{t("auth.login")}</Button>
        </AuthFlexContainer>
      </>
    );
  }

  if (!content) return <Navigate to="/login" />;

  return (
    <AuthMessagePageCard>
      <AuthForm onSubmit={onSubmit}>{content}</AuthForm>
    </AuthMessagePageCard>
  );
};

export default AuthMessagePage;
