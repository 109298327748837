import Tooltip from "../../../../components/tooltip";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { selectCurrentPlan } from "../../../billing/billingSlice";
import { changeCustomStyleModal } from "../../dashboardSlice";
import styled from "@emotion/styled";
import { FC, useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Container = styled.div({
  display: "flex",
  alignItems: "center",
  gap: 10,
});

const CustomLink = styled.a<{ disabled?: boolean }>(
  {
    fontSize: 12,
    lineHeight: "15px",
    fontWeight: 500,
  },
  ({ theme: { colors }, disabled }) => ({
    color: disabled ? colors.grey : colors.purple,
    borderBottom: `1px solid ${disabled ? colors.grey : colors.purple}`,
    cursor: disabled ? "not-allowed" : "pointer",
  }),
);

const Plus = styled.span({ fontSize: 20, fontWeight: 300 });

const Icon = styled.i({}, ({ theme: { colors } }) => ({
  color: colors.purple,
}));

interface CustomStyleLinkProps {
  className?: string;
  articleId?: number;
}

const CustomStyleLink: FC<CustomStyleLinkProps> = ({
  className,
  articleId,
}) => {
  const { t } = useTranslation();

  const plan = useAppSelector(selectCurrentPlan);
  const disabled = !plan?.planDetails?.custom_style_allowed;

  const dispatch = useAppDispatch();

  const onClick = useCallback(() => {
    if (disabled) return;
    dispatch(changeCustomStyleModal({ open: true, articleId }));
  }, [disabled, dispatch, articleId]);

  return (
    <Container>
      <CustomLink onClick={onClick} disabled={disabled} className={className}>
        <Plus>+</Plus> {t("dashboard.customStyle.link")}
      </CustomLink>
      {disabled && (
        <Tooltip
          content={
            <Trans
              i18nKey="billing.restrictions.notAvailable"
              components={{
                paywallLink: <Link to="/billing/paywall" />,
              }}
            />
          }
        >
          <Icon className="icon-help-circled" />
        </Tooltip>
      )}
    </Container>
  );
};

export default CustomStyleLink;
