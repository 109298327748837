import Button from "../../../../components/button";
import Input from "../../../../components/input";
import Modal from "../../../../components/modal";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { selectUser } from "../../../auth/authSlice";
import { postProposal, ProposalValidationError } from "../../billingSlice";
import styled from "@emotion/styled";
import { first } from "lodash";
import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

const Container = styled.div(
  {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    padding: 15,
    borderRadius: 15,
  },
  ({ theme: { colors } }) => ({
    background: colors.white,
  }),
);

const Title = styled.div(
  { fontSize: 18, lineHeight: "23px", fontWeight: 600 },
  ({ theme: { colors } }) => ({
    color: colors.black,
  }),
);

const InputContainer = styled.div({ display: "flex", flexDirection: "column" });

const Error = styled.span({}, ({ theme: { colors } }) => ({
  color: colors.red,
}));

const Footer = styled.div({ display: "flex", gap: 20 });

interface LtdConfirmProps {
  id?: number;
  onSave?: () => void;
  onClose: () => void;
}

const Content: FC<LtdConfirmProps> = ({ id, onClose, onSave }) => {
  const { t } = useTranslation();
  const user = useAppSelector(selectUser);

  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState(user.email || "");
  const [error, setError] = useState("");

  const title = t("billing.ltd.confirmation.title");
  const text = t("billing.ltd.confirmation.text");

  const handleChange = useCallback((value: string) => {
    setEmail(value);
    setError("");
  }, []);

  const handleSave = useCallback(async () => {
    setIsLoading(true);
    try {
      await dispatch(postProposal({ plan: id!, title, text, email })).unwrap();
      if (onSave) onSave();
      onClose();
    } catch (error) {
      const { email } = error as ProposalValidationError;
      if (email) {
        const message = first(email) || "";
        typeof message === "string" && setError(message);
      }
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, email, id, onClose, onSave, text, title]);

  return (
    <Container>
      <Title>{title}</Title>
      {text}
      <InputContainer>
        <Input
          value={email}
          onChange={handleChange}
          placeholder={t("auth.emailPlaceholder")}
          error={!!error}
        />
        {!!error && <Error>{error}</Error>}
      </InputContainer>
      <Footer>
        <Button disabled={!email || isLoading} onClick={handleSave}>
          {t("save")}
        </Button>
        <Button variant="outline" onClick={onClose}>
          {t("cancel")}
        </Button>
      </Footer>
    </Container>
  );
};

const LtdConfirm: FC<LtdConfirmProps> = (props) => {
  const { onClose, id } = props;

  const onOpenChange = useCallback(
    (open: boolean) => {
      if (!open) onClose();
    },
    [onClose],
  );

  return (
    <Modal
      open={!!id}
      onOpenChange={onOpenChange}
      content={<Content {...props} />}
    />
  );
};

export default LtdConfirm;
