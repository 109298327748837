import Button from "../../../../components/button";
import DeleteConfirm from "../../../../components/delete-confirm";
import { useAppDispatch } from "../../../../store/hooks";
import { Article, deleteArticle, patchArticle } from "../../dashboardSlice";
import LanguageSelect from "../language-select";
import WritingStyleSelect from "../writing-style-select";
import styled from "@emotion/styled";
import { useState, FC, useCallback } from "react";
import { useTranslation } from "react-i18next";

interface ArticleSettingsFormProps {
  article: Article;
  onClose?: () => void;
}

const DeleteButton = styled(Button)`
  padding: 6px 12px;
  margin-top: 15px;
`;

const DeleteButtonIcon = styled.i`
  font-size: 24px;
  margin-right: 10px;
`;

const ArticleSettingsForm: FC<ArticleSettingsFormProps> = ({
  article,
  onClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const [params, setParams] = useState({
    input_language: article.input_language,
    output_language: article.output_language,
    style: article.style,
  });

  const onParamChange = (
    param: "input_language" | "output_language" | "style",
    value: number,
  ) => {
    if (article[param] === value) return;
    setParams({ ...params, [param]: value });
    dispatch(patchArticle({ article, change: { [param]: value } }));
  };

  const onDeleteClick = useCallback(() => {
    setDeleteConfirmOpen(true);
  }, []);

  const onDelete = useCallback(() => {
    dispatch(deleteArticle(article));
  }, [dispatch, article]);

  return (
    <>
      <LanguageSelect
        type="input"
        value={params.input_language}
        onChange={(value) => onParamChange("input_language", value)}
      />
      <LanguageSelect
        type="output"
        inputValue={params.input_language}
        value={params.output_language}
        onChange={(value) => onParamChange("output_language", value)}
      />
      <WritingStyleSelect
        value={params.style}
        onChange={(value) => onParamChange("style", value)}
        filterLanguageValue={params.output_language}
        articleId={article.id}
        onClose={onClose}
      />
      <DeleteButton variant="outline" onClick={onDeleteClick}>
        <DeleteButtonIcon className="icon-trash" />
        {t("dashboard.deleteTextButton")}
      </DeleteButton>
      <DeleteConfirm
        open={deleteConfirmOpen}
        onOpenChange={setDeleteConfirmOpen}
        onAccept={onDelete}
      />
    </>
  );
};

export default ArticleSettingsForm;
