import Button from "../../../../components/button";
import Retry from "../../../../components/retry";
import { useBilling } from "../../../../hooks";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { isMediaSupported, noSleep } from "../../../../utils";
import { USETIFUL_STEP_CLASSNAMES } from "../../../../utils/usetiful";
import {
  RestrictionTypes,
  billingRestrictionModalChange,
  updateCurrentPlan,
} from "../../../billing/billingSlice";
import {
  ARTICLE_ITEM_TYPES,
  Article,
  addArticleItem,
  errorModalChange,
  resetAddingItemForm,
  selectAddingItemForm,
  selectTotalArticeItems,
  setAddingItemForm,
} from "../../dashboardSlice";
import AddTextNoteForm from "./add-text-note-form";
import AddNoteDropdown from "./dropdown";
import RecordAudioItem from "./record-audio-item";
import SelectAudioFile from "./select-audio-file";
import styled from "@emotion/styled";
import { find, isNil } from "lodash";
import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

interface AddNoteProps {
  article: Article;
}

const AddNoteButton = styled(Button)({
  alignSelf: "start",
  i: {
    fontSize: 20,
    marginRight: 10,
  },
  ":focus-visible": {
    outline: "none",
  },
});

const Icon = styled.i<{ dropdownOpen: boolean }>(
  { transition: "transform 0.2s" },
  ({ dropdownOpen }) => ({
    transform: `rotate(${dropdownOpen ? 45 : 0}deg)`,
  }),
);

const Control = styled.div({
  width: 33,
  position: "absolute",
  top: 0,
  right: -33,
  display: "none",
  paddingLeft: 3,
});

const ControlIcon = styled.i(
  {
    width: 30,
    height: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 16,
    borderRadius: 5,
    cursor: "pointer",
  },
  ({ theme: { colors } }) => ({
    color: colors.purple,
    background: colors.lightblue,
  }),
);

const MENU_ITEMS = [
  { key: "live_audio", label: "Live audio", icon: "icon-audio" },
  { key: "text", label: "Text-snippet", icon: "icon-text-note" },
  { key: "audio_file", label: "Audio file", icon: "icon-audio-file" },
  { key: "subtitle", label: "Subtitle", icon: "icon-subtitle" },
];

const AddNote: FC<AddNoteProps> = ({ article }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { itemType, url, fileName, retry, error, input_text } =
    useAppSelector((state) => selectAddingItemForm(state, article.id)) || {};
  const totalArticleItems = useAppSelector(selectTotalArticeItems);

  const { plan } = useBilling();
  const isFree = plan?.planDetails.type === "free";
  const { notes_count_limit } = plan?.planDetails || {};
  const addNoteRestricted =
    !isNil(notes_count_limit) && totalArticleItems >= notes_count_limit;

  const onMenuOpenChange = useCallback(
    (open: boolean) => setDropdownOpen(open),
    [],
  );

  const onMenuClick = useCallback(
    async (key: string) => {
      if (addNoteRestricted) {
        dispatch(
          billingRestrictionModalChange({
            open: true,
            type: RestrictionTypes.addArticle,
          }),
        );
        return;
      }

      const type = find(ARTICLE_ITEM_TYPES, (type) => type === key);

      if (type) {
        if (type === "audio_file" && isFree) {
          dispatch(
            billingRestrictionModalChange({
              open: true,
            }),
          );
          return;
        }

        if (type === "live_audio" || type === "audio_file") {
          try {
            const {
              isLiveAudioMinutesExceeded,
              isUploadingAudioMinutesExceeded,
            } = await dispatch(updateCurrentPlan()).unwrap();
            if (isLiveAudioMinutesExceeded || isUploadingAudioMinutesExceeded) {
              dispatch(
                billingRestrictionModalChange({
                  open: true,
                  type: RestrictionTypes.audioLength,
                }),
              );
              return;
            }
          } catch (error) {}
        }

        if (type === "live_audio") {
          if (!isMediaSupported()) {
            dispatch(
              errorModalChange({
                open: true,
                message: t("dashboard.mediaNotSupported"),
              }),
            );
            return;
          }
          noSleep.enable();
        }

        dispatch(setAddingItemForm({ article_id: article.id, itemType: type }));
      }
    },
    [article.id, t, addNoteRestricted, isFree, dispatch],
  );

  const onResetItemType = useCallback(
    () => dispatch(resetAddingItemForm({ article_id: article.id })),
    [article.id, dispatch],
  );

  const onRetry = useCallback(() => {
    if (!itemType) return;
    dispatch(
      setAddingItemForm({
        article_id: article.id,
        retry: false,
      }),
    );
    dispatch(
      addArticleItem({
        articleItem: {
          article_id: article.id,
          type: itemType,
          input_text,
          input_audio_name: fileName,
        },
        url,
      }),
    );
  }, [itemType, dispatch, article.id, input_text, fileName, url]);

  if (retry)
    return (
      <Retry
        itemType={itemType}
        url={url}
        fileName={fileName}
        onRetry={onRetry}
        error={error}
      >
        <Control className="control">
          <ControlIcon className="icon-close" onClick={onResetItemType} />
        </Control>
      </Retry>
    );

  if (itemType === "text" || itemType === "subtitle")
    return (
      <AddTextNoteForm
        article={article}
        itemType={itemType}
        onResetItemType={onResetItemType}
      />
    );

  if (itemType === "live_audio")
    return (
      <RecordAudioItem
        article={article}
        itemType={itemType}
        onResetItemType={onResetItemType}
        url={url}
      />
    );

  if (itemType === "audio_file")
    return (
      <SelectAudioFile
        article={article}
        itemType={itemType}
        onResetItemType={onResetItemType}
        url={url}
        fileName={fileName}
      />
    );

  return (
    <AddNoteDropdown
      open={dropdownOpen}
      onOpenChange={onMenuOpenChange}
      items={MENU_ITEMS}
      onClick={onMenuClick}
    >
      <AddNoteButton
        variant="text"
        className={USETIFUL_STEP_CLASSNAMES.addNote}
      >
        <Icon className="icon-plus" dropdownOpen={dropdownOpen} />
        <span>{dropdownOpen ? t("cancel") : t("dashboard.addNoteButton")}</span>
      </AddNoteButton>
    </AddNoteDropdown>
  );
};

export default AddNote;
