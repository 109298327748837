import { fadeIn } from "../styles/animations";
import Button from "./button";
import Card from "./card";
import CloseButton from "./close-button";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { map, size } from "lodash";
import { FC, ReactNode } from "react";

const contentShow = keyframes({
  "0%": { opacity: 0, transform: "translate(-50%, -48%) scale(.96)" },
  "100%": { opacity: 1, transform: "translate(-50%, -50%) scale(1)" },
});

const AlertDialogOverlay = styled(AlertDialog.Overlay)({
  position: "fixed",
  inset: 0,
  backgroundColor: "rgba(0, 0, 0, 0.70)",
  animation: `${fadeIn} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  zIndex: 2,
});

const AlertDialogContent = styled(Card)(
  {
    position: "fixed",
    top: "50%",
    left: "50%",
    flexDirection: "column",
    gap: 20,
    padding: 25,
    transform: "translate(-50%, -50%)",
    animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
    zIndex: 2,
  },
  ({ theme: { colors } }) => ({ color: colors.black }),
);

const AlertDialogTitle = styled(AlertDialog.Title)(
  {
    display: "flex",
    alignItems: "center",
    margin: 0,
    fontWeight: 500,
  },
  ({ theme: { fontSize } }) => fontSize.lg,
);

const TitleIcon = styled.div(
  {
    width: 25,
    height: 25,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 5,
    fontSize: 20,
    borderRadius: "50%",
    i: {
      display: "flex",
    },
  },
  ({ theme: { colors } }) => ({
    background: colors.purple,
    color: colors.white,
  }),
);

const Icon = styled.i(
  {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 5,
    fontSize: 25,
  },
  ({ theme: { colors } }) => ({
    color: colors.purple,
  }),
);

const AlertDialogDescription = styled(AlertDialog.Description)(
  {
    margin: 0,
  },
  ({ theme: { fontSize } }) => fontSize.md,
);

const Footer = styled.div({ display: "flex", gap: 20 });

const FooterButton = styled(Button)({ height: 40 });

const ButtonIcon = styled.i({ marginRight: 10, fontSize: 20 });

interface AlertButton {
  variant?: "normal" | "outline" | "rounded" | "text";
  text: string;
  icon?: string;
  onClick?: () => void;
}

interface AlertProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  title?: string;
  titleIcon?: string;
  hideIconWrapper?: boolean;
  hideClose?: boolean;
  message?: ReactNode;
  buttons?: Array<AlertButton>;
}

const Alert: FC<AlertProps> = ({
  open,
  onOpenChange,
  title,
  titleIcon,
  hideIconWrapper,
  hideClose,
  message,
  buttons,
}) => {
  return (
    <AlertDialog.Root open={open} onOpenChange={onOpenChange}>
      <AlertDialog.Portal>
        <AlertDialogOverlay />
        <AlertDialog.Content asChild>
          <AlertDialogContent>
            {title && (
              <AlertDialogTitle>
                {titleIcon &&
                  (hideIconWrapper ? (
                    <Icon className={titleIcon} />
                  ) : (
                    <TitleIcon>
                      <i className={titleIcon} />
                    </TitleIcon>
                  ))}
                <span>{title}</span>
              </AlertDialogTitle>
            )}
            {message && (
              <AlertDialogDescription>{message}</AlertDialogDescription>
            )}
            {size(buttons) > 0 && (
              <Footer>
                {map(buttons, ({ text, icon, variant, onClick }, index) => {
                  return (
                    <AlertDialog.Action key={index} asChild>
                      <FooterButton variant={variant} onClick={onClick}>
                        {icon && <ButtonIcon className={icon} />}
                        <span>{text}</span>
                      </FooterButton>
                    </AlertDialog.Action>
                  );
                })}
              </Footer>
            )}
            {!hideClose && (
              <CloseButton
                onClick={() => onOpenChange(false)}
                top={25}
                right={25}
              />
            )}
          </AlertDialogContent>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
};

export default Alert;
