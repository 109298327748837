import { FC } from "react";
import * as RadixSwitch from "@radix-ui/react-switch";
import styled from "@emotion/styled";

const SwitchRoot = styled(RadixSwitch.Root)(
  {
    all: "unset",
    width: 66,
    height: 30,
    display: "block",
    position: "relative",
    borderRadius: "100px",
    borderWidth: 1,
    borderStyle: "solid",
    boxShadow: "0px 4px 15px 0px rgba(190, 190, 190, 0.20)",
    WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
    "&:focus": { outline: "none" },
  },
  ({ theme: { colors } }) => ({
    backgroundColor: colors.white,
    borderColor: colors.grey3,
  })
);

const SwitchThumb = styled(RadixSwitch.Thumb)(
  {
    display: "block",
    width: 24,
    height: 24,
    borderRadius: "100px",
    transition: "transform 100ms",
    transform: "translateX(3px)",
    willChange: "transform",
    '&[data-state="checked"]': { transform: "translateX(39px)" },
    cursor: "pointer",
  },
  ({ theme: { colors } }) => ({
    backgroundColor: colors.purple,
  })
);

interface SwitchProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const Switch: FC<SwitchProps> = ({ checked, onChange }) => (
  <SwitchRoot checked={checked} onCheckedChange={onChange}>
    <SwitchThumb />
  </SwitchRoot>
);

export default Switch;
