import { FC, PropsWithChildren } from "react";
import { map } from "lodash";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { fadeIn } from "../../../../styles/animations";
import styled from "@emotion/styled";

const DropdownMenuContent = styled(DropdownMenu.Content)(
  {
    width: 174,
    borderRadius: 20,
    padding: "10px 15px",
    animationDuration: "500ms",
    animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
    willChange: "transform",
    zIndex: 2,
    '&[data-state="open"]': {
      animationName: fadeIn,
    },
  },
  ({ theme: { colors } }) => ({
    background: colors.lightgrey2,
  })
);

const DropdownMenuItem = styled(DropdownMenu.Item)(
  {
    all: "unset",
    height: 36,
    display: "flex",
    alignItems: "center",
    padding: "0 10px",
    fontWeight: 500,
    userSelect: "none",
    cursor: "pointer",
  },
  ({ theme: { colors, fontSize } }) => ({
    color: colors.black,
    borderBottom: `1px solid ${colors.lightblue}`,
    ":first-of-type": {
      borderTop: `1px solid ${colors.lightblue}`,
    },
    "&[data-highlighted]": {
      color: colors.white,
      backgroundColor: colors.purple,
      i: {
        color: colors.white,
      },
    },
    i: {
      marginRight: 10,
      fontSize: 16,
      color: colors.purple,
    },
    ...fontSize.sm,
  })
);

interface MenuItem {
  key: string;
  label: string;
  icon?: string;
}

interface DropdownProps extends PropsWithChildren {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  items: Array<MenuItem>;
  onClick: (key: string) => void;
}

const AddNoteDropdown: FC<DropdownProps> = ({
  open,
  onOpenChange,
  children,
  items,
  onClick,
}) => {
  return (
    <DropdownMenu.Root open={open} onOpenChange={onOpenChange}>
      <DropdownMenu.Trigger asChild>{children}</DropdownMenu.Trigger>
      <DropdownMenuContent align="start" alignOffset={-15} sideOffset={15}>
        {map(items, ({ key, label, icon }) => (
          <DropdownMenuItem key={key} onSelect={() => onClick(key)}>
            {icon && <i className={icon} />}
            <span>{label}</span>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu.Root>
  );
};

export default AddNoteDropdown;
