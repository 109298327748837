import Breadcrumbs from "../../../components/breadcrumbs";
import Loader from "../../../components/loader";
import { useBilling, useUser } from "../../../hooks";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getStyledScrollbar } from "../../../styles/scrollbar";
import {
  getBilling,
  getHistory,
  selectBillingHistory,
  selectIsLoadingHistory,
} from "../billingSlice";
import { useBilllingDetailsTable } from "./hooks";
import { Pagination } from "./pagination";
import styled from "@emotion/styled";
import { ceil, map, size, slice } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const Container = styled.div(
  {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 20,
    padding: "30px 0 30px 15px",
  },
  ({ theme: { media } }) => ({
    [media.md]: { padding: "30px 0 30px 40px" },
  }),
);

const LoaderContainer = styled.div({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const TableContainer = styled.div(
  {
    overflow: "auto",
    paddingBottom: 30,
  },
  ({ theme: { colors } }) => getStyledScrollbar(colors),
);

const Table = styled.table(
  {
    minWidth: 940,
    borderRadius: 10,
    overflow: "hidden",
    boxShadow: "0px 4px 15px 0px rgba(190, 190, 190, 0.20)",
  },
  ({ theme: { colors } }) => ({ background: colors.white }),
);

const Th = styled.th(
  {
    padding: "10px 15px",
    textAlign: "left",
    fontWeight: 500,
  },
  ({ theme: { colors, fontSize, media } }) => ({
    color: colors.white,
    background: colors.purple,
    ":not(:last-child)": { borderRight: `1px solid ${colors.purpleHover}` },
    ...fontSize.sm,
    [media.sm]: fontSize.md,
  }),
);

const Td = styled.td(
  {
    padding: "10px 15px",
    fontWeight: 500,
  },
  ({ theme: { colors, fontSize, media } }) => ({
    color: colors.black,
    border: `1px solid ${colors.lightgrey}`,
    ...fontSize.sm,
    [media.sm]: fontSize.md,
  }),
);

const ITEMS_PER_PAGE = 7;

const BillingDetailsPage = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);

  const dispatch = useAppDispatch();

  useUser();
  const isLoadingHistory = useAppSelector(selectIsLoadingHistory);
  const history = useAppSelector(selectBillingHistory);

  const { planList, loading: isLoadingBilling } = useBilling();

  const { cols, rows } = useBilllingDetailsTable(planList, history);

  useEffect(() => {
    dispatch(getHistory());
    dispatch(getBilling());
  }, [dispatch]);

  const breadcrumbsItems = useMemo(
    () => [
      { title: t("billing.main"), url: "/" },
      { title: t("billing.details"), url: "/billing/details" },
    ],
    [t],
  );

  const pages = ceil(size(rows) / ITEMS_PER_PAGE);

  if (isLoadingHistory || isLoadingBilling)
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );

  return (
    <Container>
      <Breadcrumbs items={breadcrumbsItems} />
      <TableContainer>
        {size(rows) > 0 && (
          <Table>
            <thead>
              <tr>
                {map(cols, ({ id, title }) => (
                  <Th key={id}>{title}</Th>
                ))}
              </tr>
            </thead>
            <tbody>
              {map(
                slice(rows, (page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE),
                (row) => {
                  if (!row) return null;
                  const {
                    id,
                    plan,
                    from,
                    to,
                    characters,
                    totalLiveAudio,
                    totalUploadingAudio,
                  } = row;
                  return (
                    <tr key={id}>
                      <Td>{plan}</Td>
                      <Td>{from}</Td>
                      <Td>{to}</Td>
                      <Td>{characters}</Td>
                      <Td>{totalLiveAudio}</Td>
                      <Td>{totalUploadingAudio}</Td>
                    </tr>
                  );
                },
              )}
            </tbody>
          </Table>
        )}
      </TableContainer>
      <Pagination page={page} pages={pages} pageChange={setPage} />
    </Container>
  );
};

export default BillingDetailsPage;
