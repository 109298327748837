import styled from "@emotion/styled";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import { FC, PropsWithChildren } from "react";

export const ScrollAreaRoot = styled(ScrollArea.Root)({
  width: "100%",
  overflow: "hidden",
  height: 273,
});

export const ScrollAreaViewport = styled(ScrollArea.Viewport)({
  width: "100%",
  height: "100%",
});

export const ScrollAreaScrollbar = styled(ScrollArea.Scrollbar)(
  {
    display: "flex",
    userSelect: "none",
    touchAction: "none",
    borderRadius: 100,
    '&[data-orientation="vertical"]': { width: 4 },
    '&[data-orientation="horizontal"]': {
      flexDirection: "column",
      height: 4,
    },
  },
  ({ theme: { colors } }) => ({
    background: colors.grey3,
  }),
);

export const ScrollAreaThumb = styled(ScrollArea.Thumb)(
  {
    flex: 1,
    position: "relative",
    borderRadius: 100,
    "&::before": {
      content: '""',
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "100%",
      height: "100%",
      minWidth: 4,
      minHeight: 44,
    },
  },
  ({ theme: { colors } }) => ({
    background: colors.purple,
  }),
);

const Scrollbars: FC<PropsWithChildren> = ({ children }) => {
  return (
    <ScrollAreaRoot type="auto">
      <ScrollAreaViewport>{children}</ScrollAreaViewport>
      <ScrollAreaScrollbar orientation="horizontal">
        <ScrollAreaThumb />
      </ScrollAreaScrollbar>
      <ScrollAreaScrollbar orientation="vertical">
        <ScrollAreaThumb />
      </ScrollAreaScrollbar>
      <ScrollArea.Corner />
    </ScrollAreaRoot>
  );
};

export default Scrollbars;
