import { useTranslation } from "react-i18next";
import { useRouteError } from "react-router";

const ErrorPage = () => {
  const error = useRouteError() as Error;
  const { t } = useTranslation();

  return (
    <div>
      <h1>{t("error-boundary")}</h1>
      <pre>{error.message || JSON.stringify(error)}</pre>
    </div>
  );
};

export default ErrorPage;
