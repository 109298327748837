import styled from "@emotion/styled";

const ValidationText = styled.span(
  {
    marginTop: 5,
  },
  ({ theme: { colors, fontSize } }) => ({ color: colors.red, ...fontSize.sm })
);

export default ValidationText;
