import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { first } from "lodash";
import AuthForm from "./components/auth-form";
import AuthFormCard from "./components/auth-form-card";
import AuthFormHeader from "./components/auth-form-header";
import FormInput from "./components/form-input";
import AuthFlexContainer from "./components/auth-flex-container";
import Button from "../../components/button";
import Link from "./components/link";
import AuthErrorAlert from "./components/auth-error-alert";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  closeResetPasswordModal,
  resetPassword,
  selectResetPasswordState,
} from "./authSlice";
import styled from "@emotion/styled";

const PsswordResetDescription = styled(AuthFlexContainer)({ marginBottom: 5 });
const LoginLink = styled(Link)({
  marginLeft: 40,
});

interface FormData {
  email: string;
}

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { loading, errors } = useAppSelector(selectResetPasswordState);
  const { register, handleSubmit } = useForm<FormData>();

  const onSubmit = handleSubmit(async (data) => {
    try {
      await dispatch(resetPassword(data.email)).unwrap();
      navigate("/auth-message?type=password-reset");
    } catch (error) {}
  });

  const onErrorDialogOpenChange = useCallback(
    (open: boolean) => {
      if (!open) dispatch(closeResetPasswordModal());
    },
    [dispatch]
  );

  return (
    <>
      <AuthFormCard>
        <AuthForm onSubmit={onSubmit}>
          <AuthFormHeader>{t("auth.passwordReset")}</AuthFormHeader>
          <PsswordResetDescription>
            {t("auth.passwordResetDescription")}
          </PsswordResetDescription>
          <FormInput
            register={register("email")}
            placeholder={t("auth.emailPlaceholder")}
            error={first(errors?.email)}
            required
          />
          <AuthFlexContainer>
            <Button type="submit" disabled={loading}>
              {t("auth.resetPassword")}
            </Button>
            <LoginLink to="/login">{t("auth.backToLogin")}</LoginLink>
          </AuthFlexContainer>
        </AuthForm>
      </AuthFormCard>
      <AuthErrorAlert errors={errors} onOpenChange={onErrorDialogOpenChange} />
    </>
  );
};

export default ResetPasswordPage;
