import { map } from "lodash";
import { Droppable } from "react-beautiful-dnd";
import Article from "./article";
import AddArticle from "./add-article";
import DndContext from "../../../context/dnd";
import { selectArticles, selectArticlesOrder } from "../dashboardSlice";
import { useAppSelector } from "../../../store/hooks";
import { DASHBOARD_DND } from "../constants";
import styled from "@emotion/styled";

const ArticlesListContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 30px;
  ${({ theme: { media } }) => `
    ${media.lg} { 
      padding: 30px 40px; 
    }
  `};
`;

const ArticlesListDroppable = styled.div`
  display: flex;
`;

const ArticlesList = () => {
  const articles = useAppSelector(selectArticles);
  const articlesOrder = useAppSelector(selectArticlesOrder);

  return (
    <ArticlesListContainer>
      <DndContext>
        <Droppable
          droppableId={DASHBOARD_DND.droppableIds.articlesList}
          type={DASHBOARD_DND.types.articlesList}
          direction="horizontal"
        >
          {(provided) => (
            <ArticlesListDroppable
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {map(articlesOrder, (id, index) => {
                const article = articles[id];
                if (!article) return null;
                return (
                  <Article key={article.id} article={article} index={index} />
                );
              })}
              {provided.placeholder}
            </ArticlesListDroppable>
          )}
        </Droppable>
      </DndContext>
      <AddArticle />
    </ArticlesListContainer>
  );
};

export default ArticlesList;
