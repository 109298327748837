import { ThemeProvider as Provider } from "@emotion/react";
import { FC, PropsWithChildren } from "react";

const colors = {
  white: "#FFF",
  white2: "#fbfaff",
  lightgrey: "#F7F7F7",
  lightgrey2: "#F4F3FB",
  grey: "#B1B0BD",
  grey2: "#C7C7C7",
  grey3: "#D9D9D9",
  purple: "#685DE7",
  purpleHover: "#5249BD",
  black: "#3C326F",
  lightblue: "#DDD4FF",
  lightblue2: "#E9E8FF",
  lightblue3: "#C1B6FC",
  lightblue4: "#EAE4FF",
  lightblue5: "#E5DFFF",
  red: "#FF0000",
  red2: "#E02823",
};

const mediaValues = {
  sm: 375,
  md: 768,
  lg: 1440,
};

const fontSize = {
  sm: {
    fontSize: 14,
    lineHeight: "18px",
  },
  md: {
    fontSize: 16,
    lineHeight: "20px",
  },
  lg: {
    fontSize: 20,
    lineHeight: "26px",
  },
};

export const theme = {
  colors,
  media: {
    sm: `@media (min-width: ${mediaValues.sm}px)`,
    md: `@media (min-width: ${mediaValues.md}px)`,
    lg: `@media (min-width: ${mediaValues.lg}px)`,
  },
  mediaValues,
  fontSize,
};

const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  return <Provider theme={theme}>{children}</Provider>;
};

export default ThemeProvider;
