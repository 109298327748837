import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { omit, size } from "lodash";
import { RootState } from "../../store";
import { AxiosError } from "axios";
import api from "../../lib/api";
import { t } from "i18next";
import { getToken, removeToken, saveToken } from "../../utils";

export interface User {
  username?: string;
  email?: string;
  pk?: number;
}

interface SignUpRequestParams {
  email: string;
  username: string;
  name?: string;
  website?: string;
  password1: string;
  password2: string;
}

interface LoginRequestParams {
  email: string;
  username: string;
  password: string;
}

interface NewPasswordParams {
  uid: string;
  token: string;
  new_password1: string;
  new_password2: string;
}

interface NewPasswordErrorResponse {
  uid?: string[];
  token?: string[];
  new_password1?: string[];
  new_password2?: string[];
  non_field_errors?: string[];
}

interface LoginResponse {
  key: string;
}

export interface ValidationErrors {
  email?: string[];
  username?: string[];
  name?: string[];
  website?: string[];
  password?: string[];
  password1?: string[];
  password2?: string[];
  new_password1?: string[];
  new_password2?: string[];
  old_password?: string[];
  non_field_errors?: string[];
}

interface AuthState {
  user: User;
  token: string | null;
  signUpState: {
    loading: boolean;
    errors?: ValidationErrors;
  };
  loginState: {
    loading: boolean;
    errors?: ValidationErrors;
  };
  getUserState: {
    loading: boolean;
  };
  logoutState: {
    loading: boolean;
    error?: string;
  };
  resetPasswordState: {
    loading: boolean;
    errors?: ValidationErrors;
  };
  newPasswordState: {
    loading: boolean;
    errors?: ValidationErrors;
  };
  changePasswordState: {
    loading: boolean;
    errors?: ValidationErrors;
  };
}

const initialState: AuthState = {
  user: {},
  token: getToken(),
  signUpState: {
    loading: false,
  },
  loginState: { loading: false },
  getUserState: { loading: false },
  logoutState: { loading: false },
  resetPasswordState: { loading: false },
  newPasswordState: { loading: false },
  changePasswordState: { loading: false },
};

export const signUp = createAsyncThunk<
  any,
  SignUpRequestParams,
  { rejectValue: ValidationErrors }
>("auth/signUp", async (data, { rejectWithValue }) => {
  try {
    const response = await api.post("dj-rest-auth/registration/", data);
    return response.data;
  } catch (error) {
    const { response } = error as AxiosError<ValidationErrors>;
    if (!response) throw error;
    return rejectWithValue(response.data);
  }
});

export const login = createAsyncThunk<
  string,
  { body: LoginRequestParams; rememberMe: boolean },
  { rejectValue: ValidationErrors }
>("auth/login", async ({ body, rememberMe }, { rejectWithValue }) => {
  try {
    const response = await api.post<LoginResponse>("dj-rest-auth/login/", body);
    const token = response.data.key;
    removeToken();
    saveToken(token, rememberMe);
    return token;
  } catch (error) {
    const { response } = error as AxiosError<ValidationErrors>;
    if (!response) throw error;
    return rejectWithValue(response.data);
  }
});

export const logout = createAsyncThunk("auth/logout", async () => {
  await api.post("dj-rest-auth/logout/");
  removeToken();
});

export const getUser = createAsyncThunk(
  "auth/getUser",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get<User>("dj-rest-auth/user/");
      return response.data;
    } catch (error) {
      const { response } = error as AxiosError;
      if (!response) throw error;
      return rejectWithValue(response.data);
    }
  }
);

export const resetPassword = createAsyncThunk<
  any,
  string,
  { rejectValue: ValidationErrors }
>("auth/resetPassword", async (email, { rejectWithValue }) => {
  try {
    await api.post("dj-rest-auth/password/reset/", { email });
  } catch (error) {
    const { response } = error as AxiosError<ValidationErrors>;
    if (!response) throw error;
    return rejectWithValue(response.data);
  }
});

export const newPassword = createAsyncThunk<
  any,
  NewPasswordParams,
  { rejectValue: ValidationErrors }
>("auth/newPassword", async (body, { rejectWithValue }) => {
  try {
    await api.post("dj-rest-auth/password/reset/confirm/", body);
  } catch (error) {
    const { response } = error as AxiosError<NewPasswordErrorResponse>;
    if (!response) throw error;
    const { new_password1, new_password2, uid, token, non_field_errors } =
      response.data;
    const errors: ValidationErrors = {
      password1: new_password1,
      password2: new_password2,
      non_field_errors,
    };
    if (size(uid) || size(token)) errors.non_field_errors = [t("commonError")];
    return rejectWithValue(errors);
  }
});

export const changePassword = createAsyncThunk<
  any,
  { new_password1: string; new_password2: string; old_password: string },
  { rejectValue: ValidationErrors }
>("auth/changePassword", async (body, { rejectWithValue }) => {
  try {
    await api.post("dj-rest-auth/password/change/", body);
  } catch (error) {
    const { response } = error as AxiosError<ValidationErrors>;
    if (!response) throw error;
    return rejectWithValue(response.data);
  }
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    closeSignUpErrorModal: (state) => {
      state.signUpState.errors = omit(
        state.signUpState.errors,
        "non_field_errors"
      );
    },
    closeLoginErrorModal: (state) => {
      state.loginState.errors = omit(
        state.loginState.errors,
        "non_field_errors"
      );
    },
    closeResetPasswordModal: (state) => {
      state.resetPasswordState.errors = omit(
        state.resetPasswordState.errors,
        "non_field_errors"
      );
    },
    closeNewPasswordModal: (state) => {
      state.newPasswordState.errors = omit(
        state.newPasswordState.errors,
        "non_field_errors"
      );
    },
    closeLogoutErrorModal: (state) => {
      state.logoutState.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signUp.pending, (state) => {
      state.signUpState = { loading: true };
    });
    builder.addCase(signUp.fulfilled, (state) => {
      state.signUpState = { loading: false };
    });
    builder.addCase(signUp.rejected, (state, action) => {
      state.signUpState = { loading: false, errors: action.payload };
    });

    builder.addCase(login.pending, (state) => {
      state.loginState = { loading: true };
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.loginState = { loading: false };
      state.token = action.payload;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loginState = { loading: false, errors: action.payload };
    });

    builder.addCase(getUser.pending, (state) => {
      state.getUserState = { loading: true };
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.getUserState = { loading: false };
      state.user = action.payload;
    });
    builder.addCase(getUser.rejected, (state) => {
      state.getUserState = { loading: false };
    });

    builder.addCase(logout.pending, (state) => {
      state.logoutState = { loading: true };
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.logoutState = { loading: false };
      state.user = {};
      state.token = null;
    });
    builder.addCase(logout.rejected, (state) => {
      state.logoutState = { loading: false, error: t("commonError") };
    });

    builder.addCase(resetPassword.pending, (state) => {
      state.resetPasswordState = { loading: true };
    });
    builder.addCase(resetPassword.fulfilled, (state) => {
      state.resetPasswordState = { loading: false };
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.resetPasswordState = { loading: false, errors: action.payload };
    });

    builder.addCase(newPassword.pending, (state) => {
      state.newPasswordState = { loading: true };
    });
    builder.addCase(newPassword.fulfilled, (state) => {
      state.newPasswordState = { loading: false };
    });
    builder.addCase(newPassword.rejected, (state, action) => {
      state.newPasswordState = { loading: false, errors: action.payload };
    });

    builder.addCase(changePassword.pending, (state) => {
      state.changePasswordState = { loading: true };
    });
    builder.addCase(changePassword.fulfilled, (state) => {
      state.changePasswordState = { loading: false };
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      state.changePasswordState = { loading: false, errors: action.payload };
    });
  },
});

export const {
  closeSignUpErrorModal,
  closeLoginErrorModal,
  closeLogoutErrorModal,
  closeResetPasswordModal,
  closeNewPasswordModal,
} = authSlice.actions;

export const selectSignUpState = (state: RootState) => state.auth.signUpState;
export const selectLoginState = (state: RootState) => state.auth.loginState;
export const selectLogoutState = (state: RootState) => state.auth.logoutState;
export const selectGetUserState = (state: RootState) => state.auth.getUserState;
export const selectResetPasswordState = (state: RootState) =>
  state.auth.resetPasswordState;
export const selectNewPasswordState = (state: RootState) =>
  state.auth.newPasswordState;
export const selectChangePasswordState = (state: RootState) =>
  state.auth.changePasswordState;
export const selectToken = (state: RootState) => state.auth.token;
export const selectUser = (state: RootState) => state.auth.user;

export default authSlice.reducer;
