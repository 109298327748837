import Button from "../../../../components/button";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  Article,
  ArticleItemType,
  addArticleItem,
  errorModalChange,
  selectAddArticleItemState,
  setAddingItemForm,
} from "../../dashboardSlice";
import { useScrollIntoView } from "../../hooks";
import AudioPanel from "../audio-panel";
import styled from "@emotion/styled";
import { first, size } from "lodash";
import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useFilePicker } from "use-file-picker";
import { FileSizeValidator } from "use-file-picker/validators";

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: 20,
});

const Picker = styled.div(
  {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    padding: 15,
    borderRadius: 5,
  },
  ({ theme: { colors } }) => ({
    background: colors.lightgrey2,
  }),
);

const ChoiceFile = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const ChoiceButton = styled(Button)({
  flexShrink: 0,
  flexBasis: 135,
});

const FileName = styled.div(
  {
    fontWeight: 500,
    overflow: "hidden",
  },
  ({ theme: { colors, fontSize } }) => ({
    color: colors.purple,
    ...fontSize.md,
  }),
);

const ClipIcon = styled.i({
  marginRight: 10,
  fontSize: 16,
});

const Info = styled.div({}, ({ theme: { colors, fontSize } }) => ({
  color: colors.grey,
  ...fontSize.md,
}));

const Footer = styled.div({ display: "flex", alignItems: "center", gap: 30 });

const CancelIcon = styled.i({}, ({ theme: { colors } }) => ({
  fontSize: 15,
  color: colors.purple,
  cursor: "pointer",
}));

interface SelectAudioFileProps {
  article: Article;
  itemType: ArticleItemType;
  url?: string;
  fileName?: string;
  onResetItemType: () => void;
}

const FILE_TYPES = [
  ".mp3",
  ".mp4",
  ".acc",
  ".wav",
  ".flac",
  ".pcm",
  ".m4a",
  ".ogg",
  ".opus",
  ".webm",
];

const SelectAudioFile: FC<SelectAudioFileProps> = ({
  article,
  itemType,
  url,
  fileName,
  onResetItemType,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { loading: addArticleItemLoading = false } =
    useAppSelector((state) => selectAddArticleItemState(state, article.id)) ||
    {};
  const ref = useScrollIntoView<HTMLDivElement>();

  const { loading, plainFiles, filesContent, errors, openFilePicker } =
    useFilePicker({
      multiple: false,
      readAs: "DataURL",
      accept: FILE_TYPES,
      validators: [
        new FileSizeValidator({ maxFileSize: 25 * 1024 * 1024 /* 25 MB */ }),
      ],
      onFilesRejected: () => {
        dispatch(
          errorModalChange({
            open: true,
            message: t("dashboard.sizeValidation"),
          }),
        );
      },
    });

  const onAdd = useCallback(async () => {
    const file = first(plainFiles);
    if (size(file?.name) > 100) {
      dispatch(errorModalChange({ open: true }));
      return;
    }
    const url = first(filesContent)?.content;
    dispatch(
      setAddingItemForm({
        article_id: article.id,
        url,
        fileName: file?.name,
      }),
    );
    await dispatch(
      addArticleItem({
        articleItem: {
          article_id: article.id,
          type: itemType,
          input_audio_name: file?.name,
        },
        url,
      }),
    );
  }, [plainFiles, filesContent, dispatch, article.id, itemType]);

  const selectedFileName = first(plainFiles)?.name;

  return (
    <Container ref={ref}>
      <Picker>
        {addArticleItemLoading ? (
          <AudioPanel
            type={itemType}
            src={url || ""}
            isLoading={addArticleItemLoading}
            fileName={fileName}
          />
        ) : (
          <>
            <ChoiceFile>
              {selectedFileName && <FileName>{selectedFileName}</FileName>}
              <ChoiceButton onClick={openFilePicker}>
                <ClipIcon className="icon-paper-clip" />
                {t("dashboard.choiceFile")}
              </ChoiceButton>
            </ChoiceFile>
            <Info>
              {t("upTo")} 25 MB
              <br />
              MP3, MP4, AAC, WAV, FLAC,
              <br />
              PCM, M4A, Ogg, Opus, WebM
            </Info>
          </>
        )}
      </Picker>
      {!addArticleItemLoading && (
        <Footer>
          <Button
            disabled={loading || !size(plainFiles) || size(errors) > 0}
            onClick={onAdd}
          >
            {t("dashboard.addNoteButton")}
          </Button>
          <CancelIcon className="icon-close" onClick={onResetItemType} />
        </Footer>
      )}
    </Container>
  );
};

export default SelectAudioFile;
