import { FC } from "react";
import { map, range, reduce } from "lodash";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";

interface LoaderProps {
  size?: "normal" | "large";
}

const itemsArr = range(1, 17);

const loaderAnimation = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const StyledLoader = styled.div<LoaderProps>(
  {
    display: "inline-block",
    position: "relative",
  },
  ({ size }) => {
    const isLarge = size === "large";
    const sizeValue = isLarge ? 70 : 46;
    return {
      width: `${sizeValue}px`,
      height: `${sizeValue}px`,
    };
  }
);

const LoaderItem = styled.div<LoaderProps>(
  {
    animation: `${loaderAnimation} 1.6s linear infinite`,
  },
  ({ theme: { colors }, size }) => {
    const isLarge = size === "large";
    const sizeValue = isLarge ? 70 : 46;
    return `
      transform-origin: ${sizeValue / 2}px ${sizeValue / 2}px;
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: ${isLarge ? 32 : 20}px;
        width: ${isLarge ? 5 : 3}px;
        height: ${isLarge ? 15 : 10}px;
        border-radius: 5px;
        background: ${colors.purple};
      }
      ${reduce(
        itemsArr,
        (result, item) => {
          return (
            result +
            `
            &:nth-of-type(${item}) {
              transform: rotate(${item * 22.5}deg);
              animation-delay: ${(item - itemsArr.length) * 0.1}s;
            }`
          );
        },
        ""
      )}
    `;
  }
);

const Loader: FC<LoaderProps> = ({ size }) => {
  return (
    <StyledLoader size={size}>
      {map(itemsArr, (index) => (
        <LoaderItem key={index} size={size} />
      ))}
    </StyledLoader>
  );
};

export default Loader;
