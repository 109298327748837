import { FC, PropsWithChildren } from "react";
import { toNumber } from "lodash";
import { DragDropContext, OnDragEndResponder } from "react-beautiful-dnd";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  changeArticleItemsOrder,
  changeArticlesOrder,
  moveArticleItem,
  selectArticleItems,
  selectArticlesOrder,
} from "../pages/root/dashboardSlice";
import { reorder } from "../utils";
import { DASHBOARD_DND } from "../pages/root/constants";

const DndContext: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();

  const articlesOrder = useAppSelector(selectArticlesOrder);
  const articleItems = useAppSelector(selectArticleItems);

  const onDragEnd: OnDragEndResponder = ({ source, destination, type }) => {
    if (!destination) return;
    if (
      destination.index === source.index &&
      destination.droppableId === source.droppableId
    )
      return;

    if (type === DASHBOARD_DND.types.articleItemsList) {
      if (source.droppableId !== destination.droppableId) {
        const source_article_id = toNumber(source.droppableId);
        const destination_article_id = toNumber(destination.droppableId);

        if (
          !articleItems[source_article_id] ||
          !articleItems[destination_article_id]
        )
          return;
        const sourceOrder = [...articleItems[source_article_id].order];
        const destinationOrder = [
          ...articleItems[destination_article_id].order,
        ];
        const [sourceId] = sourceOrder.splice(source.index, 1);
        destinationOrder.splice(destination.index, 0, sourceId);
        dispatch(
          moveArticleItem({
            id: sourceId,
            source_article_id,
            destination_article_id,
            sourceOrder,
            destinationOrder,
            sourceOrigOrder: articleItems[source_article_id].order,
            destinationOrigOrder: articleItems[destination_article_id].order,
          })
        );
        return;
      }

      const article_id = toNumber(source.droppableId);
      const origOrder = articleItems[article_id]?.order;
      if (!origOrder) return;
      const order = reorder(source.index, destination.index, origOrder);
      dispatch(
        changeArticleItemsOrder({ article_id: article_id, order, origOrder })
      );
      return;
    }

    const order = reorder(source.index, destination.index, articlesOrder);
    dispatch(changeArticlesOrder({ order, origOrder: articlesOrder }));
  };

  return <DragDropContext onDragEnd={onDragEnd}>{children}</DragDropContext>;
};

export default DndContext;
