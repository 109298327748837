import styled from "@emotion/styled";

const AuthForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 20px;
  gap: 15px;
`;

export default AuthForm;
