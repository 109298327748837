import { FormEventHandler, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import api from "../../lib/api";
import Button from "../../components/button";
import Loader from "../../components/loader";
import AuthFlexContainer from "./components/auth-flex-container";
import AuthFormHeader from "./components/auth-form-header";
import AuthForm from "./components/auth-form";
import { AuthMessagePageCard } from "./message";
import styled from "@emotion/styled";

const LoaderContainer = styled.div({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const CompleteRegistrationPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { key } = useParams();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    api
      .post("dj-rest-auth/registration/verify-email/", { key })
      .catch(() => {
        setError(true);
      })
      .finally(() => setLoading(false));
  }, [key]);

  const onSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    navigate("/login");
  };

  return (
    <AuthMessagePageCard>
      {loading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : error ? (
        <AuthForm>
          <AuthFormHeader>{t("auth.errorHeader")}</AuthFormHeader>
          <AuthFlexContainer>{t("auth.confirmEmailError")}</AuthFlexContainer>
        </AuthForm>
      ) : (
        <AuthForm onSubmit={onSubmit}>
          <AuthFormHeader>{t("auth.successHeader")}</AuthFormHeader>
          <AuthFlexContainer>{t("auth.accountCreated")}</AuthFlexContainer>
          <AuthFlexContainer>
            <Button type="submit">{t("auth.login")}</Button>
          </AuthFlexContainer>
        </AuthForm>
      )}
    </AuthMessagePageCard>
  );
};

export default CompleteRegistrationPage;
