import { RouterProvider as Provider } from "react-router";
import { createBrowserRouter } from "react-router-dom";
import Layout from "../pages/layout";
import RootPage from "../pages/root";
import ErrorPage from "../pages/error";
import LogoPage from "../pages/logo";
import LoginPage from "../pages/auth/login";
import SignUpPage from "../pages/auth/sign-up";
import CompleteRegistrationPage from "../pages/auth/complete-registration";
import ResetPasswordPage from "../pages/auth/password-reset";
import NewPasswordPage from "../pages/auth/new-password";
import AuthMessagePage from "../pages/auth/message";
import UserSettingsPage from "../pages/user-settings";
import { BillingPaywallPage } from "../pages/billing";
import BillingDetailsPage from "../pages/billing/details";
import InstallPage from "../pages/install";
import SupportPage from "../pages/support";

export const router = createBrowserRouter([
  {
    path: "/",
    Component: Layout,
    ErrorBoundary: ErrorPage,
    children: [
      { index: true, Component: RootPage },
      { path: "logo", Component: LogoPage },
      { path: "user-settings", Component: UserSettingsPage },

      {
        handle: { isAuthRoute: true },
        children: [
          { path: "login", Component: LoginPage },
          { path: "sign-up", Component: SignUpPage },
          {
            path: "complete-registration/:key/",
            Component: CompleteRegistrationPage,
          },
          { path: "reset-password", Component: ResetPasswordPage },
          { path: "new-password/:uid/:token/", Component: NewPasswordPage },
          { path: "auth-message", Component: AuthMessagePage },
        ],
      },

      {
        handle: { isPromoRoute: true },
        path: "install",
        Component: InstallPage,
      },

      { path: "/support", Component: SupportPage },

      {
        path: "billing",
        children: [
          { path: "paywall", Component: BillingPaywallPage },
          { path: "details", Component: BillingDetailsPage },
        ],
      },
    ],
  },
]);

const RouterProvider = () => {
  return <Provider router={router} />;
};

export default RouterProvider;
