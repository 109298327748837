import { useEffect } from "react";
import Dashboard from "./components/dashboard";
import Loader from "../../components/loader";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getArticles,
  getLanguageOptions,
  getStyleOptions,
  selectGetArticleItemsState,
  selectGetArticleResultsState,
  selectGetArticlesState,
  selectGetLanguageOptionsState,
  selectGetStyleOptionsState,
} from "./dashboardSlice";
import { useBilling, useUser } from "../../hooks";
import styled from "@emotion/styled";
import { getBilling } from "../billing/billingSlice";

const LoaderContainer = styled.div({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const RootPage = () => {
  const dispatch = useAppDispatch();
  const { loading: articlesLoading } = useAppSelector(selectGetArticlesState);
  const { loading: styleOptionsLoading } = useAppSelector(
    selectGetStyleOptionsState
  );
  const { loading: languageOptionsLoading } = useAppSelector(
    selectGetLanguageOptionsState
  );
  const { loading: articleItemsLoading } = useAppSelector(
    selectGetArticleItemsState
  );
  const { loading: articleResultsLoading } = useAppSelector(
    selectGetArticleResultsState
  );

  const { loading: userLoading } = useUser();
  const { loading: billingLoading } = useBilling();

  const isLoading =
    billingLoading ||
    userLoading ||
    articlesLoading ||
    styleOptionsLoading ||
    languageOptionsLoading ||
    articleItemsLoading ||
    articleResultsLoading;

  useEffect(() => {
    dispatch(getArticles());
    dispatch(getStyleOptions());
    dispatch(getLanguageOptions());
    dispatch(getBilling());
  }, [dispatch]);

  if (isLoading)
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );

  return <Dashboard />;
};

export default RootPage;
