import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { first } from "lodash";
import AuthForm from "./components/auth-form";
import AuthFormCard from "./components/auth-form-card";
import AuthFormHeader from "./components/auth-form-header";
import FormInput from "./components/form-input";
import AuthFlexContainer from "./components/auth-flex-container";
import Link from "./components/link";
import Button from "../../components/button";
import styled from "@emotion/styled";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  closeNewPasswordModal,
  newPassword,
  selectNewPasswordState,
} from "./authSlice";
import AuthErrorAlert from "./components/auth-error-alert";

const SignUpLink = styled(Link)({
  marginLeft: 40,
});

interface FormData {
  password1: string;
  password2: string;
}

const NewPasswordPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { loading, errors } = useAppSelector(selectNewPasswordState);
  const { register, handleSubmit } = useForm<FormData>();
  const { uid, token } = useParams();

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (!uid || !token) return;
      const { password1, password2 } = data;
      const body = {
        uid,
        token,
        new_password1: password1,
        new_password2: password2,
      };
      await dispatch(newPassword(body)).unwrap();
      navigate("/auth-message?type=password-changed");
    } catch (error) {}
  });

  const onErrorDialogOpenChange = useCallback(
    (open: boolean) => {
      if (!open) dispatch(closeNewPasswordModal());
    },
    [dispatch]
  );

  return (
    <>
      <AuthFormCard>
        <AuthForm onSubmit={onSubmit}>
          <AuthFormHeader>{t("auth.newPassword")}</AuthFormHeader>
          <FormInput
            register={register("password1")}
            type="password"
            placeholder={t("auth.passwordPlaceholder")}
            error={first(errors?.password1)}
            required
          />
          <FormInput
            register={register("password2")}
            type="password"
            placeholder={t("auth.passwordAgainPlaceholder")}
            error={first(errors?.password2)}
            required
          />
          <AuthFlexContainer>
            <Button type="submit" disabled={loading}>
              {t("auth.resetPassword")}
            </Button>
            <SignUpLink to="/login">{t("auth.signUp")}</SignUpLink>
          </AuthFlexContainer>
        </AuthForm>
      </AuthFormCard>
      <AuthErrorAlert errors={errors} onOpenChange={onErrorDialogOpenChange} />
    </>
  );
};

export default NewPasswordPage;
