import AnimatedButton from "../../../components/animated-button";
import DeleteConfirm from "../../../components/delete-confirm";
import Textarea from "../../../components/textarea";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { copyText } from "../../../utils";
import {
  RestrictionTypes,
  billingRestrictionModalChange,
  updateCurrentPlan,
} from "../../billing/billingSlice";
import {
  ArticleItemType,
  articleItemModalChange,
  patchArticleItem,
  rewriteArticleItem,
  selectArticleItemModal,
  selectArticleItems,
  selectRewriteArticleItemState,
  resetArticleItemResultFavorite,
  deleteArticleItemResult,
} from "../dashboardSlice";
import { isArticleItemError } from "../utils";
import ArticleItemFavorite from "./article-item-favorite";
import ArticleItemResult from "./article-item-result";
import AudioPanel from "./audio-panel";
import FullsizeModal from "./fullsize-modal";
import LanguageSelect from "./language-select";
import ResponsiveButton from "./responsive-button";
import WritingStyleSelect from "./writing-style-select";
import styled from "@emotion/styled";
import { debounce, isNil, last, map, noop, size, some } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const Modal = styled(FullsizeModal)(
  { margin: "20px auto" },
  ({ theme: { media } }) => ({
    [media.sm]: {
      margin: "80px auto",
    },
    [media.md]: {
      margin: "100px auto",
    },
    ".icon-close": {
      top: 20,
      right: 15,
      [media.sm]: {
        top: 30,
        right: 25,
      },
      [media.md]: {
        top: 35,
        right: 40,
      },
    },
  }),
);

const Container = styled.div(
  {
    display: "flex",
    flexDirection: "column",
    gap: 40,
  },
  ({
    theme: {
      media: { md },
    },
  }) => ({ [md]: { gap: 50 } }),
);

const ArticleItemModalAudioPanel = styled(AudioPanel)<{
  type: ArticleItemType;
}>(
  { alignSelf: "start", justifyContent: "start" },
  ({ theme: { colors, media }, type }) => ({
    width: type === "audio_file" ? "auto" : 290,
    [media.md]: {
      maxWidth: type === "audio_file" ? "80%" : "auto",
      width: type === "audio_file" ? "auto" : 365,
    },
    ".audio-player": {
      background: colors.lightgrey2,
    },
    ".file-name": {
      minHeight: 50,
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
      padding: "15px 20px",
      background: colors.lightgrey2,
      borderRadius: 50,
    },
    ".download-link": {
      background: colors.lightgrey2,
    },
  }),
);

const NoteContainer = styled.div(
  {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    gap: 15,
    fontSize: 18,
    fontWeight: 500,
    lineHeight: "23px",
  },
  ({ theme: { colors } }) => ({
    color: colors.black,
  }),
);

const NoteTitle = styled.span<{ isAudioItem?: boolean }>(
  {},
  ({ theme: { media }, isAudioItem }) => ({
    [media.md]: { display: isAudioItem ? "none" : "block" },
  }),
);

export const ModalTextArea = styled(Textarea)(
  {},
  ({ theme: { media, fontSize } }) => ({
    ...fontSize.sm,
    [media.sm]: fontSize.md,
  }),
);

const LoaderTextArea = styled(ModalTextArea)({ height: 200 });

const Footer = styled.div(
  {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    alignItems: "start",
    gap: 15,
  },
  ({ theme: { media } }) => ({
    [media.sm]: {
      gap: 20,
    },
    [media.md]: {
      gap: 50,
      flexDirection: "row",
      justifyContent: "space-between",
    },
  }),
);

const Selects = styled.div(
  {
    order: 2,
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  ({ theme: { media } }) => ({
    [media.sm]: { gap: 15 },
    [media.md]: { gap: 20 },
  }),
);

const LanguageSelects = styled.div(
  { display: "flex", flexDirection: "column", gap: 10 },
  ({ theme: { media } }) => ({
    [media.sm]: {
      flexDirection: "row",
      flexWrap: "wrap",
      rowGap: 15,
      columnGap: 40,
    },
  }),
);

const Rewrite = styled.div(
  {
    order: 3,
  },
  ({ theme: { media } }) => ({
    [media.md]: { order: 2, flexGrow: 1 },
  }),
);

const ActionButtons = styled.div(
  {
    order: 3,
    justifySelf: "end",
    display: "none",
    gap: 20,
  },
  ({ theme: { media } }) => ({
    [media.md]: {
      display: "flex",
    },
  }),
);

const Title = styled.div({ display: "flex", alignItems: "center", gap: 15 });

const ButtonsContainer = styled.div({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  gap: 20,
});

const ArticleItemModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loadingState = useAppSelector(selectRewriteArticleItemState);
  const articleItems = useAppSelector(selectArticleItems);
  const articleItem = useAppSelector(selectArticleItemModal);

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  let origArticleItem =
    articleItem && articleItems[articleItem.article_id]?.items[articleItem.id];

  const loading = articleItem?.id
    ? loadingState[articleItem.id] ||
      (articleItem.status !== "ready" &&
        !isArticleItemError(articleItem.status))
    : false;

  const {
    input_text,
    input_language = 1,
    output_language = 1,
    style = 1,
  } = articleItem || {};

  const onOpenChange = useCallback(
    (open: boolean) => {
      if (!open) dispatch(articleItemModalChange(null));
    },
    [dispatch],
  );

  const onRewrite = async () => {
    let canRewrite = true;
    try {
      const { isCharactersExceeded } = await dispatch(
        updateCurrentPlan(),
      ).unwrap();
      canRewrite = !isCharactersExceeded;
    } catch (error) {}

    if (!canRewrite) {
      dispatch(
        billingRestrictionModalChange({
          open: true,
          type: RestrictionTypes.characters,
        }),
      );
      return;
    }
    if (origArticleItem && articleItem) {
      dispatch(
        rewriteArticleItem({
          articleItem: origArticleItem,
          change: articleItem,
        }),
      );
    }
  };

  const onSaveInputText = useCallback(
    (input_text: string) => {
      if (!origArticleItem) return;
      dispatch(
        patchArticleItem({
          articleItem: origArticleItem,
          change: { input_text },
        }),
      );
    },
    [dispatch, origArticleItem],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSaveText = useCallback(debounce(onSaveInputText, 1000), [
    origArticleItem,
  ]);

  const onCopyInputText = useCallback(() => copyText(input_text), [input_text]);

  const lastResult = last(articleItem?.article_item_results);

  const onDeleteLastResult = useCallback(() => {
    if (lastResult && articleItem?.article_id && articleItem?.id) {
      dispatch(
        deleteArticleItemResult({
          articleId: articleItem?.article_id,
          articleItemId: articleItem?.id,
          id: lastResult.id,
        }),
      );
    }
  }, [articleItem?.article_id, articleItem?.id, dispatch, lastResult]);

  const onCopyLastResult = useCallback(() => {
    copyText(lastResult?.text);
  }, [lastResult?.text]);

  const isAudioItem =
    articleItem?.type === "audio_file" || articleItem?.type === "live_audio";

  const isInputTextFavorite = useMemo(
    () => !some(articleItem?.article_item_results, "favorite"),
    [articleItem?.article_item_results],
  );

  if (!articleItem) return null;

  return (
    <>
      <Modal open={!!articleItem} onOpenChange={onOpenChange}>
        <Container>
          {isAudioItem && articleItem.file_url && (
            <NoteContainer>
              <NoteTitle isAudioItem={isAudioItem}>
                {t(
                  articleItem.type === "audio_file"
                    ? "dashboard.audioNote"
                    : "dashboard.liveAudioNote",
                )}
              </NoteTitle>
              <ArticleItemModalAudioPanel
                src={articleItem.file_url}
                type={articleItem.type}
                fileName={articleItem.input_audio_name}
              />
            </NoteContainer>
          )}
          {!isNil(input_text) && (
            <NoteContainer>
              <Title>
                <ArticleItemFavorite
                  favorite={isInputTextFavorite}
                  onClick={() => {
                    if (isInputTextFavorite) return;
                    dispatch(
                      resetArticleItemResultFavorite({
                        articleId: articleItem.article_id,
                        articleItemId: articleItem.id,
                      }),
                    );
                  }}
                />
                {isAudioItem
                  ? t("dashboard.originalTranscript")
                  : t("dashboard.inputNote")}
              </Title>
              <ModalTextArea
                value={input_text}
                onChange={(input_text) => {
                  dispatch(
                    articleItemModalChange({
                      ...articleItem,
                      input_text,
                    }),
                  );
                  debouncedSaveText(input_text);
                }}
                initMaxHeight={492}
                resize
              />
              <ButtonsContainer>
                <AnimatedButton
                  variant="outline"
                  onClick={onCopyInputText}
                  disabled={loading}
                  iconClassName="icon-copy"
                  text={t("copy")}
                />
              </ButtonsContainer>
            </NoteContainer>
          )}

          {map(articleItem.article_item_results, (result, index) => (
            <ArticleItemResult
              key={result.id}
              {...result}
              articleId={articleItem.article_id}
              articleItemId={articleItem.id}
              isLastItem={index === size(articleItem.article_item_results) - 1}
            />
          ))}

          {loading && <LoaderTextArea value="" onChange={noop} isLoading />}

          <Footer>
            <Selects>
              <LanguageSelects>
                <LanguageSelect
                  type="input"
                  value={input_language}
                  onChange={(input_language) =>
                    dispatch(
                      articleItemModalChange({
                        ...articleItem,
                        input_language,
                      }),
                    )
                  }
                  disabled={loading}
                />
                <LanguageSelect
                  type="output"
                  value={output_language}
                  inputValue={input_language}
                  onChange={(output_language) =>
                    dispatch(
                      articleItemModalChange({
                        ...articleItem,
                        output_language,
                      }),
                    )
                  }
                  disabled={loading}
                />
              </LanguageSelects>
              <WritingStyleSelect
                value={style}
                onChange={(style) =>
                  dispatch(
                    articleItemModalChange({
                      ...articleItem,
                      style,
                    }),
                  )
                }
                disabled={loading}
                filterLanguageValue={output_language}
              />
            </Selects>
            <Rewrite>
              <ResponsiveButton onClick={onRewrite} disabled={loading}>
                <i className="icon-rewrite" />
                {t("rewrite")}
              </ResponsiveButton>
            </Rewrite>
            {lastResult && (
              <ActionButtons>
                <AnimatedButton
                  variant="outline"
                  onClick={onCopyLastResult}
                  disabled={loading}
                  iconClassName="icon-copy"
                  text={t("copy")}
                />
                <AnimatedButton
                  variant="outline"
                  onClick={() => setDeleteConfirmOpen(true)}
                  disabled={loading}
                  iconClassName="icon-trash"
                  text={t("delete")}
                />
              </ActionButtons>
            )}
          </Footer>
        </Container>
      </Modal>
      <DeleteConfirm
        open={deleteConfirmOpen}
        onOpenChange={setDeleteConfirmOpen}
        onAccept={onDeleteLastResult}
      />
    </>
  );
};

export default ArticleItemModal;
