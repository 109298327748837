import { ChangeEvent, useCallback, forwardRef, KeyboardEvent } from "react";
import styled from "@emotion/styled";

interface StyledInputProps {
  error?: boolean;
}

export const StyledInput = styled.input<StyledInputProps>(
  {
    padding: "10px 15px",
    border: "none",
    borderRadius: "5px",
    outlineWidth: "1px",
    outlineStyle: "solid",
    transition: "outline-color 300ms",
  },
  ({ theme: { colors, fontSize }, error }) => ({
    color: colors.black,
    background: colors.lightgrey2,
    outlineColor: error ? colors.red : "transparent",
    "::placeholder": {
      color: error ? colors.red : colors.grey,
    },
    ":focus-visible": {
      outlineColor: error ? colors.red : colors.purple,
    },
    ...fontSize.md,
  })
);

interface InputProps extends StyledInputProps {
  value: string;
  onChange: (value: string) => void;
  onEnter?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  placeholder?: string;
  className?: string;
  autoFocus?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      value,
      onChange,
      onEnter,
      placeholder,
      error,
      className,
      autoFocus,
      onFocus,
      onBlur,
    },
    ref
  ) => {
    const handleChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
      },
      [onChange]
    );

    const handleEnter = useCallback(
      (event: KeyboardEvent<HTMLInputElement>) => {
        const { key } = event;
        if (key === "Enter" && onEnter) onEnter();
      },
      [onEnter]
    );

    return (
      <StyledInput
        ref={ref}
        autoFocus={autoFocus}
        className={className}
        value={value}
        onChange={handleChange}
        onKeyDown={handleEnter}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        error={error}
      />
    );
  }
);

export default Input;
