import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { first } from "lodash";
import Card from "./card";
import Popover from "./popover";
import AuthErrorAlert from "../pages/auth/components/auth-error-alert";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  User as IUser,
  closeLogoutErrorModal,
  logout,
  selectLogoutState,
  selectUser,
} from "../pages/auth/authSlice";
import { changePlan, selectCurrentPlan } from "../pages/billing/billingSlice";
import { changeFreePlan } from "../pages/billing/utils";
import { USETIFUL_STEP_CLASSNAMES } from "../utils/usetiful";
import styled from "@emotion/styled";

const Container = styled.div`
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  background: #685de7;
  border-radius: 50%;
  user-select: none;
`;

const Menu = styled(Card)({
  minWidth: 300,
  display: "flex",
  flexDirection: "column",
  padding: 20,
  gap: 8,
});

const MenuItem = styled.div(
  {
    padding: 5,
    borderRadius: 4,
    cursor: "pointer",
    i: {
      marginRight: 5,
    },
  },
  ({ theme: { colors, fontSize } }) => ({
    color: colors.purple,
    ":hover": { color: colors.white, background: colors.purple },
    ...fontSize.md,
  })
);

const BillingMenuItem = styled(MenuItem)({
  display: "flex",
  alignItems: "center",
  i: {
    width: 20,
    height: 20,
    display: "none",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 14,
  },
  ":hover": {
    i: { display: "flex" },
  },
});

interface UserMenuProps {
  user: IUser;
  planName: string;
  onClose: () => void;
  isCurrentFree?: boolean;
  isAppSumo?: boolean;
}

const UserMenu: FC<UserMenuProps> = ({
  user,
  planName,
  onClose,
  isCurrentFree,
  isAppSumo,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { error } = useAppSelector(selectLogoutState);

  const onLogout = useCallback(() => {
    dispatch(logout());
    onClose();
  }, [dispatch, onClose]);

  const onOpenManageSubscription = useCallback(async () => {
    if (isCurrentFree && user) {
      changeFreePlan(user);
    } else {
      await dispatch(changePlan());
    }
    onClose();
  }, [dispatch, isCurrentFree, onClose, user]);

  const onOpenSettings = useCallback(() => {
    navigate("/user-settings");
    onClose();
  }, [navigate, onClose]);

  const onOpenBilling = useCallback(() => {
    navigate("/billing/paywall");
    onClose();
  }, [navigate, onClose]);

  const onOpenBillingDetails = useCallback(() => {
    navigate("/billing/details");
    onClose();
  }, [navigate, onClose]);

  const onOpenForMobile = useCallback(() => {
    window
      .open("https://www.flipner.com/installing-the-application", "_blank")
      ?.focus();
    onClose();
  }, [onClose]);

  const onOpenSupport = useCallback(() => {
    navigate("/support");
    onClose();
  }, [navigate, onClose]);

  const onOpenFeatureRequest = useCallback(() => {
    window
      .open("https://flipner.canny.io/feature-request?sort=top", "_blank")
      ?.focus();
    onClose();
  }, [onClose]);

  const onAlertOpenChange = useCallback(
    (open: boolean) => {
      if (!open) dispatch(closeLogoutErrorModal());
    },
    [dispatch]
  );

  return (
    <>
      <Menu>
        <div>{user.username}</div>
        {planName && (
          <BillingMenuItem onClick={onOpenBilling}>
            <i className="icon-star" />
            <span>
              {t("billing.plan")}: {planName}
            </span>
          </BillingMenuItem>
        )}
        {!isAppSumo && (
          <MenuItem onClick={onOpenManageSubscription}>
            <span>{t("userMenu.manageSubscription")}</span>
          </MenuItem>
        )}
        <MenuItem onClick={onOpenSettings}>
          <span>{t("userMenu.settings")}</span>
        </MenuItem>
        <MenuItem onClick={onOpenBillingDetails}>
          <span>{t("billing.details")}</span>
        </MenuItem>
        <MenuItem onClick={onOpenForMobile}>
          <span>{t("userMenu.forMobile")}</span>
        </MenuItem>
        <MenuItem onClick={onOpenSupport}>
          <span>{t("userMenu.support")}</span>
        </MenuItem>
        <MenuItem onClick={onOpenFeatureRequest}>
          <span>{t("userMenu.featureRequest")}</span>
        </MenuItem>
        <MenuItem onClick={onLogout}>
          <i className="icon-logout" />
          <span>{t("userMenu.logout")}</span>
        </MenuItem>
      </Menu>
      <AuthErrorAlert error={error} onOpenChange={onAlertOpenChange} />
    </>
  );
};

const User = () => {
  const user = useAppSelector(selectUser);
  const plan = useAppSelector(selectCurrentPlan);
  const { name = "", type } = plan?.planDetails || {};

  const [open, setOpen] = useState(false);

  const onClose = useCallback(() => setOpen(false), []);

  if (!user.username) return null;

  return (
    <Popover
      open={open}
      onOpenChange={setOpen}
      content={
        <UserMenu
          user={user}
          planName={name}
          isCurrentFree={type === "free"}
          isAppSumo={type === "appsumo"}
          onClose={onClose}
        />
      }
      contentProps={{ align: "end", sideOffset: 15 }}
      showClose={false}
    >
      <Container className={USETIFUL_STEP_CLASSNAMES.profile}>
        {first(user.username)}
      </Container>
    </Popover>
  );
};

export default User;
