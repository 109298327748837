import Button from "../../../components/button";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  billingRestrictionModalChange,
  RestrictionTypes,
  updateCurrentPlan,
} from "../../billing/billingSlice";
import {
  Article,
  articleModalChange,
  rewriteArticle,
  selectArticleItems,
  selectHasArticleResults,
} from "../dashboardSlice";
import styled from "@emotion/styled";
import { size } from "lodash";
import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";

const Container = styled.div({
  height: 55,
  position: "absolute",
  bottom: -55,
  right: 30,
});

const StyledButton = styled(Button)(
  {
    height: 40,
    padding: "6px 12px",
    borderRadius: 0,
  },
  ({ theme: { fontSize, media } }) => ({
    ...fontSize.sm,
    [media.md]: { ...fontSize.md },
  }),
);

const Icon = styled.i({
  fontSize: 20,
  marginRight: 10,
});

interface MergeButtonProps {
  article: Article;
}

const MergeButton: FC<MergeButtonProps> = ({ article }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const articleItems = useAppSelector(selectArticleItems);
  const show = size(articleItems[article.id]?.order) >= 2;

  const hasResults = useAppSelector((state) =>
    selectHasArticleResults(state, article.id),
  );

  const onCreate = useCallback(async () => {
    if (!article) return;
    if (!hasResults) {
      let canCreate = true;
      try {
        const { isCharactersExceeded } = await dispatch(
          updateCurrentPlan(),
        ).unwrap();
        canCreate = !isCharactersExceeded;
      } catch (error) {}

      if (!canCreate) {
        dispatch(
          billingRestrictionModalChange({
            open: true,
            type: RestrictionTypes.characters,
          }),
        );
        return;
      }
      dispatch(rewriteArticle(article.id));
    }
    dispatch(articleModalChange(article));
  }, [hasResults, dispatch, article]);

  if (!show) return null;

  return (
    <Container>
      <StyledButton onClick={onCreate}>
        <Icon className="icon-create-text" />
        <span>
          {hasResults
            ? t("dashboard.viewMergeButton")
            : t("dashboard.createMergeButton")}
        </span>
      </StyledButton>
    </Container>
  );
};

export default MergeButton;
