import Select from "../../../components/select";
import Tooltip from "../../../components/tooltip";
import { useBilling } from "../../../hooks";
import { useAppSelector } from "../../../store/hooks";
import { selectLanguageOptions } from "../dashboardSlice";
import styled from "@emotion/styled";
import { map, upperCase, toString, toNumber } from "lodash";
import { FC, useCallback, useEffect, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface LanguageSelectProps {
  type: "input" | "output";
  label?: string;
  value: number;
  inputValue?: number;
  onChange: (value: number) => void;
  disabled?: boolean;
}

const Label = styled.label`
  margin-right: 15px;
`;

const Icon = styled.i({ marginLeft: 10 }, ({ theme: { colors } }) => ({
  color: colors.purple,
}));

const LanguageSelect: FC<LanguageSelectProps> = ({
  type,
  label: propLabel,
  value,
  inputValue,
  onChange,
  disabled,
}) => {
  const { t } = useTranslation();
  const options = useAppSelector(selectLanguageOptions);
  const { plan } = useBilling();
  const isFree = plan?.planDetails.type === "free";
  const isRestricted = isFree && type === "output";
  const label =
    propLabel ||
    (type === "input"
      ? t("settings.inputLanguage")
      : t("settings.outputLanguage"));

  const convertedOptions = useMemo(() => {
    return map(options, ({ id, short_name }) => ({
      value: `${id}`,
      label: upperCase(short_name),
    }));
  }, [options]);

  const handleChange = useCallback(
    (value: string) => onChange(toNumber(value)),
    [onChange],
  );

  useEffect(() => {
    if (isRestricted && inputValue && value !== inputValue) {
      onChange(inputValue);
    }
  }, [isRestricted, value, inputValue, onChange]);

  return (
    <div>
      <Label>{label}</Label>
      <Select
        value={toString(value)}
        onChange={handleChange}
        options={convertedOptions}
        ariaLabel={label}
        disabled={disabled || isRestricted}
      />
      {isRestricted && (
        <Tooltip
          content={
            <Trans
              i18nKey="billing.restrictions.notAvailable"
              components={{
                paywallLink: <Link to="/billing/paywall" />,
              }}
            />
          }
        >
          <Icon className="icon-help-circled" />
        </Tooltip>
      )}
    </div>
  );
};

export default LanguageSelect;
