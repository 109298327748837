import AudioPlayer from "../../../components/audio-player";
import Loader from "../../../components/loader";
import { useBilling } from "../../../hooks";
import { useAppDispatch } from "../../../store/hooks";
import { getAuthHeader } from "../../../utils";
import { billingRestrictionModalChange } from "../../billing/billingSlice";
import { ArticleItemType } from "../dashboardSlice";
import styled from "@emotion/styled";
import axios from "axios";
import { first, last, split } from "lodash";
import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: 20,
});

const Panel = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: 15,
});

const AudioPlayerContainer = styled.div({ flexGrow: 1 });

const Download = styled.a(
  {
    flexShrink: 0,
    flexBasis: 50,
    width: 50,
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 20,
    textDecoration: "none",
    borderRadius: "50%",
    cursor: "pointer",
  },
  ({ theme: { colors } }) => ({
    background: colors.white,
    color: colors.purple,
  }),
);

const LoaderContainer = styled.div(
  {
    display: "flex",
    alignItems: "center",
    gap: 15,
    fontWeight: 500,
  },
  ({ theme: { colors, fontSize } }) => ({
    color: colors.purple,
    ...fontSize.md,
  }),
);

const FileName = styled.div(
  {
    fontWeight: 500,
    overflow: "hidden",
  },
  ({ theme: { colors, fontSize } }) => ({
    color: colors.purple,
    ...fontSize.md,
  }),
);

interface AudioPanelProps {
  type: ArticleItemType;
  src: string;
  fileName?: string;
  isLoading?: boolean;
  className?: string;
}

const AudioPanel: FC<AudioPanelProps> = ({
  type,
  src,
  fileName,
  isLoading,
  className,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { plan } = useBilling();
  const canDownload =
    plan?.planDetails.type !== "free" && plan?.planDetails.type !== "standard";

  const onDownload = useCallback(async () => {
    if (!canDownload) {
      dispatch(
        billingRestrictionModalChange({
          open: true,
        }),
      );
      return;
    }
    const response = await axios.get(src, {
      responseType: "blob",
      headers: {
        Authorization: getAuthHeader(),
        "ngrok-skip-browser-warning": "69420",
      },
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(response.data);
    link.setAttribute(
      "download",
      first(split(last(split(src, "/")), "?")) || "",
    );
    link.click();
    URL.revokeObjectURL(response.data);
  }, [src, canDownload, dispatch]);

  if (type !== "audio_file" && type !== "live_audio") return null;

  return (
    <Container className={className}>
      {src && (
        <Panel>
          {type === "live_audio" ? (
            <AudioPlayerContainer>
              <AudioPlayer src={src} />
            </AudioPlayerContainer>
          ) : (
            <FileName className="file-name">{fileName}</FileName>
          )}
          <Download className="download-link" onClick={onDownload}>
            <i className="icon-download" />
          </Download>
        </Panel>
      )}
      {isLoading && (
        <LoaderContainer>
          <Loader />
          {t("dashboard.noteAudioLoading")}
        </LoaderContainer>
      )}
    </Container>
  );
};

export default AudioPanel;
