import { logout } from "../pages/auth/authSlice";
import { store } from "../store";
import { getAuthHeader, isApiUrl } from "../utils";
import axios from "axios";

export const BASE_URL = "/";

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    "ngrok-skip-browser-warning": "69420",
  },
});

api.interceptors.request.use((config) => {
  if (isApiUrl(config.url)) {
    config.headers.Authorization = getAuthHeader();
  }
  return config;
});

api.interceptors.response.use(
  (config) => config,
  (error) => {
    const {
      config: { url },
      response: { status, data },
    } = error;
    if (isApiUrl(url) && status === 403 && data?.detail === "Invalid token.") {
      if (!store.getState().auth.logoutState.loading) store.dispatch(logout());
    }
    throw error;
  },
);

export default api;
