import {
  FC,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import * as RadixTooltip from "@radix-ui/react-tooltip";
import styled from "@emotion/styled";

const TooltipContent = styled(RadixTooltip.Content)(
  {
    maxWidth: 300,
    padding: 10,
    fontSize: 13,
    lineHeight: "17px",
    borderRadius: 5,
    zIndex: 4,
    boxShadow: "0px 4px 15px 0px rgba(190, 190, 190, 0.20)",
    pointerEvents: "auto",
  },
  ({ theme: { colors } }) => ({
    background: colors.white,
    color: colors.black,
  })
);

interface TooltipProps extends PropsWithChildren {
  content: ReactNode;
}

const Tooltip: FC<TooltipProps> = ({ children, content }) => {
  const [open, setOpen] = useState(false);
  const timeoutId = useRef<number>();

  const handleOpen = useCallback(() => {
    window.clearTimeout(timeoutId.current);
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    timeoutId.current = window.setTimeout(() => {
      setOpen(false);
    }, 500);
  }, []);

  useEffect(() => () => window.clearTimeout(timeoutId.current), []);

  return (
    <RadixTooltip.Provider delayDuration={0}>
      <RadixTooltip.Root open={open}>
        <RadixTooltip.Trigger
          asChild
          onMouseEnter={handleOpen}
          onMouseLeave={handleClose}
          onFocus={handleOpen}
          onBlur={handleClose}
        >
          {children}
        </RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <TooltipContent
            sideOffset={5}
            side="bottom"
            onMouseEnter={handleOpen}
            onMouseLeave={handleClose}
          >
            {content}
          </TooltipContent>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};

export default Tooltip;
