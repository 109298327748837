import { FC } from "react";
import { map, toString } from "lodash";
import { Droppable } from "react-beautiful-dnd";
import ArticleItem from "./article-item";
import { Article, selectArticleItems } from "../../dashboardSlice";
import { useAppSelector } from "../../../../store/hooks";
import { DASHBOARD_DND } from "../../constants";
import styled from "@emotion/styled";

interface ArticleItemsListProps {
  article: Article;
  className?: string;
}

const ArticleItemsListContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  paddingBottom: 20,
});

const ArticleItemsList: FC<ArticleItemsListProps> = ({
  article,
  className,
}) => {
  const articleItems = useAppSelector(selectArticleItems);
  const { items, order = [] } = articleItems[article.id] || {};

  return (
    <Droppable
      droppableId={toString(article.id)}
      type={DASHBOARD_DND.types.articleItemsList}
    >
      {(provided) => (
        <ArticleItemsListContainer
          ref={provided.innerRef}
          {...provided.droppableProps}
          className={className}
        >
          {map(order, (id, index) => {
            const item = items[id];
            if (!item) return null;
            return <ArticleItem key={id} articleItem={item} index={index} />;
          })}
          {provided.placeholder}
        </ArticleItemsListContainer>
      )}
    </Droppable>
  );
};

export default ArticleItemsList;
