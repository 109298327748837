import React, { FC } from "react";
import * as RadixCheckbox from "@radix-ui/react-checkbox";
import styled from "@emotion/styled";

const Container = styled.div({ display: "inline-flex" });

const CheckboxRoot = styled(RadixCheckbox.Root)(
  {
    width: 20,
    height: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    borderRadius: 5,
    cursor: "pointer",
  },
  ({ theme: { colors } }) => ({
    backgroundColor: colors.white,
    border: `1px solid ${colors.purple}`,
    "&[data-state='checked']": {
      background: colors.purple,
    },
  })
);

const CheckboxIndicator = styled(RadixCheckbox.Indicator)(
  {},
  ({ theme: { colors } }) => ({
    color: colors.purple,
    "&[data-state='checked']": {
      color: colors.white,
    },
  })
);

const Label = styled.label(
  {
    marginLeft: 10,
  },
  ({ theme: { fontSize } }) => fontSize.md
);

interface CheckboxProps {
  checked: boolean;
  onChange: (value: boolean) => void;
  label?: string;
}

const Checkbox: FC<CheckboxProps> = ({ checked, onChange, label }) => {
  return (
    <Container>
      <CheckboxRoot checked={checked} onCheckedChange={onChange}>
        <CheckboxIndicator>
          <i className="icon-check" />
        </CheckboxIndicator>
      </CheckboxRoot>
      {label && <Label>{label}</Label>}
    </Container>
  );
};

export default Checkbox;
