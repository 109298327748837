import { useCallback, useState } from "react";
import { first } from "lodash";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import Checkbox from "../../components/checkbox";
import Button from "../../components/button";
import FormInput from "./components/form-input";
import Link from "./components/link";
import AuthFormCard from "./components/auth-form-card";
import AuthForm from "./components/auth-form";
import AuthFormHeader from "./components/auth-form-header";
import AuthFlexContainer from "./components/auth-flex-container";
import AuthErrorAlert from "./components/auth-error-alert";
import { closeLoginErrorModal, login, selectLoginState } from "./authSlice";
import styled from "@emotion/styled";

const ForgotPasswordLink = styled(Link)({
  marginLeft: 40,
});

const SignUpLink = styled(Link)({
  marginLeft: 30,
});

interface FormData {
  email: string;
  password: string;
}

const LoginPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { loading: loginLoading, errors } = useAppSelector(selectLoginState);
  const { register, handleSubmit } = useForm<FormData>();
  const [rememberMe, setRememberMe] = useState(true);

  const onSubmit = handleSubmit(async (data) => {
    const { email, password } = data;
    const body = { username: email, email, password };
    await dispatch(login({ body, rememberMe }));
  });

  const onErrorDialogOpenChange = useCallback(
    (open: boolean) => {
      if (!open) dispatch(closeLoginErrorModal());
    },
    [dispatch]
  );

  return (
    <>
      <AuthFormCard>
        <AuthForm onSubmit={onSubmit}>
          <AuthFormHeader>{t("auth.login")}</AuthFormHeader>
          <FormInput
            register={register("email")}
            placeholder={t("auth.emailPlaceholder")}
            error={first(errors?.email) || first(errors?.username)}
            required
          />
          <FormInput
            register={register("password")}
            type="password"
            placeholder={t("auth.passwordPlaceholder")}
            error={first(errors?.password)}
            required
          />
          <AuthFlexContainer>
            <Checkbox
              checked={rememberMe}
              onChange={setRememberMe}
              label={t("auth.rememberMe")}
            />
            <ForgotPasswordLink to="/reset-password">
              {t("auth.forgotPassword")}
            </ForgotPasswordLink>
          </AuthFlexContainer>
          <AuthFlexContainer>
            <Button type="submit" disabled={loginLoading}>
              {t("auth.login")}
            </Button>
            <SignUpLink to="/sign-up">{t("auth.signUp")}</SignUpLink>
          </AuthFlexContainer>
        </AuthForm>
      </AuthFormCard>
      <AuthErrorAlert errors={errors} onOpenChange={onErrorDialogOpenChange} />
    </>
  );
};

export default LoginPage;
