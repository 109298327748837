import Card from "../../../components/card";
import styled from "@emotion/styled";

const AuthFormCard = styled(Card)`
  width: 100%;
  max-width: 356px;
  display: flex;
  align-self: center;
  margin: auto;
  margin-top: 40px;
  padding: 20px;
  ${({ theme: { media } }) =>
    `${media.sm} { max-width: 400px; margin-top: 70px; }`}
  color: ${({ theme: { colors } }) => colors.black};
`;

export default AuthFormCard;
