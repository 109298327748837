import AnimatedButton from "../../../../components/animated-button";
import DeleteConfirm from "../../../../components/delete-confirm";
import Stars from "../../../../components/stars";
import Textarea from "../../../../components/textarea";
import { useAppDispatch } from "../../../../store/hooks";
import { copyText } from "../../../../utils";
import {
  ArticleResult as IArticleResult,
  changeArticleResult,
  deleteArticleResult,
  patchArticleResult,
} from "../../dashboardSlice";
import ResponsiveButton from "../responsive-button";
import ResultInfo from "../result-info";
import styled from "@emotion/styled";
import { debounce } from "lodash";
import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

const Options = styled.div(
  { display: "flex", flexWrap: "wrap", rowGap: 15, columnGap: 10 },
  ({ theme: { media } }) => ({ [media.sm]: { gap: 30 } }),
);

const Mark = styled.div({
  display: "flex",
  flexDirection: "column",
  fontWeight: 500,
});

interface ArticleResultProps {
  articleResult: IArticleResult;
  loading: boolean;
  hideDelete?: boolean;
}

const ArticleResult: FC<ArticleResultProps> = ({
  articleResult,
  loading,
  hideDelete,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const onMarkChange = useCallback(
    (mark: string) => {
      dispatch(patchArticleResult({ articleResult, change: { mark } }));
    },
    [articleResult, dispatch],
  );

  const onSave = useCallback(
    (text: string) => {
      dispatch(patchArticleResult({ articleResult, change: { text } }));
    },
    [articleResult, dispatch],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(debounce(onSave, 1000), []);

  const onTextChange = useCallback(
    (text: string) => {
      dispatch(changeArticleResult({ ...articleResult, text }));
      debouncedSave(text);
    },
    [articleResult, debouncedSave, dispatch],
  );

  const onCopy = useCallback(() => {
    copyText(articleResult.text);
  }, [articleResult.text]);

  const onDeleteClick = useCallback(() => {
    setDeleteConfirmOpen(true);
  }, []);

  const onDelete = useCallback(() => {
    dispatch(deleteArticleResult(articleResult));
  }, [articleResult, dispatch]);

  return (
    <>
      <ResultInfo
        date={articleResult.created}
        text={articleResult.text}
        styleName={articleResult.style}
      />
      <Textarea
        value={articleResult.text}
        onChange={onTextChange}
        initMaxHeight={429}
        resize
      />
      <Options>
        <AnimatedButton
          variant="outline"
          onClick={onCopy}
          disabled={loading}
          iconClassName="icon-copy"
          text={t("copy")}
        />
        {!hideDelete && (
          <ResponsiveButton
            variant="outline"
            onClick={onDeleteClick}
            disabled={loading}
          >
            <i className="icon-trash" />
            {t("delete")}
          </ResponsiveButton>
        )}
        <Mark>
          {t("dashboard.evaluateQuality")}
          <Stars
            value={articleResult.mark}
            onChange={onMarkChange}
            disabled={loading}
          />
        </Mark>
      </Options>
      <DeleteConfirm
        open={deleteConfirmOpen}
        onOpenChange={setDeleteConfirmOpen}
        onAccept={onDelete}
      />
    </>
  );
};

export default ArticleResult;
