import AppBar from "../components/app-bar";
import { useAuth } from "../hooks";
import { isMobile } from "../utils";
import styled from "@emotion/styled";
import { Outlet } from "react-router-dom";

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: ${({ theme: { colors } }) => colors.white2};
  overflow: ${isMobile ? "auto" : "visible"};
`;

const Content = styled.div({
  flex: 1,
  overflow: isMobile ? "visible" : "auto",
});

const Layout = () => {
  const tokenChecked = useAuth();

  // useAddSocialproofy();

  return (
    <Container>
      <AppBar />
      <Content>{tokenChecked && <Outlet />}</Content>
    </Container>
  );
};

export default Layout;
