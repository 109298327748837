import { FC, ReactNode } from "react";
import * as ReactPopover from "@radix-ui/react-popover";
import CloseButton from "./close-button";
import { fadeIn } from "../styles/animations";
import styled from "@emotion/styled";

interface ReactPopoverProps extends ReactPopover.PopoverProps {
  content: ReactNode;
  contentProps?: ReactPopover.PopperContentProps;
  showClose?: boolean;
}

const ReactPopoverContent = styled.div({
  position: "relative",
  animationDuration: "500ms",
  animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
  willChange: "opacity",
  zIndex: 2,
  '&[data-state="open"]': { animationName: fadeIn },
  ":focus-visible": {
    outline: "none",
  },
});

const Popover: FC<ReactPopoverProps> = ({
  children,
  content,
  contentProps = {},
  showClose = true,
  ...props
}) => (
  <ReactPopover.Root {...props}>
    <ReactPopover.Trigger asChild>{children}</ReactPopover.Trigger>
    <ReactPopover.Portal>
      <ReactPopover.Content asChild {...contentProps}>
        <ReactPopoverContent>
          {content}
          {showClose && (
            <ReactPopover.Close aria-label="Close" asChild>
              <CloseButton />
            </ReactPopover.Close>
          )}
        </ReactPopoverContent>
      </ReactPopover.Content>
    </ReactPopover.Portal>
  </ReactPopover.Root>
);

export default Popover;
