import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { first } from "lodash";
import Breadcrumbs from "../../components/breadcrumbs";
import Card from "../../components/card";
import Loader from "../../components/loader";
import Button from "../../components/button";
import FormInput from "../auth/components/form-input";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { changePassword, selectChangePasswordState } from "../auth/authSlice";
import { useUser } from "../../hooks";
import styled from "@emotion/styled";

const Container = styled.div(
  {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    gap: 20,
    padding: "30px 15px",
  },
  ({ theme: { colors, media } }) => ({
    color: colors.black,
    [media.md]: { padding: "30px 40px" },
  })
);

const SettingsCard = styled(Card)(
  { width: 345, padding: 15 },
  ({ theme: { media } }) => ({
    [media.md]: {
      width: 547,
      padding: 20,
    },
  })
);

const Form = styled.form({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 20,
});

const FormHeader = styled.h1({
  fontSize: 18,
  fontWeight: 500,
  lineHeight: "23px",
});

const FormItem = styled.div({ display: "flex" });

const AuthFormItem = styled.div(
  {
    display: "flex",
    flexDirection: "column",
  },
  ({ theme: { media } }) => ({
    [media.md]: {
      flexDirection: "row",
    },
  })
);

const Label = styled.label<{ required?: boolean }>(
  {
    minWidth: 62,
  },
  ({ theme: { colors, media }, required }) => ({
    ":after": {
      content: required ? "' *'" : "''",
      color: colors.red,
    },
    [media.md]: {
      minWidth: 187,
    },
  })
);

const AuthLabel = styled(Label)(
  {
    display: "flex",
    alignItems: "center",
  },
  ({ theme: { media } }) => ({ [media.md]: { height: 50 } })
);

const Footer = styled.div({
  display: "flex",
  alignItems: "center",
  gap: 20,
  marginTop: 5,
});

const Icon = styled.i({ marginRight: 10 });

interface FormData {
  new_password1: string;
  new_password2: string;
  old_password: string;
}

const UserSettingsPage = () => {
  const { t } = useTranslation();
  const { loading, errors } = useAppSelector(selectChangePasswordState);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { register, handleSubmit, reset } = useForm<FormData>();

  const { user } = useUser();

  const onSubmit = handleSubmit(async (data) => {
    try {
      await dispatch(changePassword(data)).unwrap();
      reset();
    } catch (error) {}
  });

  const onCancel = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const breadcrubmsItems = useMemo(
    () => [{ title: t("user-settings.breadcrubms"), url: "/" }],
    [t]
  );

  return (
    <Container>
      <Breadcrumbs arrow items={breadcrubmsItems} />
      <SettingsCard>
        <Form onSubmit={onSubmit}>
          <FormHeader>{t("user-settings.header")}</FormHeader>
          <FormItem>
            <Label>{t("user-settings.email")}</Label>
            {user.username}
          </FormItem>
          <AuthFormItem>
            <AuthLabel required>{t("user-settings.newPassword")}</AuthLabel>
            <FormInput
              type="password"
              register={register("new_password1")}
              placeholder={t("user-settings.newPassword")}
              error={
                first(errors?.new_password1) || first(errors?.new_password1)
              }
            />
          </AuthFormItem>
          <AuthFormItem>
            <AuthLabel required>
              {t("user-settings.newPasswordAgain")}
            </AuthLabel>
            <FormInput
              type="password"
              register={register("new_password2")}
              placeholder={t("user-settings.newPasswordAgain")}
              error={
                first(errors?.new_password2) || first(errors?.new_password2)
              }
            />
          </AuthFormItem>
          <AuthFormItem>
            <AuthLabel required>{t("user-settings.oldPassword")}</AuthLabel>
            <FormInput
              type="password"
              register={register("old_password")}
              placeholder={t("user-settings.oldPassword")}
              error={first(errors?.old_password) || first(errors?.old_password)}
            />
          </AuthFormItem>
          <Footer>
            <Button disabled={loading}>
              <Icon className="icon-file" />
              {t("save")}
            </Button>
            <Button variant="outline" onClick={onCancel}>
              <Icon className="icon-close" />
              {t("cancel")}
            </Button>
            {loading && <Loader />}
          </Footer>
        </Form>
      </SettingsCard>
    </Container>
  );
};

export default UserSettingsPage;
