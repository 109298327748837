import authReducer from "../pages/auth/authSlice";
import billingReducer from "../pages/billing/billingSlice";
import dashboardReducer from "../pages/root/dashboardSlice";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
// @ts-ignore
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "dashboard",
  storage,
  whitelist: ["addingItemForm"],
};

const reducer = combineReducers({
  dashboard: persistReducer(persistConfig, dashboardReducer),
  auth: authReducer,
  billing: billingReducer,
});

export const store = configureStore({
  reducer,
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
