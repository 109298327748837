import { formatNumber } from "../../../utils";
import { BillingHistoryItem, Plan } from "../billingSlice";
import { format } from "date-fns";
import { keyBy, map, toLower } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

type BillingDetailTableCols =
  | "plan"
  | "from"
  | "to"
  | "characters"
  | "totalLiveAudio"
  | "totalUploadingAudio";

interface BillingDetailTableCol {
  id: BillingDetailTableCols;
  title: string;
}

type BillingDetailTableColsValues = {
  [key in BillingDetailTableCols]: string;
};

interface BillingDetailTableRow extends BillingDetailTableColsValues {
  id: number;
}

export const useBilllingDetailsTable = (
  planList: Plan[],
  history: BillingHistoryItem[],
) => {
  const { t } = useTranslation();

  const cols = useMemo<Array<BillingDetailTableCol>>(
    () => [
      { id: "plan", title: t("billing.table.plan") },
      { id: "from", title: t("billing.table.from") },
      { id: "to", title: t("billing.table.to") },
      { id: "characters", title: t("billing.table.characters") },
      { id: "totalLiveAudio", title: t("billing.table.totalLiveAudio") },
      {
        id: "totalUploadingAudio",
        title: t("billing.table.totalUploadingAudio"),
      },
    ],
    [t],
  );

  const plans = useMemo(() => keyBy(planList, "id"), [planList]);

  const rows = useMemo(
    () =>
      map(
        history,
        ({
          id,
          plan_id,
          total_live_audio_min,
          total_uploading_audio_min,
          total_characters,
          date_from,
          date_to,
        }) => {
          const planInfo = plans[plan_id];
          if (!planInfo) return null;
          const row: BillingDetailTableRow = {
            id,
            plan: planInfo.name,
            from: toLower(format(date_from, "d MMMM yyyy")),
            to: toLower(format(date_to, "d MMMM yyyy")),
            characters: `${formatNumber(total_characters)} / ${formatNumber(
              planInfo.limit_characters,
            )}`,
            totalLiveAudio: `${formatNumber(
              total_live_audio_min,
            )} / ${formatNumber(planInfo.limit_live_audio_min)}`,
            totalUploadingAudio: `${formatNumber(
              total_uploading_audio_min,
            )} / ${formatNumber(planInfo.limit_uploading_audio_min)}`,
          };
          return row;
        },
      ),
    [history, plans],
  );

  return { cols, rows };
};
