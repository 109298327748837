import { FC } from "react";
import TextLogo from "./text-logo";
import User from "./user";
import { isMobile } from "../utils";
import styled from "@emotion/styled";

const Container = styled.div`
  flex-basis: 76px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: ${isMobile ? "sticky" : "static"};
  left: 0;
  padding: 0 40px;
  background: ${({ theme: { colors } }) => colors.white};
  box-shadow: 0px 4px 15px 0px rgba(190, 190, 190, 0.2);
`;

const AppBar: FC = () => {
  return (
    <Container>
      <TextLogo />
      <User />
    </Container>
  );
};

export default AppBar;
