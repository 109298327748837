import { useCallback, FC, useRef } from "react";
import { RefCallBack, UseFormRegisterReturn } from "react-hook-form";
import { StyledInput } from "../../../components/input";
import ValidationText from "../../../components/validation-text";
import styled from "@emotion/styled";

interface PlaceholderProps {
  error?: string;
  required?: boolean;
}

const InputContainer = styled.div({
  width: "100%",
  position: "relative",
  display: "flex",
  flexDirection: "column",
});

const StyledFormInput = styled(StyledInput)({
  flexGrow: 1,
  height: 50,
  ":focus ~ div": {
    display: "none",
  },
  ":not(:placeholder-shown) ~ div": {
    display: "none",
  },
});

const Placeholder = styled.div<PlaceholderProps>(
  {
    position: "absolute",
    left: 15,
    transform: "translateY(-50%)",
    cursor: "text",
  },
  ({ theme: { colors, fontSize }, error, required }) => ({
    top: 26,
    color: error ? colors.red : colors.grey,
    "::after": {
      content: required ? "' *'" : "none",
      color: colors.red,
    },
    lineHeight: fontSize.md.lineHeight,
  })
);

interface FormInputProps extends PlaceholderProps {
  type?: string;
  register: UseFormRegisterReturn<string>;
  placeholder?: string;
  className?: string;
}

const FormInput: FC<FormInputProps> = ({
  type,
  register,
  placeholder,
  error,
  required,
  className,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { ref, ...rest } = register;

  const onPlaceholderClick = useCallback(
    () => inputRef?.current?.focus(),
    [inputRef]
  );

  const setRef: RefCallBack = useCallback(
    (element) => {
      ref(element);
      inputRef.current = element;
    },
    [ref]
  );

  return (
    <InputContainer>
      <StyledFormInput
        ref={setRef}
        type={type}
        className={className}
        error={!!error}
        placeholder=" "
        {...rest}
      />
      {error && <ValidationText>{error}</ValidationText>}
      {placeholder && (
        <Placeholder
          onClick={onPlaceholderClick}
          required={required}
          error={error}
        >
          {placeholder}
        </Placeholder>
      )}
    </InputContainer>
  );
};

export default FormInput;
