import Button from "../../../../components/button";
import Loader from "../../../../components/loader";
import Textarea from "../../../../components/textarea";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  Article,
  ArticleItemType,
  addArticleItem,
  selectAddArticleItemState,
  setAddingItemForm,
} from "../../dashboardSlice";
import { useScrollIntoView } from "../../hooks";
import styled from "@emotion/styled";
import { FC, useCallback, useState, KeyboardEvent } from "react";
import { useTranslation } from "react-i18next";

interface AddNoteFormProps {
  article: Article;
  itemType: ArticleItemType;
  onResetItemType: () => void;
}

const AddNoteFormContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: 20,
  alignItems: "start",
});

export const ArticleItemTextArea = styled(Textarea)<{ isSubtitle: boolean }>(
  {},
  ({
    isSubtitle,
    theme: {
      fontSize: { md, lg },
    },
  }) => ({
    fontSize: isSubtitle ? lg.fontSize : md.fontSize,
    lineHeight: isSubtitle ? lg.lineHeight : md.lineHeight,
  }),
);

const Footer = styled.div({ display: "flex", alignItems: "center" });

const AddButton = styled(Button)({
  marginRight: 30,
});

const CancelIcon = styled.i({}, ({ theme: { colors } }) => ({
  fontSize: 15,
  color: colors.purple,
  cursor: "pointer",
}));

const AddTextNoteForm: FC<AddNoteFormProps> = ({
  article,
  itemType,
  onResetItemType,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { loading } =
    useAppSelector((state) => selectAddArticleItemState(state, article.id)) ||
    {};
  const [input_text, setInputText] = useState("");
  const isSubtitle = itemType === "subtitle";
  const ref = useScrollIntoView<HTMLDivElement>();

  const onAdd = useCallback(async () => {
    if (loading) return;
    dispatch(setAddingItemForm({ article_id: article.id, input_text }));
    await dispatch(
      addArticleItem({
        articleItem: {
          article_id: article.id,
          type: itemType,
          input_text,
        },
      }),
    );
  }, [article.id, itemType, input_text, loading, dispatch]);

  const onKeyDown = useCallback(
    (event: KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === "Enter" && !event.shiftKey) {
        onAdd();
      }
    },
    [onAdd],
  );

  return (
    <AddNoteFormContainer ref={ref}>
      <ArticleItemTextArea
        value={input_text}
        onChange={setInputText}
        onKeyDown={onKeyDown}
        placeholder={
          isSubtitle
            ? t("dashboard.subtitleNotePlaceholder")
            : t("dashboard.textNoteTitlePlaceholder")
        }
        isSubtitle={isSubtitle}
        maxHeight={303}
        autoFocus
      />
      <Footer>
        <AddButton disabled={!input_text || loading} onClick={onAdd}>
          {t("dashboard.addNoteButton")}
        </AddButton>
        {loading ? (
          <Loader />
        ) : (
          <CancelIcon className="icon-close" onClick={onResetItemType} />
        )}
      </Footer>
    </AddNoteFormContainer>
  );
};

export default AddTextNoteForm;
