import Tooltip from "../../../components/tooltip";
import { useAppSelector } from "../../../store/hooks";
import { selectTextStyles } from "../dashboardSlice";
import styled from "@emotion/styled";
import { format } from "date-fns";
import { size, toLower } from "lodash";
import { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

const Container = styled.div(
  {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    gap: 15,
  },
  ({
    theme: {
      media: { sm },
    },
  }) => ({ [sm]: { gap: 20 } }),
);

const Item = styled.div(
  { display: "flex", gap: 6, fontWeight: 500 },
  ({
    theme: {
      fontSize,
      colors,
      media: { md },
    },
  }) => ({
    ...fontSize.sm,
    i: { color: colors.lightblue3 },
    [md]: {
      ...fontSize.md,
    },
  }),
);

const BarsIcon = styled.i({ marginRight: 6 });

interface ResultInfoProps extends PropsWithChildren {
  date?: string;
  style?: number;
  styleName?: string;
  text?: string;
}

const ResultInfo: FC<ResultInfoProps> = ({
  date,
  style,
  styleName,
  text,
  children,
}) => {
  const { t } = useTranslation();

  const styles = useAppSelector(selectTextStyles);

  const styleNameDisplay = styleName || (style && styles[style]?.style_name);

  return (
    <Container>
      {children}
      {date && (
        <Item>
          <i className="icon-clock" />
          {toLower(format(date, "dd MMMM yy"))}
        </Item>
      )}
      {text && (
        <Tooltip
          content={
            <>
              {t("dashboard.charactersCountNoSpaces")} /{" "}
              {t("dashboard.charactersCountWithSpaces")}
            </>
          }
        >
          <Item>
            <BarsIcon className="icon-bars" />
            {size(text.replaceAll(" ", ""))} / {size(text)}
          </Item>
        </Tooltip>
      )}
      {styleNameDisplay && (
        <Item>
          <i className="icon-diamond" />
          {styleNameDisplay}
        </Item>
      )}
    </Container>
  );
};

export default ResultInfo;
