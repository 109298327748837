import { Trans, useTranslation } from "react-i18next";
import Alert from "../../../components/alert";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  RestrictionTypes,
  billingRestrictionModalChange,
  selectBillingRestrictionModal,
} from "../billingSlice";
import { useCallback } from "react";
import { Link } from "react-router-dom";

const RestrictionModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const errorModal = useAppSelector(selectBillingRestrictionModal);

  const onErrorModalOpenChange = useCallback(
    (open: boolean) => {
      dispatch(billingRestrictionModalChange({ open }));
    },
    [dispatch]
  );

  const { type } = errorModal;

  const message = (
    <Trans
      i18nKey={
        type === RestrictionTypes.addText
          ? "billing.restrictions.addtext"
          : type === RestrictionTypes.addArticle
          ? "billing.restrictions.addArticle"
          : type === RestrictionTypes.characters
          ? "billing.restrictions.characters"
          : type === RestrictionTypes.audioLength
          ? "billing.restrictions.audioLength"
          : "billing.restrictions.notAvailable"
      }
      components={{
        paywallLink: (
          <Link
            to="/billing/paywall"
            onClick={() => onErrorModalOpenChange(false)}
          />
        ),
      }}
    />
  );

  return (
    <Alert
      title={t("information")}
      titleIcon="icon-info"
      message={message}
      open={errorModal.open}
      onOpenChange={onErrorModalOpenChange}
      buttons={[{ text: t("ok") }]}
      hideIconWrapper
    />
  );
};

export default RestrictionModal;
