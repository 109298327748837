import api from "../../lib/api";
import { RootState } from "../../store";
import { dataURLtoFile, defaultMimeType } from "../../utils";
import { isArticleItemError } from "./utils";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import {
  each,
  filter,
  find,
  findIndex,
  join,
  keyBy,
  last,
  map,
  omit,
  reduce,
  size,
  split,
  toString,
} from "lodash";

type ArticleStatusType = "new" | "processing" | "ready" | "error";
export type ArticleItemStatusType =
  | ArticleStatusType
  | "parsing"
  | "parsing_error"
  | "parsed"
  | "billing_error"
  | "process_error";

interface ArticleRequestParams {
  name: string;
  input_language: number;
  output_language: number;
  style: number;
}

export interface Article extends ArticleRequestParams {
  status?: ArticleStatusType;
  error_text?: string;
  id: number;
}

export const ARTICLE_ITEM_TYPES = [
  "text",
  "subtitle",
  "audio_file",
  "live_audio",
] as const;
export type ArticleItemType = (typeof ARTICLE_ITEM_TYPES)[number];

interface ArticleItemRequestParams {
  article_id: number;
  input_language?: number;
  output_language?: number;
  style?: number;
  type: ArticleItemType;
  input_text?: string;
  file_url?: string;
  input_audio_name?: string;
}

export interface ArticleItemResult {
  id: number;
  favorite?: boolean;
  text?: string;
  created?: string;
}
export interface ArticleItem extends ArticleItemRequestParams {
  id: number;
  status: ArticleItemStatusType;
  output_text?: string;
  visible_text?: string;
  favorite?: boolean;
  article_item_results: ArticleItemResult[];
  error_text?: string;
}

interface ArticleItems {
  [article_id: number]: {
    items: { [id: number]: ArticleItem };
    order: number[];
  };
}

export interface ArticleResult {
  id: number;
  article_id: number;
  mark: string;
  text: string;
  style?: string;
  created?: string;
}

export interface StyleOption {
  id: number;
  language: number;
  language_name: string;
  style_name: string;
  style_id?: number;
  type: "custom" | "system";
}

export interface LanguageOption {
  id: number;
  name: string;
  osi_name: string;
  short_name: string;
}

interface RequestState {
  loading?: boolean;
  error?: string;
}

type ResponseError = Record<string, string>;

interface AddingItemForm {
  id?: number;
  itemType?: ArticleItemType;
  input_text?: string;
  url?: string;
  fileName?: string;
  retry?: boolean;
  error?: string;
}

interface AddArticleForm {
  input_language: number;
  output_language: number;
  style: number;
  show?: boolean;
}

export interface CustomStyleParams {
  name: string;
  wording_to_openai: string;
  language: number;
}

interface CustomStyleModal extends Partial<CustomStyleParams> {
  open: boolean;
  id?: number;
  articleId?: number;
}

interface DashboardState {
  articles: { [id: number]: Article };
  articlesOrder: number[];
  articleItems: ArticleItems;
  articleResults: { [article_id: number]: ArticleResult[] };
  styleOptions: Array<StyleOption>;
  styles: Record<number, StyleOption>;
  languageOptions: Array<LanguageOption>;
  getArticlesState: RequestState;
  addArticleState: RequestState;
  getStyleOptionsState: RequestState;
  getLanguageOptionsState: RequestState;
  getArticleItemsState: RequestState;
  addArticleItemState: { [article_id: number]: RequestState };
  addingItemForm: {
    [article_id: number]: AddingItemForm;
  };
  addArticleForm: AddArticleForm;
  rewriteArticleItemState: { [id: number]: boolean };
  rewriteArticleState: { [article_id: number]: boolean };
  getArticleResultsState: RequestState;
  errorModal: { open: boolean; message?: string; isInfo?: boolean };
  articleItemModal: ArticleItem | null;
  articleModal: Article | null;
  customStyleModal: CustomStyleModal;
}

const DEFAULT_ADD_ARTICLE_FORM: AddArticleForm = {
  input_language: 1,
  output_language: 1,
  style: 2,
};

const initialState: DashboardState = {
  articles: {},
  articlesOrder: [],
  articleItems: {},
  articleResults: {},
  styleOptions: [],
  styles: {},
  languageOptions: [],
  getArticlesState: {},
  addArticleState: {},
  getStyleOptionsState: {},
  getLanguageOptionsState: {},
  getArticleItemsState: {},
  addArticleItemState: {},
  addingItemForm: {},
  addArticleForm: DEFAULT_ADD_ARTICLE_FORM,
  rewriteArticleItemState: {},
  rewriteArticleState: {},
  getArticleResultsState: {},
  errorModal: { open: false },
  articleItemModal: null,
  articleModal: null,
  customStyleModal: { open: false },
};

export const getArticles = createAsyncThunk(
  "dashboard/getArticles",
  async (_, { dispatch }) => {
    const response = await api.get<Article[]>("api/article/");
    dispatch(getArticleItems(response.data));
    dispatch(getArticleResults(response.data));
    return response.data;
  },
);

export const addArticle = createAsyncThunk<
  Article,
  ArticleRequestParams,
  { state: RootState }
>("dashboard/addArticle", async (article, { getState, dispatch }) => {
  const response = await api.post<ArticleRequestParams, AxiosResponse<number>>(
    "api/article/",
    article,
  );
  const id = response.data;
  const {
    dashboard: { articlesOrder },
  } = getState();
  const order = [...articlesOrder, id];
  dispatch(
    changeArticlesOrder({
      order,
      origOrder: order,
    }),
  );
  return { ...article, id };
});

export const patchArticle = createAsyncThunk<
  any,
  { article: Article; change: Partial<Article> }
>("dashboard/patchArticle", async ({ article, change }) => {
  const response = await api.patch(`api/article/${article.id}/`, {
    ...article,
    ...change,
  });
  return response.data;
});

export const deleteArticle = createAsyncThunk<any, Article>(
  "dashboard/deleteArticle",
  async (article) => {
    const response = await api.delete(`api/article/${article.id}/`);
    return response.data;
  },
);

export const changeArticlesOrder = createAsyncThunk<
  any,
  { order: number[]; origOrder: number[] }
>("dashboard/changeArticlesOrder", async ({ order }) => {
  const response = await api.patch(`api/article/sort/`, { order: join(order) });
  return response.data;
});

export const getStyleOptions = createAsyncThunk(
  "dashboard/getStyleOptions",
  async () => {
    const response = await api.get<StyleOption[]>("api/settings/");
    return response.data;
  },
);

export const getOneStyleOption = createAsyncThunk(
  "dashboard/getOneStyleOption",
  async (id: number) => {
    const response = await api.get<StyleOption & Partial<CustomStyleParams>>(
      `api/settings/${id}/`,
    );
    return response.data;
  },
);

export const patchStyleOptions = createAsyncThunk<
  StyleOption,
  CustomStyleParams & { id: number; articleId?: number },
  { state: RootState }
>("dashboard/patchStyleOptions", async (params, { getState, dispatch }) => {
  if (params.articleId) {
    const {
      dashboard: { articles },
    } = getState();
    const article = articles[params.articleId];
    dispatch(
      patchArticle({
        article,
        change: {
          style: params.id,
          input_language: params.language,
          output_language: params.language,
        },
      }),
    );
  }
  const response = await api.patch(`api/settings/${params.id}/`, {
    ...omit(params, ["id", "articleId"]),
  });
  return response.data;
});

export const postStyleOptions = createAsyncThunk<
  StyleOption,
  CustomStyleParams & { articleId?: number },
  { state: RootState }
>("dashboard/postStyleOptions", async (params, { getState, dispatch }) => {
  const response = await api.post<StyleOption>("api/settings/", {
    ...params,
  });

  const id = response?.data?.style_id || response?.data?.id;
  if (params.articleId && id) {
    const {
      dashboard: { articles },
    } = getState();
    const article = articles[params.articleId];
    dispatch(
      patchArticle({
        article,
        change: {
          style: id,
          input_language: params.language,
          output_language: params.language,
        },
      }),
    );
  }

  return response.data;
});

export const deleteStyleOptions = createAsyncThunk(
  "dashboard/deleteStyleOptions",
  async (id: number) => {
    const response = await api.delete(`api/settings/${id}/`);
    return response.data;
  },
);

export const getLanguageOptions = createAsyncThunk(
  "dashboard/getLanguageOptions",
  async () => {
    const response = await api.get<LanguageOption[]>("api/language/");
    return response.data;
  },
);

export const getArticleItems = createAsyncThunk(
  "dashboard/getArticleItems",
  async (articles: Article[]) => {
    const promises: Promise<AxiosResponse<ArticleItem[]>>[] = [];
    each(articles, ({ id }) => {
      const request = api.get(`api/article_item/article_id=${id}/`);
      promises.push(request);
    });
    const response = await Promise.all(promises);
    return map(response, ({ data }, index) => ({
      data,
      article_id: articles[index].id,
    }));
  },
);

export const getArticleItem = createAsyncThunk(
  "dashboard/getArticleItem",
  async (id: number) => {
    const response = await api.get<ArticleItem>(`api/article_item/${id}/`);
    return response.data;
  },
);

export const addArticleItem = createAsyncThunk<
  ArticleItem,
  { articleItem: ArticleItemRequestParams; url?: string },
  { state: RootState; rejectValue: ResponseError }
>(
  "dashboard/addArticleItem",
  async ({ articleItem, url }, { dispatch, getState, rejectWithValue }) => {
    let response;

    const postArticleItem = async () => {
      if (
        articleItem.type === "live_audio" ||
        articleItem.type === "audio_file"
      ) {
        if (!url) throw new Error("No file provided");
        const file = dataURLtoFile(
          url,
          articleItem.input_audio_name ||
            `${Date.now()}.${last(split(defaultMimeType, "/"))}`,
        );
        const formData = new FormData();
        formData.append("article_id", toString(articleItem.article_id));
        formData.append("type", articleItem.type);
        formData.append("file_url", file);
        if (articleItem.input_audio_name) {
          formData.append("input_audio_name", file.name);
        }
        return await api.post<ArticleItem>("api/article_item/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      } else {
        return await api.post<ArticleItem>("api/article_item/", articleItem);
      }
    };

    try {
      response = await postArticleItem();
      const { error_text = "", id, status } = response?.data || {};
      if (!id) {
        dispatch(
          setAddingItemForm({
            article_id: articleItem.article_id,
            retry: true,
            error: error_text,
          }),
        );
        return rejectWithValue({ error_text, status });
      }
    } catch (error) {
      const { response } = (error as AxiosError<ResponseError>) || {};
      dispatch(
        setAddingItemForm({
          article_id: articleItem.article_id,
          retry: true,
          error: response?.data?.error_text,
        }),
      );
      if (!response) throw error;
      return rejectWithValue(response.data);
    }

    const { id } = response.data;

    const {
      dashboard: { articleItems },
    } = getState();

    const order = [...articleItems[articleItem.article_id].order, id];
    dispatch(
      changeArticleItemsOrder({
        article_id: articleItem.article_id,
        order,
        origOrder: order,
      }),
    );

    dispatch(
      resetAddingItemForm({
        article_id: articleItem.article_id,
      }),
    );

    return response.data;
  },
);

export const retryArticleItem = createAsyncThunk<ArticleItem, { id: number }>(
  "dashboard/retryArticleItem",
  async ({ id }) => {
    const response = await api.get(`api/check_article_item/${id}/`);
    return response.data;
  },
);

export const retryArticleItemResult = createAsyncThunk<
  ArticleItem,
  { id: number; isAudio?: boolean }
>("dashboard/retryArticleItemResult", async ({ id, isAudio }) => {
  const response = await api.get(
    `api/${isAudio ? "audio-task" : "text-task"}/${id}/`,
  );
  return response.data;
});

export const patchArticleItem = createAsyncThunk<
  ArticleItem,
  { articleItem: ArticleItem; change: Partial<ArticleItem> }
>("dashboard/patchArticleItem", async ({ articleItem, change }) => {
  const response = await api.patch(`api/article_item/${articleItem.id}/`, {
    ...articleItem,
    ...change,
  });
  return response.data;
});

export const deleteArticleItem = createAsyncThunk<
  any,
  ArticleItem,
  { state: RootState }
>(
  "dashboard/deleteArticleItem",
  async (articleItem: ArticleItem, { dispatch, getState }) => {
    const response = await api.delete(`api/article_item/${articleItem.id}/`);
    const {
      dashboard: { articleItems },
    } = getState();
    const order = [...articleItems[articleItem.article_id].order];
    const index = findIndex(order, (itemId) => itemId === articleItem.id);
    order.splice(index, 1);
    dispatch(
      changeArticleItemsOrder({
        article_id: articleItem.article_id,
        order,
        origOrder: order,
      }),
    );
    return response.data;
  },
);

export const changeArticleItemsOrder = createAsyncThunk<
  any,
  { article_id: number; order: number[]; origOrder: number[] }
>("dashboard/changeArticleItemsOrder", async ({ article_id, order }) => {
  const response = await api.patch(
    `api/article_item/sort/article_id=${article_id}/`,
    { items_ordering: join(order) },
  );
  return response.data;
});

export const moveArticleItem = createAsyncThunk<
  any,
  {
    id: number;
    source_article_id: number;
    destination_article_id: number;
    sourceOrder: number[];
    sourceOrigOrder: number[];
    destinationOrder: number[];
    destinationOrigOrder: number[];
  },
  { state: RootState }
>(
  "dashboard/moveArticleItem",
  async (
    {
      id,
      source_article_id,
      destination_article_id,
      sourceOrder,
      sourceOrigOrder,
      destinationOrder,
      destinationOrigOrder,
    },
    { dispatch },
  ) => {
    const response = await api.patch(
      `api/article_item/${id}/move/${destination_article_id}/`,
    );
    dispatch(
      changeArticleItemsOrder({
        article_id: source_article_id,
        order: sourceOrder,
        origOrder: sourceOrigOrder,
      }),
    );
    dispatch(
      changeArticleItemsOrder({
        article_id: destination_article_id,
        order: destinationOrder,
        origOrder: destinationOrigOrder,
      }),
    );
    return response.data;
  },
);

export const rewriteArticleItem = createAsyncThunk<
  ArticleItem,
  { articleItem: ArticleItem; change: Partial<ArticleItem> },
  { rejectValue: ResponseError }
>(
  "dashboard/rewriteArticleItem",
  async ({ articleItem, change }, { dispatch, rejectWithValue }) => {
    try {
      await dispatch(patchArticleItem({ articleItem, change })).unwrap();
      const response = await api.post(
        `api/article_item/${articleItem.id}/process/`,
      );
      return response.data;
    } catch (error) {
      const { response } = error as AxiosError<ResponseError>;
      if (!response) throw error;
      return rejectWithValue(response.data);
    }
  },
);

export const patchArticleItemResult = createAsyncThunk<
  ArticleItemResult,
  {
    articleId: number;
    articleItemId: number;
    id: number;
    change: Partial<ArticleItemResult>;
  }
>("dashboard/patchArticleItemResult", async ({ articleItemId, id, change }) => {
  const response = await api.patch(
    `api/article_item_result/${id}/?article_item=${articleItemId}`,
    change,
  );
  return response.data;
});

export const deleteArticleItemResult = createAsyncThunk<
  any,
  {
    articleId: number;
    articleItemId: number;
    id: number;
  }
>("dashboard/deleteArticleItemResult", async ({ articleItemId, id }) => {
  const response = await api.delete(
    `api/article_item_result/${id}/?article_item=${articleItemId}`,
  );
  return response.data;
});

export const resetArticleItemResultFavorite = createAsyncThunk<
  any,
  {
    articleId: number;
    articleItemId: number;
  }
>("dashboard/resetArticleItemResultFavorite", async ({ articleItemId }) => {
  const response = await api.get(
    `api/article_item_result/reset-favorites/?article_item=${articleItemId}`,
  );
  return response.data;
});

export const getArticleResults = createAsyncThunk(
  "dashboard/getArticleResults",
  async (articles: Article[]) => {
    const promises: Promise<AxiosResponse<ArticleResult[]>>[] = [];
    each(articles, ({ id }) => {
      const request = api.get(`api/article_result/article_id=${id}/`);
      promises.push(request);
    });
    const response = await Promise.all(promises);
    return map(response, ({ data }, index) => ({
      data,
      article_id: articles[index].id,
    }));
  },
);

export const rewriteArticle = createAsyncThunk<
  ArticleResult,
  number,
  { rejectValue: ResponseError }
>(
  "dashboard/rewriteArticle",
  async (article_id: number, { rejectWithValue }) => {
    try {
      const response = await api.post<ArticleResult>(
        `api/article/${article_id}/process/`,
      );
      return response.data;
    } catch (error) {
      const { response } = error as AxiosError<ResponseError>;
      if (!response) throw error;
      return rejectWithValue(response.data);
    }
  },
);

export const patchArticleResult = createAsyncThunk<
  any,
  { articleResult: ArticleResult; change: Partial<ArticleResult> }
>("dashboard/patchArticleResult", async ({ articleResult, change }) => {
  const response = await api.patch(`api/article_result/${articleResult.id}/`, {
    ...articleResult,
    ...change,
  });
  return response.data;
});

export const deleteArticleResult = createAsyncThunk(
  "dashboard/deleteArticleResult",
  async (articleResult: ArticleResult) => {
    const response = await api.delete(
      `api/article_result/${articleResult.id}/`,
    );
    return response.data;
  },
);

export const rewriteArticleResult = createAsyncThunk(
  "dashboard/rewriteArticleResult",
  async (articleResult: ArticleResult, { dispatch }) => {
    const response = await api.post<ArticleResult>(
      `api/article_result/${articleResult.id}/process/`,
    );
    return response.data;
  },
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    errorModalChange: (
      state,
      action: PayloadAction<{ open: boolean; message?: string }>,
    ) => {
      state.errorModal = action.payload;
    },
    articleItemChange: (
      state,
      action: PayloadAction<{
        articleId: number;
        id: number;
        change: Partial<ArticleItem>;
      }>,
    ) => {
      const { articleId, id, change } = action.payload;
      const items = state.articleItems[articleId].items;
      items[id] = { ...items[id], ...change };
    },
    articleItemModalChange: (
      state,
      action: PayloadAction<ArticleItem | null>,
    ) => {
      state.articleItemModal = action.payload;
    },
    articleModalChange: (state, action: PayloadAction<Article | null>) => {
      state.articleModal = action.payload;
    },
    changeArticleResult: (state, action: PayloadAction<ArticleResult>) => {
      const result = action.payload;
      const index = findIndex(
        state.articleResults[result.article_id],
        ({ id }) => id === result.id,
      );
      state.articleResults[result.article_id][index] = result;
    },
    changeArticleItemResult: (
      state,
      action: PayloadAction<{
        id: number;
        change: Partial<ArticleItemResult>;
      }>,
    ) => {
      const { id, change } = action.payload;
      const index = findIndex(state.articleItemModal?.article_item_results, {
        id,
      });
      if (index >= 0 && state.articleItemModal) {
        state.articleItemModal.article_item_results[index] = {
          ...state.articleItemModal.article_item_results[index],
          ...change,
        };
      }
    },
    setAddingItemForm: (
      state,
      action: PayloadAction<AddingItemForm & { article_id: number }>,
    ) => {
      const { article_id } = action.payload;
      const currentState = state.addingItemForm[article_id];
      state.addingItemForm[article_id] = {
        ...currentState,
        ...action.payload,
      };
    },
    resetAddingItemForm: (
      state,
      action: PayloadAction<{ article_id: number }>,
    ) => {
      const { article_id } = action.payload;
      delete state.addingItemForm[article_id];
    },
    setAddArticleForm: (
      state,
      action: PayloadAction<Partial<AddArticleForm>>,
    ) => {
      state.addArticleForm = {
        ...state.addArticleForm,
        ...action.payload,
      };
    },
    changeCustomStyleModal: (
      state,
      action: PayloadAction<CustomStyleModal>,
    ) => {
      state.customStyleModal = {
        ...state.customStyleModal,
        ...action.payload,
        ...(!action.payload.open && { id: undefined, articleId: undefined }),
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getArticles.pending, (state) => {
      state.getArticlesState = {
        loading: true,
      };
    });
    builder.addCase(getArticles.fulfilled, (state, action) => {
      state.getArticlesState = {
        loading: false,
      };
      state.articles = keyBy(action.payload, "id");
      state.articlesOrder = map(action.payload, "id");
    });
    builder.addCase(getArticles.rejected, (state) => {
      state.getArticlesState = {
        loading: false,
      };
    });

    builder.addCase(addArticle.pending, (state) => {
      state.addArticleState = {
        loading: true,
      };
    });
    builder.addCase(addArticle.fulfilled, (state, action) => {
      state.addArticleState = {
        loading: false,
      };
      state.articles[action.payload.id] = action.payload;
      state.articleItems[action.payload.id] = {
        items: {},
        order: [],
      };
      state.articleResults[action.payload.id] = [];
    });
    builder.addCase(addArticle.rejected, (state) => {
      state.addArticleState = {
        loading: false,
      };
      state.errorModal.open = true;
    });

    builder.addCase(patchArticle.pending, (state, action) => {
      const { article, change } = action.meta.arg;
      state.articles[article.id] = { ...article, ...change };
    });
    builder.addCase(patchArticle.fulfilled, (state, action) => {
      const { article, change } = action.meta.arg;
      if (state.articleModal) state.articleModal = { ...article, ...change };
    });
    builder.addCase(patchArticle.rejected, (state, action) => {
      state.errorModal.open = true;
      const { article } = action.meta.arg;
      state.articles[article.id] = article;
    });

    builder.addCase(deleteArticle.pending, (state, action) => {
      const { id } = action.meta.arg;
      delete state.articles[id];
    });
    builder.addCase(deleteArticle.fulfilled, (state, action) => {
      const { id } = action.meta.arg;
      const index = findIndex(
        state.articlesOrder,
        (articleId) => articleId === id,
      );
      state.articlesOrder.splice(index, 1);
    });
    builder.addCase(deleteArticle.rejected, (state, action) => {
      state.errorModal.open = true;
      const article = action.meta.arg;
      state.articles[article.id] = article;
    });

    builder.addCase(changeArticlesOrder.pending, (state, action) => {
      const { order } = action.meta.arg;
      state.articlesOrder = order;
    });
    builder.addCase(changeArticlesOrder.rejected, (state, action) => {
      state.errorModal.open = true;
      const { origOrder } = action.meta.arg;
      state.articlesOrder = origOrder;
    });

    builder.addCase(getStyleOptions.pending, (state) => {
      state.getStyleOptionsState = {
        loading: true,
      };
    });
    builder.addCase(getStyleOptions.fulfilled, (state, action) => {
      state.getStyleOptionsState = {
        loading: false,
      };
      state.styleOptions = action.payload;
      state.styles = keyBy(action.payload, "id");
    });
    builder.addCase(getStyleOptions.rejected, (state) => {
      state.getStyleOptionsState = {
        loading: false,
      };
    });

    builder.addCase(getOneStyleOption.rejected, (state) => {
      state.customStyleModal.open = false;
      state.errorModal.open = true;
    });

    builder.addCase(patchStyleOptions.fulfilled, (state, action) => {
      const { id } = action.meta.arg;
      const index = findIndex(state.styleOptions, { id });
      state.styleOptions[index] = { ...action.payload, id };
      state.styles[id] = { ...action.payload, id };
      if (state.articleItemModal) {
        state.articleItemModal = {
          ...state.articleItemModal,
          input_language: action.payload.language,
          output_language: action.payload.language,
          style: id,
        };
      }
      if (state.addArticleForm.show) {
        state.addArticleForm = {
          ...state.addArticleForm,
          input_language: action.payload.language,
          output_language: action.payload.language,
          style: id,
        };
      }
    });
    builder.addCase(patchStyleOptions.rejected, (state) => {
      state.errorModal.open = true;
    });

    builder.addCase(postStyleOptions.fulfilled, (state, action) => {
      const { style_id, id: styleId, language } = action.payload;
      const id = style_id || styleId;
      state.styleOptions.unshift({ ...action.payload, id });
      state.styles[id] = { ...action.payload, id };
      if (state.articleItemModal) {
        state.articleItemModal = {
          ...state.articleItemModal,
          input_language: language,
          output_language: language,
          style: id,
        };
      }
      if (state.addArticleForm.show) {
        state.addArticleForm = {
          ...state.addArticleForm,
          input_language: language,
          output_language: language,
          style: id,
        };
      }
    });
    builder.addCase(postStyleOptions.rejected, (state) => {
      state.errorModal.open = true;
    });

    builder.addCase(deleteStyleOptions.fulfilled, (state, action) => {
      const id = action.meta.arg;
      const index = findIndex(state.styleOptions, { id });
      if (index >= 0) {
        state.styleOptions.splice(index, 1);
      }
      delete state.styles[id];
    });
    builder.addCase(deleteStyleOptions.rejected, (state) => {
      state.errorModal.open = true;
    });

    builder.addCase(getLanguageOptions.pending, (state) => {
      state.getLanguageOptionsState = {
        loading: true,
      };
    });
    builder.addCase(getLanguageOptions.fulfilled, (state, action) => {
      state.getLanguageOptionsState = {
        loading: false,
      };
      state.languageOptions = action.payload;
    });
    builder.addCase(getLanguageOptions.rejected, (state) => {
      state.getLanguageOptionsState = {
        loading: false,
      };
    });

    builder.addCase(getArticleItems.pending, (state) => {
      state.getArticleItemsState = {
        loading: true,
      };
    });
    builder.addCase(getArticleItems.fulfilled, (state, action) => {
      state.getArticleItemsState = {
        loading: false,
      };
      each(action.payload, ({ data, article_id }) => {
        state.articleItems[article_id] = {
          items: keyBy(data, "id"),
          order: map(data, ({ id }) => id),
        };
      });
    });
    builder.addCase(getArticleItems.rejected, (state) => {
      state.getArticleItemsState = {
        loading: false,
      };
    });

    builder.addCase(getArticleItem.fulfilled, (state, action) => {
      const { article_id, id, status, error_text } = action.payload;
      state.articleItems[article_id].items[id] = action.payload;
      if (state.articleItemModal?.id === id) {
        state.articleItemModal = action.payload;
      }
      if (isArticleItemError(status) && error_text) {
        state.errorModal = {
          open: true,
          message: error_text,
          isInfo: status === "billing_error",
        };
      }
    });

    builder.addCase(addArticleItem.pending, (state, action) => {
      const {
        articleItem: { article_id },
      } = action.meta.arg;
      state.addArticleItemState[article_id] = {
        loading: true,
      };
    });
    builder.addCase(addArticleItem.fulfilled, (state, action) => {
      const articleItem = action.payload;
      state.addArticleItemState[articleItem.article_id] = {
        loading: false,
      };
      state.articleItems[articleItem.article_id].items[articleItem.id] =
        articleItem;
      const { error_text, status } = articleItem;
      if (isArticleItemError(status) && error_text) {
        state.errorModal = {
          open: true,
          message: error_text,
          isInfo: status === "billing_error",
        };
      }
    });
    builder.addCase(addArticleItem.rejected, (state, action) => {
      const {
        articleItem: { article_id },
      } = action.meta.arg;
      state.addArticleItemState[article_id] = {
        loading: false,
      };
      const message = action.payload?.error_text;
      const status = action.payload?.status;
      if (message) {
        state.errorModal = {
          open: true,
          message,
          isInfo: status === "billing_error",
        };
      }
    });

    builder.addCase(retryArticleItem.fulfilled, (state, action) => {
      const { id, article_id } = action.payload;
      state.articleItems[article_id].items[id] = action.payload;
    });
    builder.addCase(retryArticleItem.rejected, (state) => {
      state.errorModal.open = true;
    });

    builder.addCase(patchArticleItem.pending, (state, action) => {
      const { articleItem, change } = action.meta.arg;
      state.articleItems[articleItem.article_id].items[articleItem.id] = {
        ...articleItem,
        ...change,
      };
    });
    builder.addCase(patchArticleItem.fulfilled, (state, action) => {
      const { articleItem } = action.meta.arg;
      state.articleItems[articleItem.article_id].items[articleItem.id] = {
        ...articleItem,
        ...action.payload,
      };
    });
    builder.addCase(patchArticleItem.rejected, (state, action) => {
      state.errorModal.open = true;
      const { articleItem } = action.meta.arg;
      state.articleItems[articleItem.article_id].items[articleItem.id] =
        articleItem;
    });

    builder.addCase(deleteArticleItem.pending, (state, action) => {
      const { id, article_id } = action.meta.arg;
      delete state.articleItems[article_id].items[id];
    });
    builder.addCase(deleteArticleItem.rejected, (state, action) => {
      state.errorModal.open = true;
      const { id, article_id } = action.meta.arg;
      state.articleItems[article_id].items[id] = action.meta.arg;
    });

    builder.addCase(changeArticleItemsOrder.pending, (state, action) => {
      const { article_id, order } = action.meta.arg;
      state.articleItems[article_id].order = order;
    });
    builder.addCase(changeArticleItemsOrder.rejected, (state, action) => {
      state.errorModal.open = true;
      const { article_id, origOrder } = action.meta.arg;
      state.articleItems[article_id].order = origOrder;
    });

    builder.addCase(moveArticleItem.pending, (state, action) => {
      const {
        id,
        source_article_id,
        destination_article_id,
        sourceOrder,
        destinationOrder,
      } = action.meta.arg;
      state.articleItems[destination_article_id].items[id] = {
        ...state.articleItems[source_article_id].items[id],
        article_id: destination_article_id,
      };
      delete state.articleItems[source_article_id].items[id];
      state.articleItems[source_article_id].order = sourceOrder;
      state.articleItems[destination_article_id].order = destinationOrder;
    });
    builder.addCase(moveArticleItem.rejected, (state, action) => {
      const {
        id,
        source_article_id,
        destination_article_id,
        sourceOrigOrder,
        destinationOrigOrder,
      } = action.meta.arg;
      state.articleItems[source_article_id].items[id] = {
        ...state.articleItems[destination_article_id].items[id],
        article_id: source_article_id,
      };
      delete state.articleItems[destination_article_id].items[id];
      state.articleItems[source_article_id].order = sourceOrigOrder;
      state.articleItems[destination_article_id].order = destinationOrigOrder;
    });

    builder.addCase(rewriteArticleItem.pending, (state, action) => {
      const { articleItem } = action.meta.arg;
      state.rewriteArticleItemState = { [articleItem.id]: true };
    });
    builder.addCase(rewriteArticleItem.fulfilled, (state, action) => {
      const articleItem = action.payload;
      state.rewriteArticleItemState[articleItem.id] = false;
      state.articleItems[articleItem.article_id].items[articleItem.id] =
        articleItem;
      if (state.articleItemModal?.id === articleItem.id) {
        state.articleItemModal = articleItem;
      }
    });
    builder.addCase(rewriteArticleItem.rejected, (state, action) => {
      const { articleItem } = action.meta.arg;
      state.rewriteArticleItemState[articleItem.id] = false;
      const message = action.payload?.error_text;
      const status = action.payload?.status;
      state.errorModal = {
        open: true,
        message,
        isInfo: status === "billing_error",
      };
    });

    builder.addCase(patchArticleItemResult.fulfilled, (state, action) => {
      const { articleId, articleItemId, id } = action.meta.arg;
      const articleItem = state.articleItems[articleId].items[articleItemId];
      articleItem.article_item_results = map(
        articleItem.article_item_results,
        (result) => {
          if (result.id === id) return action.payload;
          else
            return {
              ...result,
              favorite: action.payload.favorite ? false : result.favorite,
            };
        },
      );
      if (action.payload.favorite) {
        articleItem.visible_text = action.payload.text;
      }
      if (state.articleItemModal?.id === articleItemId) {
        state.articleItemModal = articleItem;
      }
    });
    builder.addCase(patchArticleItemResult.rejected, (state) => {
      state.errorModal.open = true;
    });

    builder.addCase(deleteArticleItemResult.fulfilled, (state, action) => {
      const { articleId, articleItemId, id } = action.meta.arg;
      const articleItem = state.articleItems[articleId].items[articleItemId];
      const resultIndex = findIndex(articleItem.article_item_results, { id });
      const isFavorite =
        articleItem.article_item_results[resultIndex]?.favorite;
      articleItem.article_item_results = filter(
        articleItem.article_item_results,
        (result) => result.id !== id,
      );
      if (isFavorite) {
        const lastItem = last(articleItem.article_item_results);
        if (lastItem) {
          lastItem.favorite = true;
          articleItem.visible_text = lastItem.text;
        } else {
          articleItem.article_item_results = map(
            articleItem.article_item_results,
            (result) => ({ ...result, favorite: false }),
          );
          articleItem.visible_text = articleItem.input_text;
        }
      }
      if (state.articleItemModal?.id === articleItemId) {
        state.articleItemModal = articleItem;
      }
    });
    builder.addCase(deleteArticleItemResult.rejected, (state) => {
      state.errorModal.open = true;
    });

    builder.addCase(
      resetArticleItemResultFavorite.fulfilled,
      (state, action) => {
        const { articleId, articleItemId } = action.meta.arg;
        const articleItem = state.articleItems[articleId].items[articleItemId];
        articleItem.article_item_results = map(
          articleItem.article_item_results,
          (result) => ({ ...result, favorite: false }),
        );
        articleItem.visible_text = articleItem.input_text;
        if (state.articleItemModal?.id === articleItemId) {
          state.articleItemModal = articleItem;
        }
      },
    );
    builder.addCase(resetArticleItemResultFavorite.rejected, (state) => {
      state.errorModal.open = true;
    });

    builder.addCase(getArticleResults.pending, (state) => {
      state.getArticleResultsState = { loading: true };
    });
    builder.addCase(getArticleResults.fulfilled, (state, action) => {
      state.getArticleResultsState = { loading: false };
      each(action.payload, ({ data, article_id }) => {
        state.articleResults[article_id] = data;
      });
    });
    builder.addCase(getArticleResults.rejected, (state) => {
      state.getArticleResultsState = { loading: false };
    });

    builder.addCase(rewriteArticle.pending, (state, action) => {
      state.rewriteArticleState[action.meta.arg] = true;
    });
    builder.addCase(rewriteArticle.fulfilled, (state, action) => {
      const articleResult = action.payload;
      const { article_id } = articleResult;
      state.rewriteArticleState[article_id] = false;
      state.articleResults[article_id].push(articleResult);
    });
    builder.addCase(rewriteArticle.rejected, (state, action) => {
      state.rewriteArticleState[action.meta.arg] = false;
      const error = action.payload;
      const message = error
        ? error.error_text || error["Limit error"] || error.Error
        : undefined;
      state.errorModal = {
        open: true,
        message,
        isInfo: !!(error && error["Limit error"]),
      };
    });

    builder.addCase(patchArticleResult.pending, (state, action) => {
      const { articleResult, change } = action.meta.arg;
      const results = state.articleResults[articleResult.article_id];
      const index = findIndex(results, ({ id }) => id === articleResult.id);
      state.articleResults[articleResult.article_id][index] = {
        ...articleResult,
        ...change,
      };
    });
    builder.addCase(patchArticleResult.rejected, (state, action) => {
      state.errorModal.open = true;
      const { articleResult } = action.meta.arg;
      const results = state.articleResults[articleResult.article_id];
      const index = findIndex(results, ({ id }) => id === articleResult.id);
      state.articleResults[articleResult.article_id][index] = articleResult;
    });

    builder.addCase(deleteArticleResult.pending, (state, action) => {
      const { id, article_id } = action.meta.arg;
      state.articleResults[article_id] = filter(
        state.articleResults[article_id],
        ({ id: resultId }) => resultId !== id,
      );
    });
    builder.addCase(deleteArticleResult.rejected, (state) => {
      state.errorModal.open = true;
    });

    builder.addCase(rewriteArticleResult.pending, (state, action) => {
      const { article_id } = action.meta.arg;
      state.rewriteArticleState[article_id] = true;
    });
    builder.addCase(rewriteArticleResult.fulfilled, (state, action) => {
      const { article_id } = action.payload;
      state.rewriteArticleState[article_id] = false;
      state.articleResults[article_id].push(action.payload);
    });
    builder.addCase(rewriteArticleResult.rejected, (state, action) => {
      const { article_id } = action.meta.arg;
      state.rewriteArticleState[article_id] = false;
      state.errorModal.open = true;
    });
  },
});

export const {
  errorModalChange,
  articleItemModalChange,
  articleModalChange,
  changeArticleResult,
  setAddingItemForm,
  setAddArticleForm,
  resetAddingItemForm,
  changeArticleItemResult,
  articleItemChange,
  changeCustomStyleModal,
} = dashboardSlice.actions;

export const selectArticles = (state: RootState) => state.dashboard.articles;
export const selectArticlesOrder = (state: RootState) =>
  state.dashboard.articlesOrder;
export const selectGetArticlesState = (state: RootState) =>
  state.dashboard.getArticlesState;
export const selectArticleById = (state: RootState, id: number) =>
  find(state.dashboard.articles, { id });
export const selectStyleOptions = (state: RootState) =>
  state.dashboard.styleOptions;
export const selectTextStyles = (state: RootState) => state.dashboard.styles;
export const selectLanguageOptions = (state: RootState) =>
  state.dashboard.languageOptions;
export const selectArticleItems = (state: RootState) =>
  state.dashboard.articleItems;
export const selectArticleResults = (state: RootState) =>
  state.dashboard.articleResults;
export const selectHasArticleResults = (state: RootState, article_id: number) =>
  size(state.dashboard.articleResults[article_id]) > 0;
export const selectAddArticleState = (state: RootState) =>
  state.dashboard.addArticleState;
export const selectGetStyleOptionsState = (state: RootState) =>
  state.dashboard.getStyleOptionsState;
export const selectGetLanguageOptionsState = (state: RootState) =>
  state.dashboard.getLanguageOptionsState;
export const selectGetArticleItemsState = (state: RootState) =>
  state.dashboard.getArticleItemsState;
export const selectAddArticleItemState = (
  state: RootState,
  article_id: number,
) => state.dashboard.addArticleItemState[article_id];
export const selectAddingItemForm = (state: RootState, article_id: number) =>
  state.dashboard.addingItemForm[article_id];
export const selectAddArticleForm = (state: RootState) =>
  state.dashboard.addArticleForm;
export const selectRewriteArticleItemState = (state: RootState) =>
  state.dashboard.rewriteArticleItemState;
export const selectGetArticleResultsState = (state: RootState) =>
  state.dashboard.getArticleResultsState;
export const selectRewriteArticleState = (state: RootState) =>
  state.dashboard.rewriteArticleState;
export const selectErrorModal = (state: RootState) =>
  state.dashboard.errorModal;
export const selectArticleItemModal = (state: RootState) =>
  state.dashboard.articleItemModal;
export const selectArticleModal = (state: RootState) =>
  state.dashboard.articleModal;
export const selectTotalArticeItems = (state: RootState) =>
  reduce(
    state.dashboard.articleItems,
    (total, item) => total + size(item?.order),
    0,
  );
export const selectCustomStyleModal = (state: RootState) =>
  state.dashboard.customStyleModal;

export default dashboardSlice.reducer;
