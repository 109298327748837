import ReduxProvider from "./context/redux";
import RouterProvider from "./context/router";
import ThemeProvider from "./context/theme";
import "./index.css";
import "./localization/i18n";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { persistor } from "./store";
import ReactDOM from "react-dom/client";
import { PersistGate } from "redux-persist/integration/react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <ThemeProvider>
    <ReduxProvider>
      <PersistGate loading={null} persistor={persistor}>
        <RouterProvider />
      </PersistGate>
    </ReduxProvider>
  </ThemeProvider>,
);

serviceWorkerRegistration.register();
