import { useBilling } from "../../../../hooks";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { formatTime, noSleep } from "../../../../utils";
import {
  Article,
  ArticleItemType,
  addArticleItem,
  errorModalChange,
  selectAddArticleItemState,
  setAddingItemForm,
} from "../../dashboardSlice";
import { useAudioRecorder, useScrollIntoView } from "../../hooks";
import AudioPanel from "../audio-panel";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

interface RecordAudioItemProps {
  article: Article;
  itemType: ArticleItemType;
  onResetItemType: () => void;
  url?: string;
}

const pulseShadow = keyframes({
  "0%": {
    boxShadow: "0 0 0 0 rgba(100, 100, 100, 0.4)",
  },
  "70%": {
    boxShadow: "0 0 0 10px rgba(100, 100, 100, 0)",
  },
  "100%": {
    boxShadow: "0 0 0 0 rgba(100, 100, 100, 0)",
  },
});

const Container = styled.div<{ isLoading: boolean }>(
  {
    alignItems: "center",
    gap: 10,
    borderRadius: 5,
  },
  ({ theme: { colors }, isLoading }) => ({
    display: isLoading ? "block" : "flex",
    padding: isLoading ? 15 : "8px 12px",
    background: colors.lightgrey2,
    color: colors.black,
  }),
);

const MicIcon = styled.i<{ recording?: boolean }>(
  {
    flexShrink: 0,
    width: 50,
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    fontSize: 22,
    cursor: "pointer",
    animation: `${pulseShadow} 1.5s infinite`,
  },
  ({ theme: { colors } }) => ({
    color: colors.white,
    background: colors.red2,
  }),
);

const Time = styled.div(
  {
    fontWeight: 500,
  },
  ({ theme: { fontSize } }) => fontSize.sm,
);

const Wave = styled.div({
  flexGrow: 1,
});

const Canvas = styled.canvas({
  height: 50,
  width: "100%",
});

const CancelIcon = styled.i({ cursor: "pointer" }, ({ theme: { colors } }) => ({
  color: colors.purple,
}));

const RecordAudioItem: FC<RecordAudioItemProps> = ({
  article,
  itemType,
  url,
  onResetItemType,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { loading = false } =
    useAppSelector((state) => selectAddArticleItemState(state, article.id)) ||
    {};
  const { plan } = useBilling();
  const { limit_note_audio_min = 3 } = plan?.planDetails || {};

  const canvas = useRef<HTMLCanvasElement | null>(null);
  const interval = useRef<number | undefined>(undefined);
  const [time, setTime] = useState(limit_note_audio_min * 60);
  const ref = useScrollIntoView<HTMLDivElement>();

  const { startRecording, stopRecording, getStream, visualize } =
    useAudioRecorder();

  const startTimer = useCallback(() => {
    interval.current = window.setInterval(() => {
      setTime((time) => time - 1);
    }, 1000);
  }, []);

  const init = useCallback(async () => {
    try {
      await getStream();
      startRecording();
      startTimer();
      visualize(canvas.current);
    } catch (error) {
      dispatch(
        errorModalChange({
          open: true,
          message: t("dashboard.microphoneAccess"),
        }),
      );
      onResetItemType();
    }
  }, [
    getStream,
    startRecording,
    startTimer,
    visualize,
    dispatch,
    t,
    onResetItemType,
  ]);

  useEffect(() => {
    init();
  }, [init]);

  const onStop = useCallback(async () => {
    window.clearInterval(interval.current);
    try {
      const url = await stopRecording();
      dispatch(setAddingItemForm({ article_id: article.id, url }));
      await dispatch(
        addArticleItem({
          articleItem: {
            article_id: article.id,
            type: itemType,
          },
          url,
        }),
      );
    } catch (error) {
      dispatch(errorModalChange({ open: true }));
    }
    noSleep.disable();
  }, [article.id, itemType, stopRecording, dispatch]);

  const onClose = useCallback(async () => {
    window.clearInterval(interval.current);
    try {
      await stopRecording();
    } catch (error) {
      dispatch(errorModalChange({ open: true }));
    }
    noSleep.disable();
    onResetItemType();
  }, [stopRecording, onResetItemType, dispatch]);

  useEffect(() => {
    if (time <= 0) {
      onStop();
    }
  }, [time, onStop]);

  return (
    <Container isLoading={loading} ref={ref}>
      {loading ? (
        <AudioPanel type={itemType} src={url || ""} isLoading={loading} />
      ) : (
        <>
          <MicIcon className="icon-mic" onClick={onStop} />
          <Time>{formatTime(time)}</Time>
          <Wave>
            <Canvas ref={canvas} />
          </Wave>
          <CancelIcon className="icon-close" onClick={onClose} />
        </>
      )}
    </Container>
  );
};

export default RecordAudioItem;
