import { FC, useState } from "react";
import { isNaN, map, noop, range, toNumber, toString } from "lodash";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

const Container = styled.div({
  width: 132,
  height: 20,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "0 3px",
});

interface StarProps {
  index: number;
  setHoverIndex: (index: number) => void;
  onChange: (mark: string) => void;
  active?: boolean;
  isHover?: boolean;
  disabled?: boolean;
}

const Star: FC<StarProps> = ({
  index,
  active,
  isHover,
  disabled,
  setHoverIndex,
  onChange,
}) => {
  const theme = useTheme();
  const {
    colors: { grey, purple, lightblue },
  } = theme;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      cursor={disabled ? "default" : "pointer"}
      onMouseOver={() => setHoverIndex(index)}
      onClick={() => onChange(toString(index))}
    >
      <path
        d="M9.52447 3.08156C9.67415 2.6209 10.3259 2.6209 10.4755 3.08156L11.5451 6.37336C11.7459 6.99139 12.3218 7.40983 12.9717 7.40983H16.4329C16.9172 7.40983 17.1186 8.02964 16.7268 8.31434L13.9266 10.3488C13.4009 10.7307 13.1809 11.4078 13.3817 12.0258L14.4513 15.3176C14.6009 15.7783 14.0737 16.1613 13.6818 15.8766L10.8817 13.8422C10.3559 13.4602 9.64405 13.4602 9.11832 13.8422L6.31815 15.8766C5.9263 16.1613 5.39906 15.7783 5.54873 15.3176L6.6183 12.0258C6.81911 11.4078 6.59913 10.7307 6.07339 10.3488L3.27323 8.31434C2.88137 8.02964 3.08276 7.40983 3.56712 7.40983H7.02832C7.67816 7.40983 8.25409 6.99139 8.4549 6.37336L9.52447 3.08156Z"
        stroke={disabled ? grey : purple}
        fill={
          active
            ? disabled
              ? grey
              : purple
            : isHover && !disabled
            ? lightblue
            : "none"
        }
      />
    </svg>
  );
};

interface StarsProps {
  value?: string;
  onChange: (mark: string) => void;
  disabled?: boolean;
}

const Stars: FC<StarsProps> = ({ value, disabled, onChange }) => {
  const [hover, setHover] = useState(0);

  let mark = toNumber(value);
  if (isNaN(mark)) mark = 0;

  return (
    <Container onMouseLeave={() => setHover(0)}>
      {map(range(1, 6), (index) => (
        <Star
          key={index}
          index={index}
          setHoverIndex={setHover}
          onChange={disabled ? noop : onChange}
          active={index <= mark}
          isHover={hover >= index}
          disabled={disabled}
        />
      ))}
    </Container>
  );
};

export default Stars;
