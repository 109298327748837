import styled from "@emotion/styled";

const Card = styled.div`
  display: inline-flex;
  padding: 15px;
  border-radius: 20px;
  background: ${({ theme: { colors } }) => colors.white};
  box-shadow: 0px 4px 15px 0px rgba(190, 190, 190, 0.2);
  &:focus-visible {
    outline: none;
  }
`;

export default Card;
