import Select, { Option } from "../../../components/select";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  changeCustomStyleModal,
  selectStyleOptions,
  selectTextStyles,
} from "../dashboardSlice";
import CustomStyleLink from "./custom-style/custom-style-link";
import styled from "@emotion/styled";
import { toString, toNumber, each, find } from "lodash";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const Container = styled.div({
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  gap: 10,
});

const Label = styled.label`
  margin-right: 15px;
`;

const SelectOption = styled.span({
  display: "flex",
  justifyContent: "space-between",
  gap: 5,
});

const PenIcon = styled.i({ fontSize: 12 }, ({ theme: { colors } }) => ({
  color: colors.purple,
}));

interface WritingStyleSelecttProps {
  value: number;
  onChange: (value: number) => void;
  onClose?: () => void;
  disabled?: boolean;
  filterLanguageValue?: number;
  articleId?: number;
  hideCustomStyleLink?: boolean;
}

const WritingStyleSelect: FC<WritingStyleSelecttProps> = ({
  value,
  onChange,
  onClose,
  disabled,
  filterLanguageValue,
  articleId,
  hideCustomStyleLink,
}) => {
  const { t } = useTranslation();
  const options = useAppSelector(selectStyleOptions);
  const styles = useAppSelector(selectTextStyles);
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);

  const label = t("settings.style");

  const convertedOptions = useMemo(() => {
    const result: Option[] = [];
    each(options, ({ id, language, style_name, type }) => {
      if (filterLanguageValue && filterLanguageValue !== language) {
        return;
      }

      const opencustomStyleModal = () => {
        dispatch(changeCustomStyleModal({ open: true, id, articleId }));
        setOpen(false);
        if (onClose) onClose();
      };

      result.push({
        value: `${id}`,
        label:
          type === "custom" ? (
            <SelectOption>
              {style_name}
              <PenIcon
                className="icon-pen"
                onMouseDown={opencustomStyleModal}
                onTouchStart={opencustomStyleModal}
              />
            </SelectOption>
          ) : (
            style_name
          ),
      });
    });
    return result;
  }, [options, filterLanguageValue, dispatch, articleId, onClose]);

  const handleChange = useCallback(
    (value: string) => onChange(toNumber(value)),
    [onChange],
  );

  useEffect(() => {
    if (!find(convertedOptions, { value: toString(value) })) {
      onChange(toNumber(convertedOptions[0].value));
    }
  }, [value, convertedOptions, onChange]);

  const customValue = useMemo(() => styles[value]?.style_name, [styles, value]);

  return (
    <Container>
      <Label>{label}</Label>
      <Select
        open={open}
        setOpen={setOpen}
        value={toString(value)}
        onChange={handleChange}
        options={convertedOptions}
        ariaLabel={label}
        customValue={customValue}
        disabled={disabled}
      />
      {!hideCustomStyleLink && <CustomStyleLink articleId={articleId} />}
    </Container>
  );
};

export default WritingStyleSelect;
