import Modal from "../../../components/modal";
import { fadeIn } from "../../../styles/animations";
import { ClassNames, useTheme } from "@emotion/react";
import { CSSInterpolation } from "@emotion/serialize";
import styled from "@emotion/styled";
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from "react";

const Container = styled.div(
  {
    width: "100%",
    position: "relative",
    padding: 15,
    borderRadius: 15,
  },
  ({ theme: { colors, media } }) => ({
    background: colors.white,
    [media.sm]: {
      padding: 25,
    },
    [media.md]: {
      padding: 40,
    },
  }),
);

const FullSize = styled.i(
  {
    width: 30,
    height: 30,
    position: "absolute",
    top: 15,
    right: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 30,
    borderRadius: 5,
    cursor: "pointer",
  },
  ({ theme: { colors, media } }) => ({
    background: colors.purple,
    color: colors.white,
    [media.sm]: {
      top: 25,
      right: 70,
    },
    [media.md]: {
      top: 30,
      right: 85,
    },
  }),
);

interface FullsizeModalProps extends PropsWithChildren {
  open?: boolean;
  onOpenChange: (open: boolean) => void;
  className?: string;
}

interface IFullsizeModalContext {
  fullSize?: boolean;
}
const FullsizeModalContext = createContext<IFullsizeModalContext>({
  fullSize: false,
});
export const useFullsizeModalContext = () => useContext(FullsizeModalContext);

const FullsizeModal: FC<FullsizeModalProps> = ({
  children,
  open,
  onOpenChange,
  className,
}) => {
  const theme = useTheme();
  const [fullSize, setFullSize] = useState(false);

  const onFullSizeToggle = () => setFullSize(!fullSize);

  const value = useMemo(() => ({ fullSize }), [fullSize]);

  const Content = (
    <Container>
      <FullsizeModalContext.Provider value={value}>
        {children}
      </FullsizeModalContext.Provider>
      <FullSize className="icon-full-size" onClick={onFullSizeToggle} />
    </Container>
  );

  return (
    <ClassNames>
      {({ css }) => {
        const styles: CSSInterpolation = {
          width: "95vw",
          minHeight: "auto",
          position: "relative",
          top: 0,
          left: 0,
          display: "flex",
          margin: "auto",
          overflow: "auto",
          transform: "none",
          animation: `${fadeIn} 150ms`,
          [theme.media.lg]: {
            width: "83vw",
          },
        };
        if (fullSize) {
          styles.margin = "0px !important";
          styles.minHeight = "100%";
          styles.width = "100% !important";
        }

        return (
          <Modal
            content={Content}
            open={open}
            onOpenChange={onOpenChange}
            contentProps={{
              className: css(styles) + (className ? ` ${className}` : ""),
            }}
          />
        );
      }}
    </ClassNames>
  );
};

export default FullsizeModal;
