import styled from "@emotion/styled";

interface CloseButtonProps {
  top?: number;
  right?: number;
}

const CloseButton = styled.i<CloseButtonProps>(
  {
    width: 20,
    height: 20,
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    fontSize: 20,
    border: "none",
    cursor: "pointer",
  },
  ({ theme: { colors }, top = 20, right = 20 }) => ({
    top,
    right,
    color: colors.purple,
  })
);

CloseButton.defaultProps = { className: "icon-close" };

export default CloseButton;
