import styled from "@emotion/styled";

const AuthFormHeader = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 5px;
`;

export default AuthFormHeader;
