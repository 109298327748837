import AudioPanel from "../pages/root/components/audio-panel";
import { ArticleItemType } from "../pages/root/dashboardSlice";
import styled from "@emotion/styled";
import { FC, MouseEvent, PropsWithChildren, useCallback, useMemo } from "react";
import { Trans } from "react-i18next";

const RetryContainer = styled.div(
  {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    gap: 15,
    padding: 15,
    borderRadius: 5,
    ":hover": {
      ".control": { display: "block" },
    },
  },
  ({ theme: { colors } }) => ({
    background: colors.lightgrey2,
  }),
);

const RetryLink = styled.a({}, ({ theme: { colors } }) => ({
  color: colors.purple,
}));

interface RetryProps extends PropsWithChildren {
  itemType?: ArticleItemType;
  url?: string;
  fileName?: string;
  error?: string;
  onRetry: () => void;
}

const Retry: FC<RetryProps> = ({
  itemType,
  url,
  fileName,
  error,
  onRetry,
  children,
}) => {
  const handleRetry = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      onRetry();
    },
    [onRetry],
  );

  const retryError = useMemo(() => {
    if (error && error.length > 300) return `${error.substring(0, 300)}...`;
    return error;
  }, [error]);

  return (
    <RetryContainer>
      {(itemType === "audio_file" || itemType === "live_audio") && (
        <AudioPanel type={itemType} src={url || ""} fileName={fileName} />
      )}
      <div>
        <Trans
          i18nKey="dashboard.retry"
          components={{
            tryAgain: <RetryLink href="/" onClick={handleRetry} />,
            supportLink: <RetryLink href="mailto:ae@flipner.com" />,
          }}
        />
      </div>
      {retryError && <div title={error}>{retryError}</div>}
      {children}
    </RetryContainer>
  );
};

export default Retry;
