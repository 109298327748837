import Button from "../../../../components/button";
import Card from "../../../../components/card";
import Tooltip from "../../../../components/tooltip";
import { PaymentPeriod } from "../../billingSlice";
import { TariffSchema } from "../hooks";
import styled from "@emotion/styled";
import { map, size } from "lodash";
import { FC } from "react";
import { useTranslation } from "react-i18next";

const Container = styled(Card)<{ isPopular?: boolean }>(
  {
    flexShrink: 0,
    width: 310,
    height: "auto",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px 25px",
    fontWeight: 500,
    borderRadius: 10,
  },
  ({ theme: { media, fontSize, colors }, isPopular }) => ({
    background: isPopular ? colors.lightblue2 : colors.white,
    color: colors.black,
    ...fontSize.md,
    [media.sm]: {
      height: 665,
      padding: "30px 25px",
    },
  }),
);

const Popular = styled.div(
  {
    height: 32,
    position: "absolute",
    top: 0,
    left: "50%",
    display: "flex",
    alignItems: "center",
    padding: "0px 12px",
    borderRadius: 5,
    transform: "translate(-50%, -50%)",
  },
  ({ theme: { colors } }) => ({
    background: colors.purpleHover,
    color: colors.white,
  }),
);

const Price = styled.div(
  {
    margin: "20px 0 5px 0",
    fontSize: 32,
    lineHeight: "40px",
    fontWeight: 600,
  },
  ({ theme: { media, colors } }) => ({
    color: colors.purple,
    [media.sm]: {
      margin: "30px 0 5px 0",
    },
  }),
);

const TryButton = styled(Button)({ marginTop: 15 });

const Features = styled.ul({
  alignSelf: "start",
  display: "flex",
  flexDirection: "column",
  gap: 10,
  fontWeight: 400,
  listStyle: "none",
  margin: "20px 0 0 0",
  padding: 0,
});

const DisabledFeatures = styled(Features)(
  {
    marginTop: 15,
  },
  ({ theme: { colors } }) => ({ color: colors.grey }),
);

const HighlightedFeatures = styled(Features)(
  { width: "100%", padding: 10 },
  ({ theme: { colors } }) => ({
    background: colors.lightgrey2,
  }),
);

const Feature = styled.li(
  { display: "flex", alignItems: "center", gap: 5 },
  ({ theme: { fontSize } }) => fontSize.sm,
);

const Star = styled.i(
  {
    alignSelf: "start",
    width: 12,
    height: 12,
    fontSize: 12,
  },
  ({ theme: { colors } }) => ({
    color: colors.purple,
  }),
);

const Icon = styled.i({ marginLeft: 5 }, ({ theme: { colors } }) => ({
  color: colors.purple,
}));

const Dash = styled.span({ fontWeight: 900 });

interface TariffProps extends TariffSchema {
  isCurrent: boolean;
  isCurrentEnterprice?: boolean;
  isCurrentLtd?: boolean;
  paymentPeriod: PaymentPeriod;
  onChange: () => void;
  className?: string;
}

const Tariff: FC<TariffProps> = ({
  title,
  features,
  disabledFeatures,
  highlightedFeatures,
  isPopular,
  isCurrent,
  isCurrentEnterprice,
  isCurrentLtd,
  priceAnnually,
  priceMonthly,
  paymentPeriod,
  isEnterprice,
  onChange,
  className,
}) => {
  const { t } = useTranslation();

  const showPriceAnnualy =
    paymentPeriod === "year" &&
    typeof priceAnnually === "string" &&
    priceAnnually !== "";
  const showPriceMonthly =
    paymentPeriod === "month" &&
    typeof priceMonthly === "string" &&
    priceMonthly !== "";

  return (
    <Container isPopular={isPopular} className={className}>
      {title}
      {showPriceAnnualy && <Price>{priceAnnually}$</Price>}
      {showPriceMonthly && <Price>{priceMonthly}$</Price>}
      {isEnterprice && <Price>{t("billing.enterprise.price")}</Price>}
      {(showPriceAnnualy || showPriceMonthly) && t("billing.perMonth")}
      {isEnterprice ? (
        <a href="mailto:info@flipner.com">
          <TryButton disabled={isCurrentEnterprice}>
            {isCurrentEnterprice
              ? t("billing.currentTariff")
              : t("billing.contactUs")}
          </TryButton>
        </a>
      ) : (
        !isCurrentLtd && (
          <TryButton
            disabled={isCurrent || isCurrentEnterprice}
            onClick={onChange}
          >
            {isCurrent ? t("billing.currentTariff") : t("billing.getStarted")}
          </TryButton>
        )
      )}
      {size(highlightedFeatures) > 0 && (
        <HighlightedFeatures>
          {map(highlightedFeatures, (feature, index) => (
            <Feature key={index}>
              <Star className="icon-star" />
              {feature}
            </Feature>
          ))}
        </HighlightedFeatures>
      )}
      <Features>
        {map(features, (feature, index) => (
          <Feature key={index}>
            <Star className="icon-star" />
            {feature.text}
            {feature.icon && feature.tooltip ? (
              <Tooltip content={feature.tooltip}>
                <Icon className={feature.icon} />
              </Tooltip>
            ) : (
              feature.icon && <Icon className={feature.icon} />
            )}
          </Feature>
        ))}
      </Features>
      <DisabledFeatures>
        {map(disabledFeatures, (feature, index) => (
          <Feature key={index}>
            <Dash>-</Dash>
            {feature}
          </Feature>
        ))}
      </DisabledFeatures>
      {isPopular && <Popular>Most popular</Popular>}
    </Container>
  );
};

export default Tariff;
