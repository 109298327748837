import { ReactComponent as Logo } from "../../assets/flipner-logo.svg";
import InstallIconChrome from "../../assets/install-icon-chrome.png";
import InstallIconIOS from "../../assets/install-icon-ios.png";
import { ReactComponent as InstallIcon } from "../../assets/install-icon.svg";
import AnimatedButton from "../../components/animated-button";
import Card from "../../components/card";
import { copyText } from "../../utils";
import styled from "@emotion/styled";
import { useCallback, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { UAParser } from "ua-parser-js";

const Container = styled.div({
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const AppCard = styled(Card)({
  display: "flex",
  flexDirection: "column",
  gap: 20,
  padding: 20,
});

const Title = styled.h1(
  {
    margin: 0,
    fontWeight: 500,
  },
  ({ theme: { fontSize } }) => fontSize.lg,
);

const AppInfo = styled.div({
  display: "flex",
  alignItems: "center",
  gap: 20,
});

const AppLogo = styled(Logo)({
  width: 64,
  height: 64,
});

const AppDescription = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: 5,
});

const AppName = styled.span({ fontWeight: 500 });
const Website = styled.span({}, ({ theme: { fontSize } }) => fontSize.sm);

const NotSupported = styled.div(
  {
    width: 250,
  },
  ({ theme: { media } }) => ({
    [media.md]: {
      width: 450,
    },
  }),
);

const InstructionInstallIcon = styled.img({
  width: 30,
  height: 30,
});

const InstructionAdd = styled.p(
  {
    display: "inline-block",
    padding: 5,
    borderRadius: 5,
    fontSize: 12,
    fontWeight: 600,
  },
  ({ theme: { colors } }) => ({
    background: colors.lightgrey,
  }),
);

const InstructionLogo = styled(Logo)({ width: 30, height: 30 });

const CopyButton = styled(AnimatedButton)({
  alignSelf: "start",
});

const InstallButton = styled.div(
  {
    width: 250,
    height: 200,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 10,
    fontWeight: 500,
    cursor: "pointer",
    transition: "all 0.5s",
  },
  ({ theme: { media, colors } }) => ({
    [media.md]: {
      width: 450,
    },
    color: colors.grey,
    border: `2px dashed ${colors.grey3}`,
    ":hover": {
      background: colors.lightgrey2,
    },
  }),
);

const { getBrowser, getOS } = new UAParser(navigator?.userAgent);
const os = getOS();
const browser = getBrowser();
const isIOS = os.name === "iOS" || os.name === "Mac OS";
const isSafari = browser.name?.includes("Safari");
const isChrome = browser.name?.includes("Chrome");
const isAndroid = os.name?.includes("Android");
interface BeforeInstallPromptEvent {
  prompt?: () => Promise<void>;
}

const InstallPage = () => {
  const { t } = useTranslation();

  const [isInstallationSupported, setIsInstallationSupported] = useState(false);
  const deferredPrompt = useRef<BeforeInstallPromptEvent | null>(null);

  useEffect(() => {
    if ("onbeforeinstallprompt" in window) {
      const onBeforeInstallPrompt = (event: Event) => {
        event.preventDefault();
        deferredPrompt.current = event as BeforeInstallPromptEvent;
        setIsInstallationSupported(true);
      };

      window.addEventListener("beforeinstallprompt", onBeforeInstallPrompt);

      return () => {
        window.removeEventListener(
          "beforeinstallprompt",
          onBeforeInstallPrompt,
        );
      };
    }
  }, []);

  const onInstall = useCallback(async () => {
    if (!deferredPrompt.current?.prompt) return;
    await deferredPrompt.current.prompt();
  }, []);

  const onCopy = useCallback(() => {
    copyText(window.location.href);
  }, []);

  return (
    <Container>
      <AppCard>
        <Title>{t("install.installApp")}</Title>
        <AppInfo>
          <AppLogo />
          <AppDescription>
            <AppName>{t("install.appName")}</AppName>
            <Website>{t("install.website")}</Website>
          </AppDescription>
        </AppInfo>
        {isSafari && isIOS ? (
          <ol>
            <li>
              <Trans
                i18nKey="install.iosInstruction.icon"
                components={{
                  icon: <InstructionInstallIcon src={InstallIconIOS} alt="" />,
                }}
              />
            </li>
            <li>
              {t("install.iosInstruction.select")}
              <br />
              <InstructionAdd>{t("install.iosInstruction.add")}</InstructionAdd>
            </li>
            <li>
              <Trans
                i18nKey="install.iosInstruction.look"
                components={{
                  icon: <InstructionLogo />,
                }}
              />
            </li>
          </ol>
        ) : isChrome && isAndroid ? (
          <>
            <ol>
              <li>
                <Trans
                  i18nKey="install.chromeInstruction.icon"
                  components={{
                    icon: (
                      <InstructionInstallIcon src={InstallIconChrome} alt="" />
                    ),
                  }}
                />
              </li>
              <li>{t("install.chromeInstruction.select")}</li>
              <li>
                <Trans
                  i18nKey="install.chromeInstruction.look"
                  components={{
                    icon: <InstructionLogo />,
                  }}
                />
              </li>
            </ol>
          </>
        ) : isInstallationSupported ? (
          <InstallButton onClick={onInstall}>
            <InstallIcon />
            {t("install.clickToInstall")}
          </InstallButton>
        ) : (
          <>
            <NotSupported>
              {isIOS ? t("install.notSupportedIOS") : t("install.notSupported")}
            </NotSupported>
            <CopyButton
              variant="outline"
              onClick={onCopy}
              iconClassName="icon-copy"
              text={t("install.copy")}
            />
          </>
        )}
      </AppCard>
    </Container>
  );
};

export default InstallPage;
