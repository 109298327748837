import Button from "../../../../components/button";
import DeleteConfirm from "../../../../components/delete-confirm";
import Input from "../../../../components/input";
import Loader from "../../../../components/loader";
import Modal from "../../../../components/modal";
import Textarea from "../../../../components/textarea";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  changeCustomStyleModal,
  deleteStyleOptions,
  getOneStyleOption,
  patchStyleOptions,
  postStyleOptions,
  selectCustomStyleModal,
} from "../../dashboardSlice";
import LanguageSelect from "../language-select";
import styled from "@emotion/styled";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Container = styled.div(
  {
    width: 345,
    display: "flex",
    flexDirection: "column",
    gap: 20,
    padding: 15,
    borderRadius: 15,
  },
  ({
    theme: {
      colors,
      media: { md },
    },
  }) => ({
    background: colors.white,
    [md]: {
      width: 660,
      padding: 30,
      borderRadius: 30,
    },
  }),
);

const LoaderContainer = styled.div(
  {
    minHeight: 300,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  [],
);

const Title = styled.div(
  { fontSize: 18, lineHeight: "23px", fontWeight: 600 },
  ({ theme: { colors } }) => ({
    color: colors.black,
  }),
);

const InputContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: 10,
});

const Counter = styled.div(
  { fontWeight: 500 },
  ({ theme: { colors, fontSize } }) => ({
    color: colors.grey,
    ...fontSize.sm,
  }),
);

const Footer = styled.div({
  display: "flex",
  gap: 20,
  button: { flexGrow: 1, justifyContent: "center", height: 40 },
});

const NAME_RESTRICTION = 40;
const PROMPT_RESTRICTION = 500;

interface CustomStyleModalProps {}

interface CustomStyleModalContentProps extends CustomStyleModalProps {
  onFormChanged: () => void;
  onClose: () => void;
}

const Content: FC<CustomStyleModalContentProps> = ({
  onFormChanged,
  onClose,
}) => {
  const { t } = useTranslation();

  const defaultPrompt = t("dashboard.customStyle.defaultPrompt");

  const {
    id,
    name = "",
    wording_to_openai = defaultPrompt,
    language = 1,
    articleId,
  } = useAppSelector(selectCustomStyleModal);

  const dispatch = useAppDispatch();

  const [newName, setNewName] = useState(name);
  const [newLanguage, setNewLanguage] = useState(language);
  const [newWordingToOpenai, setNewWordingToOpenai] =
    useState(wording_to_openai);
  const [isLoading, setIsLoading] = useState(!!id);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const isNameValid = newName.length <= NAME_RESTRICTION;
  const isWordingtopenaiValid = newWordingToOpenai.length <= PROMPT_RESTRICTION;

  const onChangeName = useCallback(
    (value: string) => {
      setNewName(value);
      onFormChanged();
    },
    [onFormChanged],
  );
  const onChangeLanguage = useCallback(
    (value: number) => {
      setNewLanguage(value);
      onFormChanged();
    },
    [onFormChanged],
  );
  const onChangeWordingToOpenai = useCallback(
    (value: string) => {
      setNewWordingToOpenai(value);
      onFormChanged();
    },
    [onFormChanged],
  );

  useEffect(() => {
    if (!id) return;
    dispatch(getOneStyleOption(id))
      .unwrap()
      .then(
        ({
          style_name = "",
          language = 1,
          wording_to_openai = defaultPrompt,
        }) => {
          setNewName(style_name);
          setNewLanguage(language);
          setNewWordingToOpenai(wording_to_openai);
        },
      )
      .finally(() => {
        setIsLoading(false);
      });
  }, [defaultPrompt, dispatch, id]);

  const onSave = useCallback(() => {
    if (!isNameValid || !isWordingtopenaiValid) return;

    if (!id) {
      dispatch(
        postStyleOptions({
          name: newName,
          wording_to_openai: newWordingToOpenai,
          language: newLanguage,
          articleId,
        }),
      );
    } else {
      dispatch(
        patchStyleOptions({
          id: id,
          name: newName,
          wording_to_openai: newWordingToOpenai,
          language: newLanguage,
          articleId,
        }),
      );
    }

    onClose();
  }, [
    articleId,
    dispatch,
    id,
    isNameValid,
    isWordingtopenaiValid,
    newLanguage,
    newName,
    newWordingToOpenai,
    onClose,
  ]);

  const onOpenDelteConfirm = useCallback(() => {
    setDeleteConfirmOpen(true);
  }, []);

  const onDelete = useCallback(() => {
    if (id) dispatch(deleteStyleOptions(id));
    onClose();
  }, [dispatch, id, onClose]);

  return (
    <Container>
      <Title>{t("dashboard.customStyle.title")}</Title>
      {isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          <InputContainer>
            <Input
              value={newName}
              onChange={onChangeName}
              placeholder={t("dashboard.customStyle.namePlaceholder")}
            />
            <Counter>
              {newName.length} / {NAME_RESTRICTION}
            </Counter>
          </InputContainer>
          <LanguageSelect
            value={newLanguage}
            onChange={onChangeLanguage}
            type="input"
            label={t("dashboard.customStyle.language")}
          />
          <InputContainer>
            <Textarea
              value={newWordingToOpenai}
              onChange={onChangeWordingToOpenai}
              placeholder={t("dashboard.customStyle.propmpt")}
              resize
            />
            <Counter>
              {newWordingToOpenai.length} / {PROMPT_RESTRICTION}
            </Counter>
          </InputContainer>
          <Footer>
            <Button
              onClick={onSave}
              disabled={
                !isNameValid ||
                !isWordingtopenaiValid ||
                !newName ||
                !newWordingToOpenai
              }
            >
              {t("save")}
            </Button>
            {id ? (
              <Button onClick={onOpenDelteConfirm} variant="outline">
                {t("delete")}
              </Button>
            ) : (
              <Button onClick={onClose} variant="outline">
                {t("cancel")}
              </Button>
            )}
          </Footer>
        </>
      )}
      <DeleteConfirm
        open={deleteConfirmOpen}
        onOpenChange={setDeleteConfirmOpen}
        onAccept={onDelete}
      />
    </Container>
  );
};

const CustomStyleModal: FC<CustomStyleModalProps> = (props) => {
  const { t } = useTranslation();

  const { open } = useAppSelector(selectCustomStyleModal);

  const dispatch = useAppDispatch();

  const [closeConfirmOpen, setCloseConfirmOpen] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);

  const onOpenChange = useCallback(
    (open: boolean) => {
      if (!open && isFormChanged) {
        setCloseConfirmOpen(true);
      } else {
        dispatch(changeCustomStyleModal({ open }));
      }
    },
    [dispatch, isFormChanged],
  );

  const onClose = useCallback(() => {
    dispatch(changeCustomStyleModal({ open: false }));
    setIsFormChanged(false);
  }, [dispatch]);

  const onFormChanged = useCallback(() => {
    setIsFormChanged(true);
  }, []);

  return (
    <>
      <Modal
        open={open}
        onOpenChange={onOpenChange}
        content={
          <Content {...props} onFormChanged={onFormChanged} onClose={onClose} />
        }
      />
      <DeleteConfirm
        open={closeConfirmOpen}
        onOpenChange={setCloseConfirmOpen}
        onAccept={onClose}
        message={t("dashboard.closeConfirmText")}
      />
    </>
  );
};

export default CustomStyleModal;
