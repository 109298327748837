import { FC } from "react";
import { map } from "lodash";
import styled from "@emotion/styled";

const Wrapper = styled.div({
  flexGrow: 1,
  display: "flex",
  alignItems: "end",
  justifyContent: "center",
});

const Container = styled.div({
  display: "flex",
  gap: 15,
  paddingRight: 30,
  userSelect: "none",
});

const ArrowButton = styled.button(
  {
    height: 40,
    display: "inline-flex",
    alignItems: "center",
    padding: 0,
    fontSize: 26,
    border: "none",
  },
  ({ theme: { colors }, disabled }) => ({
    color: colors.purple,
    cursor: disabled ? "default" : "pointer",
  })
);

const Button = styled.button<{ isDots?: boolean }>(
  {
    height: 40,
    fontSize: 18,
    lineHeight: "20px",
    fontWeight: 500,
    borderRadius: 10,
  },
  ({ theme: { colors }, disabled, isDots }) => ({
    width: isDots ? "auto" : 40,
    padding: isDots ? "20px 0 0 0" : 0,
    color: colors.black,
    border: isDots
      ? "none"
      : disabled
      ? `2px solid ${colors.purple}`
      : `1px solid ${colors.grey}`,
    cursor: disabled || isDots ? "default" : "pointer",
  })
);

interface PaginationProps {
  pages: number;
  page: number;
  pageChange: (page: number) => void;
}

export const Pagination: FC<PaginationProps> = ({
  pages,
  page,
  pageChange,
}) => {
  if (pages <= 1) return null;

  const getPages = () => {
    const pageArr = Array.from(Array(pages).keys());
    switch (true) {
      case pages <= 4:
        return pageArr;
      case pages > 4: {
        if (page < 3) {
          return pageArr
            .slice(0, page === 2 ? 3 : 2)
            .concat([-1])
            .concat(pageArr.slice(pages - 1, pages));
        }
        if (page >= 3 && pages - page >= 3) {
          return pageArr
            .slice(0, 1)
            .concat([-1])
            .concat([page - 1])
            .concat([-1])
            .concat([pages - 1]);
        }
        if (pages - page < 3) {
          return pageArr
            .slice(0, 1)
            .concat([-1])
            .concat(pageArr.slice(pages - (pages - page === 2 ? 3 : 2), pages));
        }
        break;
      }
      default:
        return [...Array(1)];
    }
  };

  return (
    <Wrapper>
      <Container>
        <ArrowButton
          type="button"
          onClick={() => pageChange(page - 1)}
          disabled={page === 1}
        >
          <i className="icon-arrow-left" />
        </ArrowButton>
        {map(getPages(), (pageNum, index) => {
          const isDots = pageNum === -1;
          return (
            <Button
              type="button"
              key={index}
              onClick={() => (!isDots ? pageChange(pageNum + 1) : null)}
              disabled={pageNum + 1 === page}
              isDots={isDots}
            >
              {!isDots ? pageNum + 1 : <span>...</span>}
            </Button>
          );
        })}
        <ArrowButton
          type="button"
          onClick={() => pageChange(page + 1)}
          disabled={page === pages}
        >
          <i className="icon-arrow-right" />
        </ArrowButton>
      </Container>
    </Wrapper>
  );
};
