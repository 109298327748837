import Button from "../../../components/button";
import styled from "@emotion/styled";

const ResponsiveButton = styled(Button)(
  {
    height: 32,
    i: { marginRight: 5, fontSize: 18 },
  },
  ({ theme: { media, fontSize } }) => ({
    ...fontSize.sm,
    [media.sm]: {
      height: 40,
      ...fontSize.md,
      i: { marginRight: 10, fontSize: 24 },
    },
  })
);

export default ResponsiveButton;
